import { createAsyncThunk } from '@reduxjs/toolkit';
import locationApi from '~/apis/crm/location';
import { CREATE_LOCATION, GET_DETAIL_LOCATION, GET_LIST_LOCATION } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import { IFormLocation } from '~/utils/interface/crm/location';

export const createLocation = createAsyncThunk(CREATE_LOCATION, async (payload: IFormLocation, { rejectWithValue }) => {
  try {
    const res = await locationApi.createLocation(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListLocations = createAsyncThunk(
  GET_LIST_LOCATION,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await locationApi.getListLocation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailLocation = createAsyncThunk(
  GET_DETAIL_LOCATION,
  async (locationId: string, { rejectWithValue }) => {
    try {
      const res = await locationApi.getDetailLocation(locationId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
