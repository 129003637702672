// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
import {
  BaseTable,
  BasePagination,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  InputSearch,
  ToolBar,
  CrmEditReferralSource,
  FormSendEmailModal,
  BaseButton,
  RingCentralConfirmModal,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CLientStatusEnum,
  ConnectionStatusEnum,
  CRMEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { DEFAULT_CURRENT_PAGE, DEFAULT_DELAY_TIME, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { formatPhoneNumber, getUserName } from '~/utils/helper';
import useDebounce from '~/utils/customHook';
import { LoadingData, RingCentralContext } from '~/context';
import {
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { changeStatusClient, getListClients } from '~/thunks/crm/clients/clientsThunk';
import { IListClient, IListQueryParamsClients, IPayloadChangeStatusClient } from '~/utils/interface/crm/clients';
import { clientActions, selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
// Styles, images, icons
import styles from './CRMReferralSource.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleCall: (record: IListClient) => void,
  handleSendEmail: (record: IListClient) => void,
  handleViewDetails: (id: string) => void,
  handleSendMessage: (record: IListClient) => void,
  handleChangeStatus: (record: IListClient) => void
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'organization',
      title: t('crm_referral_source_tab_organization_label'),
      dataIndex: 'organization',
      render: (_, record) => {
        return <>{record.organization || EMPTY_STRING}</>;
      },
    },
    {
      key: 'name',
      title: t('crm_referral_source_tab_name_label'),
      // dataIndex: 'firstName',
      render: (_, record) => {
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
    },
    {
      key: 'owner',
      title: t('crm_referral_source_tab_owner_label'),
      dataIndex: 'owner',
      render: (_, record) => {
        return <>{record.owner || EMPTY_STRING}</>;
      },
    },
    {
      key: 'workPhone',
      title: t('crm_referral_source_tab_workPhone_label'),
      dataIndex: 'workPhone',
      render: (_, record) => {
        return <>{record?.workPhone ? formatPhoneNumber(record?.workPhone) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'prospects',
      title: t('crm_referral_source_tab_prospects_label'),
      dataIndex: 'prospects',
      render: (_, record) => {
        return <>{record.prospects ?? EMPTY_STRING}</>;
      },
    },
    {
      key: 'action',
      title: t('crm_referral_source_tab_action_label'),
      render(_, record) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isCall: true,
              isSendMessage: true,
              isSendEmail: true,
              isEdit: false,
              isDelete: false,
              isDeactivate: true,
            }}
            onView={() => handleViewDetails(record.id)}
            onCall={() => handleCall(record)}
            onSendEmail={() => handleSendEmail(record)}
            onSendMessage={() => handleSendMessage(record)}
            onDeactivate={() => handleChangeStatus(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMReferralSource = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  // const { ringCentralStatus, executeCall, sendSMS } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [paramObject, setParamObject] = useState<IListQueryParamsClients>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [referralsList, setReferralsList] = useState<IListClient[]>([]);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [referralSourceId, seReferralSourceId] = useState<string>(EMPTY_STRING);

  const [referralSourceSelected, setReferralSourceSelected] = useState<IListClient | null>(null);
  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);

  // const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParamsClients = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      type: [CRMEnum.REFERRAL],
    };

    handleGetListReferrals(newParamObject);

    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page!.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };
    setSearchParams(newParam);
    isRefreshClientsList && dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject, isRefreshClientsList]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleEdit = (record?: IListClient) => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleCall = async (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.workPhone) {
    //   executeCall && executeCall(record?.workPhone);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleSendMessage = (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.workPhone) {
    //   sendSMS && sendSMS(record?.workPhone);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleShowSendEmail = (record: IListClient) => {
    if (!record) return;

    setReferralSourceSelected(record);
    setIsShowSendMail(true);
  };

  const handleCloseSendEmail = () => {
    setReferralSourceSelected(null);
    setIsShowSendMail(false);
  };

  const handleChangeStatus = (record: IListClient) => {
    loading?.show();

    const payloadClientStatus: IPayloadChangeStatusClient = {
      clientId: record.id,
      body: {
        status:
          record.status && record.status === CLientStatusEnum.ACTIVE
            ? CLientStatusEnum.INACTIVE
            : CLientStatusEnum.ACTIVE,
      },
    };

    dispatch(changeStatusClient(payloadClientStatus))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch((_err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleViewDetails = (id: string) => {
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmReferralSource}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmReferralSource}/${id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmReferralSource}/${id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmReferralSource}/${id}`);
        return;

      default:
        return;
    }
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParamsClients = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleSortBy = () => {
    // TODO - HuyPahmGRF-ABD: Handle Sort By
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleFilter = () => {
    // TODO - HuyPahmGRF-ABD: Handle Filter
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleGetListReferrals = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;
        setReferralsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleAddRefferalSource = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  // const handleCloseDisconnectConfirm = () => {
  //   setOpenDisconnectConfirm(false);
  // };

  // const handleNavigateToRingCentral = () => {
  //   const role = localStorage.getItem(StorageEnum.ROLE);

  //   switch (role) {
  //     case AccountRoleCodesEnum.ADMIN:
  //       navigate(adminRouteAbsolute.ringCentral);
  //       break;

  //     case AccountRoleCodesEnum.EMPLOYEE:
  //       break;

  //     case AccountRoleCodesEnum.CAREGIVER:
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleReferralSourceRowClick = (data: IListClient) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='CRMReferralSourceTemplate' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_referral_source_tab_title')}</span>

            {/* {ringCentralStatus !== ConnectionStatusEnum.CONNECTED && (
              <span className={cx('crmRingCentralStatus')} onClick={handleNavigateToRingCentral}>
                {t('ring_central_confirm_disconnect_title')}
              </span>
            )} */}
          </div>
        }
      >
        {/* <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_sort')}
          width={84}
          height={36}
          onClick={handleSortBy}
        />
        <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_filter')}
          width={67}
          height={36}
          onClick={handleFilter}
        /> */}
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={textSearchParams}
        />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_referral_title')}
          height={36}
          onClick={handleAddRefferalSource}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleCall,
              handleShowSendEmail,
              handleViewDetails,
              handleSendMessage,
              handleChangeStatus
            )}
            dataSource={referralsList}
            onClickRow={handleReferralSourceRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowSendMail && (
        <FormSendEmailModal
          isOpen={isShowSendMail}
          dataDefault={referralSourceSelected}
          onClose={handleCloseSendEmail}
        />
      )}

      {/* {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_disconnect_title')}
          description={t('ring_central_confirm_disconnect_description')}
          confirm={t('ring_central_confirm_disconnect_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleNavigateToRingCentral}
        />
      )} */}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      {isShowEdit && (
        <CrmEditReferralSource isOpen={isShowEdit} onClose={handleEdit} referralSourceId={referralSourceId} />
      )}
    </div>
  );
};

export default CRMReferralSource;
