import { RefObject, useEffect, useRef, useState } from 'react';
import { IMessage } from './interface/message';
import { MessageStatusEnum, StorageEnum } from './enum';
import { DEBOUNCE_SEEN_MESSAGE_DELAY, DEFAULT_DELAY_TIME } from './constants/common';

/**
 * Custom hook to debounce a value.
 * @param value - The value to debounce.
 * @param delay - The debounce delay in milliseconds.
 * @returns The debounced value.
 */
export const useDebounce = <T>(value: T, delay: number = DEFAULT_DELAY_TIME): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

export const useOutsideClick = <T extends HTMLElement>(callback: () => void) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);

  return ref;
};

/**
 * Monitors the visibility of messages within a given container and updates the list of visible message IDs.
 * @param {IMessage[]} messages - Array of message objects to be checked for visibility.
 * @param {RefObject<HTMLDivElement>} containerRef - Reference to the container element that holds the messages.
 * @returns {number[]} - Array of IDs of the messages that are currently visible in the container.
 */
export const useVisibleMessages = (messages: IMessage[], containerRef: RefObject<HTMLDivElement>) => {
  const [visibleMessages, setVisibleMessages] = useState<number[]>([]);
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  const debounceTimeoutRef = useRef<number | null>(null);

  const checkVisibility = () => {
    const updatedVisibleMessages: number[] = [];
    const container = containerRef.current;

    if (container) {
      messages.forEach((message) => {
        if (message.senderAccountId !== accountId && message.status === MessageStatusEnum.DELIVERED) {
          const element = container.querySelector(`[data-message-id='${message.id}']`);
          if (element) {
            const rect = element.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            if (
              rect.top >= containerRect.top &&
              rect.bottom <= containerRect.bottom &&
              rect.left >= containerRect.left &&
              rect.right <= containerRect.right
            ) {
              updatedVisibleMessages.push(Number(message.id));
            }
          }
        }
      });
    }

    setVisibleMessages(updatedVisibleMessages);
  };

  const handleDebouncedVisibilityCheck = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      checkVisibility();
    }, DEBOUNCE_SEEN_MESSAGE_DELAY);
  };

  useEffect(() => {
    checkVisibility();

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleDebouncedVisibilityCheck);
      window.addEventListener('resize', handleDebouncedVisibilityCheck);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleDebouncedVisibilityCheck);
      }
      window.removeEventListener('resize', handleDebouncedVisibilityCheck);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [messages, containerRef]);

  return visibleMessages;
};

export const useTextOverflow = (
  refs: Map<string, HTMLDivElement | null> | HTMLDivElement | null,
  dependencies: any[] = []
) => {
  const [overflowMap, setOverflowMap] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const checkOverflow = (el: HTMLDivElement | null) => {
      return el ? el.scrollWidth > el.clientWidth : false;
    };

    if (refs instanceof Map) {
      const newOverflowMap: { [key: string]: boolean } = {};
      refs.forEach((el, key) => {
        newOverflowMap[key] = checkOverflow(el);
      });
      setOverflowMap(newOverflowMap);
    } else {
      setOverflowMap({ singleRef: checkOverflow(refs) });
    }
  }, [refs, ...dependencies]);

  return overflowMap;
};
