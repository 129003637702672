// Libs
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  BaseUploadImage,
  CheckboxSingle,
  ModalUnderDevelopment,
  SelectMultipleTag,
  PlacesAutocomplete,
  BaseSelectMultiple,
} from '~/components';
// Others
import { referrerOptions } from '~/mockData';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { createCaregiver, editCaregiver, getDetailCaregiver, getProfileTags } from '~/thunks/caregiver/caregiverThunk';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { IAddress, IBaseOption, IBaseOptionMultiple, IListQueryParams, ITag } from '~/utils/interface/common';
import { IFormCaregiver, IProfileTag } from '~/utils/interface/caregiver';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FILE_UPLOAD_LIMIT_50_MB,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import {
  AddCaregiverNameModalEnum,
  ButtonTypeEnum,
  DateFormatEnum,
  MasterDataTypeEnum,
  ReferrerTypeEnum,
} from '~/utils/enum';
import { createFormData, formatPhoneNumber } from '~/utils/helper';
// Styles, images, icons
import styles from './AddCaregiver.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    avatarAccount: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('add_caregiver_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= FILE_UPLOAD_LIMIT_50_MB;
            }),
        })
      )
      .optional(),
    firstName: yup.string().trim().required(t('common_required_field')),
    middleName: yup.string().trim().optional(),
    lastName: yup.string().trim().required(t('common_required_field')),
    address: yup.string().trim().optional(),
    addressLine2: yup.string().trim().optional(),
    city: yup.string().trim().optional(),
    state: yup.string().trim().optional(),
    zipCode: yup.string().trim().optional(),
    notes: yup.string().trim().optional(),
    hireDate: yup.string().trim().required(t('common_required_field')),
    tags: yup.array().optional(),
    newTags: yup.array().optional(),
    tagIds: yup.array().optional(),
    ssn: yup.string().trim().optional(),
    homePhone: yup.string().trim().optional(),
    mobilePhone: yup.string().trim().optional(),
    workPhone: yup.string().trim().optional(),
    email: yup
      .string()
      .required(t('common_required_field'))
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    location: yup
      .array(yup.string().required(t('common_required_field')))
      .min(1, t('common_required_field'))
      .required(),
    referrer: yup.string().trim().optional(),
    npi: yup.string().trim().optional(),
    externalId: yup.string().trim().optional(),
    isBackgroundCheck: yup.boolean().optional(),
    isDeleteAvatar: yup.boolean().optional(),
  });
};

const defaultValues: IFormCaregiver = {
  avatarAccount: [],
  firstName: EMPTY_STRING,
  middleName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  address: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  notes: EMPTY_STRING,
  hireDate: moment().format(DateFormatEnum.YYYY_MM_DD),
  tags: [],
  tagIds: [],
  newTags: [],
  ssn: EMPTY_STRING,
  homePhone: EMPTY_STRING,
  mobilePhone: EMPTY_STRING,
  workPhone: EMPTY_STRING,
  email: EMPTY_STRING,
  location: [],
  referrer: EMPTY_STRING,
  npi: EMPTY_STRING,
  externalId: EMPTY_STRING,
  isBackgroundCheck: false,
  lat: EMPTY_STRING,
  lng: EMPTY_STRING,
};

const AddCaregiver = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const { caregiverId } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormCaregiver>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isUnderDevelopment, setIsUnderDevelopment] = useState<boolean>(false);
  const [profileTags, setProfileTags] = useState<ITag[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [locations, setLocations] = useState<IBaseOptionMultiple[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const payload: IProfileTag = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: MasterDataTypeEnum.TAG,
    };

    handleGetProfileTags(payload);
    handleGetListLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!caregiverId) return;

    handleGetCaregiverDetail(caregiverId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverId]);

  useEffect(() => {
    const currentDate = moment().format(DateFormatEnum.YYYY_MM_DD);
    setSelectedDate(currentDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetCaregiverDetail = (caregiverId: string) => {
    loadingData?.show();

    dispatch(getDetailCaregiver(caregiverId))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const data = res?.data;

        reset({
          avatarAccount: data?.avatarUrl ? [{ url: data?.avatarUrl }] : undefined,
          firstName: data?.firstName || EMPTY_STRING,
          middleName: data?.middleName || EMPTY_STRING,
          lastName: data?.lastName || EMPTY_STRING,
          address: data?.address || EMPTY_STRING,
          addressLine2: data?.addressLine2 || EMPTY_STRING,
          city: data?.city || EMPTY_STRING,
          state: data?.state || EMPTY_STRING,
          zipCode: data?.zipCode || EMPTY_STRING,
          notes: data?.notes || EMPTY_STRING,
          hireDate: data?.hireDate || EMPTY_STRING,
          tags: data?.tags?.map((tag) => tag?.id) || [],
          ssn: data?.ssn || EMPTY_STRING,
          homePhone: data?.homePhone || EMPTY_STRING,
          mobilePhone: data?.mobilePhone || EMPTY_STRING,
          workPhone: data?.workPhone || EMPTY_STRING,
          email: data?.email || EMPTY_STRING,
          location: data?.locations?.map((location) => location?.id) || [],
          referrer: data?.referrer ? ReferrerTypeEnum.SELF : ReferrerTypeEnum.NONE,
          npi: data?.npi || EMPTY_STRING,
          externalId: data?.externalId || EMPTY_STRING,
          isBackgroundCheck: data?.isBackgroundCheck || false,
        });
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleCloseUnderDevelopment = () => {
    setIsUnderDevelopment(false);
  };

  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetProfileTags = (data: IProfileTag) => {
    dispatch(getProfileTags(data))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const { responses } = res?.data;

        setProfileTags(responses || []);
      })
      .catch((error) => {});
  };

  const handleCreateCaregiver = (formData: FormData) => {
    loadingData?.show();

    dispatch(createCaregiver(formData))
      .unwrap()
      .then((res) => {
        handleResetFormCaregiver();

        handleBackToPreviousPage();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdateCaregiver = (formData: FormData) => {
    if (!caregiverId) return;

    loadingData?.show();

    dispatch(editCaregiver({ caregiverId, body: formData }))
      .unwrap()
      .then((res) => {
        handleResetFormCaregiver();

        handleBackToPreviousPage();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSubmitFormCaregiver = async (data: IFormCaregiver) => {
    const optionsIds = new Set(profileTags?.map((option) => option?.id));
    const tagIds: number[] = data?.tags?.filter((id) => optionsIds?.has(id))?.map((id) => Number(id)) || [];
    const newTags: string[] = data?.tags?.filter((id) => !optionsIds.has(id)) || [];

    const { tags, ...newData } = data;

    const payload: IFormCaregiver = {
      ...newData,
      avatarAccount:
        Array.isArray(newData?.avatarAccount) && newData?.avatarAccount?.length > DEFAULT_NUMBER_ZERO
          ? newData?.avatarAccount
          : undefined,
      tagIds: tagIds,
      newTags: newTags,
      ...(caregiverId && {
        isDeleteAvatar:
          !Array.isArray(newData?.avatarAccount) || newData?.avatarAccount?.length === DEFAULT_NUMBER_ZERO,
      }),
      homePhone: newData.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: newData.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: newData.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      lat: newData.lat,
      lng: newData.lng,
    };

    const filterPayloadCreateCaregiver: Partial<IFormCaregiver> = {};
    const filterPayloadEditCaregiver: Partial<IFormCaregiver> = {};

    Object.entries(payload).forEach(([key, value]) => {
      if (
        (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
        (Array.isArray(value) && value?.length > DEFAULT_NUMBER_ZERO)
      ) {
        filterPayloadCreateCaregiver[key as keyof IFormCaregiver] = value;
      }

      filterPayloadEditCaregiver[key as keyof IFormCaregiver] = value;
    });

    const formData = await createFormData(
      caregiverId ? filterPayloadEditCaregiver : filterPayloadCreateCaregiver,
      AddCaregiverNameModalEnum.AVATAR_ACCOUNT
    );

    if (caregiverId) {
      handleUpdateCaregiver(formData);
    } else {
      handleCreateCaregiver(formData);
    }
  };

  const handleResetFormCaregiver = () => {
    reset(defaultValues);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('zipCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lat', address?.lat ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lng', address?.lng ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => {
          return { label: data.name || EMPTY_STRING, value: data.id };
        });
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='addCaregiverPage' className={cx('container')}>
      <span className={cx('backTag')} onClick={handleBackToPreviousPage}>
        <img src={icons.commonIconPrev} alt={t('common_img_text_alt')} />
        <span className={cx('textBack')}>{t('common_text_back')}</span>
      </span>

      <div className={cx('header')}>
        <div className={cx('title')}>
          {caregiverId ? t('caregiver_modal_edit_caregiver_title') : t('add_caregiver_title')}
        </div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitFormCaregiver)}>
          <div className={cx('formBody')}>
            <Controller
              name='avatarAccount'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('add_caregiver_profile_image_label')}
                  textBtn={t('add_caregiver_profile_image_content')}
                  height={64}
                  maxUpload={DEFAULT_NUMBER_ONE}
                  defaultImageList={value ?? undefined}
                  onChange={onChange}
                  errorMessage={errors.avatarAccount?.message}
                />
              )}
            />

            <div className={cx('threeCol')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='firstName'
                    value={value}
                    label={t('add_caregiver_first_name_label')}
                    onChange={onChange}
                    required
                    messageError={errors.firstName?.message}
                  />
                )}
              />

              <Controller
                name='middleName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='middleName'
                    value={value}
                    label={t('add_caregiver_middle_name_label')}
                    onChange={onChange}
                    messageError={errors.middleName?.message}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    value={value}
                    label={t('add_caregiver_last_name_label')}
                    onChange={onChange}
                    required
                    messageError={errors.lastName?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='hireDate'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    label={t('add_caregiver_hire_dates_label')}
                    onDateSelected={onChange}
                    value={selectedDate}
                    name={'hireDate'}
                    placeholderText={t('common_placeholder_select')}
                    required
                    errorMessage={errors.hireDate?.message}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    value={value}
                    label={t('add_caregiver_email_label')}
                    onChange={onChange}
                    messageError={errors.email?.message}
                    required
                  />
                )}
              />

              <Controller
                name='location'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelectMultiple
                    value={value || []}
                    options={locations || []}
                    label={t('add_caregiver_location_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={onChange}
                    errorMessage={errors.location?.message}
                    required
                  />
                )}
              />
            </div>

            <Controller
              name='address'
              control={control}
              render={({ field: { value, onChange } }) => (
                <PlacesAutocomplete
                  id='address'
                  value={value}
                  label={t('add_caregiver_address_label')}
                  onChange={(address) => handleChangeAddress(address)}
                  messageError={errors.address?.message}
                />
              )}
            />

            <div className={cx('fourCol')}>
              <Controller
                name='addressLine2'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    value={value}
                    label={t('add_caregiver_address_line_2_label')}
                    onChange={onChange}
                    messageError={errors.addressLine2?.message}
                  />
                )}
              />

              <Controller
                name='city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='city'
                    value={value}
                    label={t('add_caregiver_city_label')}
                    onChange={onChange}
                    messageError={errors.city?.message}
                  />
                )}
              />

              <Controller
                name='state'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='state'
                    value={value}
                    label={t('add_caregiver_state_label')}
                    onChange={onChange}
                    messageError={errors.state?.message}
                  />
                )}
              />

              <Controller
                name='zipCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='zipCode'
                    value={value}
                    label={t('add_caregiver_postal_code_label')}
                    onChange={onChange}
                    messageError={errors.zipCode?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='homePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='homePhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_home_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.homePhone?.message}
                  />
                )}
              />

              <Controller
                name='mobilePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='mobilePhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_mobile_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.mobilePhone?.message}
                  />
                )}
              />

              <Controller
                name='workPhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='workPhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_work_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.workPhone?.message}
                  />
                )}
              />

              <Controller
                name='tags'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectMultipleTag
                    label={t('add_caregiver_profile_tag_label')}
                    options={profileTags || []}
                    value={value}
                    onChange={onChange}
                    errorMessage={errors.tagIds?.message}
                  />
                )}
              />

              <Controller
                name='referrer'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={referrerOptions || []}
                    label={t('add_caregiver_referrer_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.referrer?.message}
                  />
                )}
              />

              <Controller
                name='npi'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='npi'
                    value={value}
                    label={t('add_caregiver_caregiver_npi_label')}
                    onChange={onChange}
                    messageError={errors.npi?.message}
                  />
                )}
              />

              <Controller
                name='externalId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='externalId'
                    value={value}
                    label={t('add_caregiver_external_id_label')}
                    onChange={onChange}
                    messageError={errors.externalId?.message}
                  />
                )}
              />

              <Controller
                name='ssn'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='ssn'
                    value={value}
                    label={t('add_caregiver_ssn_label')}
                    onChange={onChange}
                    messageError={errors.ssn?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id='notes'
                  label={t('add_caregiver_notes_label')}
                  value={value}
                  onChange={onChange}
                  height={100}
                  messageError={errors.notes?.message}
                />
              )}
            />

            <Controller
              name='isBackgroundCheck'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxSingle
                  label={t('add_caregiver_background_label')}
                  value={value}
                  onChange={(checked: boolean, name?: string) => {
                    onChange(checked);
                  }}
                  messageError={errors.isBackgroundCheck?.message}
                />
              )}
            />
          </div>

          <div className={cx('actions')}>
            <BaseButton text={t('common_go_back_label')} width={80} onClick={handleBackToPreviousPage} type='button' />

            <BaseButton
              text={caregiverId ? t('common_update_label') : t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              type='submit'
              disabled={caregiverId ? !isDirty : false}
            />
          </div>
        </form>
      </div>

      {isUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default AddCaregiver;
