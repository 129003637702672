// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
import {
  ConversationActions,
  ConversationInputMessage,
  ConversationMessage,
  ConversationUserInfo,
  Spinner,
} from '~/components';
// Others
import { IBodyCreateMessage, IMessage, IPayloadCreateMessage, ISubmitMessage } from '~/utils/interface/message';
import { convertDateToFormatTime, createFormData, generateUniqueId } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MESSAGE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { FileKeyEnum, MessageStatusEnum, StorageEnum, TimeFormatEnum, WebSocketEvent } from '~/utils/enum';
import { joinConversation, seenMessage, stopTyping, typing } from '~/utils/helpers/wss';
import { createMessage, getDetailConversation } from '~/thunks/conversation/conversationThunk';
import { IConversationMember, IPayloadGetDetailConversation } from '~/utils/interface/conversation';
import { LoadingContext } from '~/context';
import { WebSocketContext } from '~/context/websocketContext/WebSocketContext';
import { useAppDispatch } from '~/redux/hooks';
import { IUserAccount } from '~/utils/interface/user';
import useDebounce, { useVisibleMessages } from '~/utils/customHook';
// Styles, images, icons
import styles from './Conversation.module.scss';

type Props = {
  conversationId?: string;
  accountInformation: IUserAccount;
  onRefreshConversation: () => void;
};

const cx = classNames.bind(styles);

const Conversation = (props: Props) => {
  //#region Destructuring Props
  const { conversationId, accountInformation, onRefreshConversation } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const { message: messageWSS, wss, socketId } = useContext(WebSocketContext);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [memberInfo, setMemberInfo] = useState<IConversationMember | undefined>();
  const [listMessages, setListMessages] = useState<IMessage[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState<boolean>(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const totalPagesRef = useRef<number>(totalPages);
  const currentPageRef = useRef<number>(currentPage);
  const listMessagesRef = useRef<IMessage[]>(listMessages);
  const isLoadingMoreRef = useRef<boolean>(isLoadingMore);
  const isLoadingMessageRef = useRef<boolean>(isLoadingMessage);
  const searchKeyRef = useRef<string>(searchKey);
  const debouncedSearchKey = useDebounce<string>(searchKey);
  const previousScrollTopRef = useRef<number>(0);
  const visibleMessages = useVisibleMessages(listMessages, containerRef);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (Array.isArray(visibleMessages) && visibleMessages.length === 0) {
      return;
    }

    if (!wss || !conversationId) {
      return;
    }

    seenMessage(wss, +conversationId, visibleMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleMessages]);

  useLayoutEffect(() => {
    if (isLoadingMoreRef.current && containerRef.current) {
      containerRef.current.scrollTop = previousScrollTopRef.current;
    }
  }, [listMessages]);

  useEffect(() => {
    currentPageRef.current = currentPage;
    totalPagesRef.current = totalPages;
    listMessagesRef.current = listMessages;
    isLoadingMoreRef.current = isLoadingMore;
    isLoadingMessageRef.current = isLoadingMessage;
    searchKeyRef.current = searchKey;
  }, [currentPage, totalPages, listMessages, isLoadingMore, isLoadingMessage, searchKey]);

  useEffect(() => {
    if (!conversationId) return;
    clearData();
    setIsLoadingConversation(true);
    const params: IPayloadGetDetailConversation = {
      conversationId: conversationId,
      query: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_LIMIT_MESSAGE,
      },
    };

    handleGetConversationDetails(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (!conversationId) return;
    clearDataOnSearch();
    setIsLoadingMessage(true);
    const params: IPayloadGetDetailConversation = {
      conversationId: conversationId,
      query: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_LIMIT_MESSAGE,
        ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      },
    };

    handleGetConversationDetails(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }
    if (wss) {
      joinConversation(wss, +conversationId);
    }
  }, [wss, conversationId]);

  useEffect(() => {
    if (!messageWSS) {
      return;
    }

    switch (messageWSS.type) {
      case WebSocketEvent.GET_MESSAGE_UNSEEN:
        break;
      case WebSocketEvent.SEEN_MESSAGE:
        const messageIdsUpdated = messageWSS?.data?.messageIdsUpdated;
        if (String(messageWSS?.data?.conversationId) === String(conversationId) && messageIdsUpdated) {
          const newListMessages = listMessagesRef.current.map((message) => {
            if (messageIdsUpdated.includes(Number(message.id))) {
              return {
                ...message,
                status: MessageStatusEnum.SEEN,
              };
            }
            return message;
          });

          setListMessages(newListMessages);
        }
        break;
      case WebSocketEvent.SEND_MESSAGE:
        if (String(messageWSS?.data?.conversationId) === String(conversationId)) {
          const newMessage: IMessage = {
            id: messageWSS.data.id,
            content: messageWSS.data.content,
            type: messageWSS.data.type,
            status: MessageStatusEnum.DELIVERED,
            createdAt: messageWSS.data.createdAt,
            senderAccountId: messageWSS.data.senderAccountId,
            senderAvatarUrl: memberInfo?.avatarUrl,
            senderFirstName: memberInfo?.firstName,
            senderLastName: memberInfo?.lastName,
          };
          setListMessages((prev) => [newMessage, ...prev]);
        }
        break;
      case WebSocketEvent.TYPING:
        if (String(messageWSS?.data?.conversationId) === String(conversationId)) {
          setIsTyping(true);
        }
        break;
      case WebSocketEvent.STOP_TYPING:
        if (String(messageWSS?.data?.conversationId) === String(conversationId)) {
          setIsTyping(false);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageWSS]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetConversationDetails = (params: IPayloadGetDetailConversation) => {
    dispatch(getDetailConversation(params))
      .unwrap()
      .then((res) => {
        const { messages, members, pagination } = res.data;
        if (params.conversationId !== conversationId) return;

        if (members[DEFAULT_NUMBER_ZERO]) {
          setMemberInfo(members[DEFAULT_NUMBER_ZERO]);
        }
        setTotalPages(pagination.totalPages);
        if (isLoadingMoreRef.current) {
          setCurrentPage(pagination.page);
          setListMessages([...listMessagesRef.current, ...messages]);
          return;
        }
        setListMessages(messages);
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
        setIsLoadingMore(false);
        setIsLoadingConversation(false);
        setIsLoadingMessage(false);
      });
  };

  const handleCreateMessage = (payload: IPayloadCreateMessage) => {
    dispatch(createMessage(payload))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        if (Number(data?.conversationId) !== Number(conversationId)) return;

        setListMessages(
          listMessagesRef.current.map((message) =>
            message.id === data?.tempId ? { ...message, id: data?.tempId } : message
          )
        );
        onRefreshConversation();
      })
      .catch((error) => {});
  };

  const handleSearch = (textSearch: string) => {
    setSearchKey(textSearch);
  };

  const handleCall = () => {
    // Handle Update Later
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollHeight + scrollTop === clientHeight &&
        !isLoadingMore &&
        currentPageRef.current < totalPagesRef.current
      ) {
        previousScrollTopRef.current = scrollTop;
        handleLoadMoreItems();
      }
    }
  };

  const handleLoadMoreItems = async () => {
    if (!isLoadingMoreRef.current && currentPageRef.current < totalPagesRef.current && conversationId) {
      setIsLoadingMore(true);
      const params: IPayloadGetDetailConversation = {
        conversationId: conversationId,
        query: {
          page: currentPageRef.current + 1,
          limit: DEFAULT_LIMIT_MESSAGE,
          ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
        },
      };

      handleGetConversationDetails(params);
    }
  };

  const groupMessagesByDay = (messages: IMessage[]): { day: string; messages: IMessage[] }[] => {
    const groupedMessages: { day: string; messages: IMessage[] }[] = [];

    messages.forEach((message) => {
      const messageDate = convertDateToFormatTime(message?.createdAt as string, TimeFormatEnum.MM_DD_YYYY);

      const existingGroup = groupedMessages.find((group) => group.day === messageDate);
      if (existingGroup) {
        existingGroup.messages.push(message);
        existingGroup.messages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      } else {
        groupedMessages.push({ day: messageDate, messages: [message] });
      }
    });

    return groupedMessages;
  };

  const handleSendMessage = async (data: ISubmitMessage) => {
    const { content, type, fileUrl } = data;
    if (!conversationId || !accountId || !content) {
      return;
    }

    const newMessage: IMessage = {
      id: generateUniqueId(),
      content: content ?? '',
      type: type,
      status: MessageStatusEnum.DELIVERED,
      createdAt: new Date(),
      senderAccountId: accountId,
      senderAvatarUrl: accountInformation.avatarUrl,
      senderFirstName: accountInformation.firstName,
      senderLastName: accountInformation.lastName,
    };
    setListMessages([newMessage, ...listMessagesRef.current]);

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    const bodyCreateMessage: IBodyCreateMessage = {
      tempId: newMessage.id.toString(),
      content,
      type,
      socketId: socketId,
      fileUrl,
    };

    const formData = await createFormData(bodyCreateMessage, FileKeyEnum.FILES);
    handleCreateMessage({
      conversationId,
      formData,
    });
  };

  const handleFocus = () => {
    if (!wss || !conversationId) {
      return;
    }
    typing(wss, +conversationId);
  };

  const handleBlur = () => {
    if (!wss || !conversationId) {
      return;
    }
    stopTyping(wss, +conversationId);
  };

  const clearData = () => {
    setMemberInfo(undefined);
    setListMessages([]);
    setSearchKey(EMPTY_STRING);
    setIsLoadingMore(false);
    setTotalPages(DEFAULT_CURRENT_PAGE);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const clearDataOnSearch = () => {
    setListMessages([]);
    setIsLoadingConversation(false);
    setIsLoadingMore(false);
    setTotalPages(DEFAULT_CURRENT_PAGE);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };
  //#endregion Handle Function

  return (
    <div id='conversationComponent' className={cx('conversationContainer')}>
      {!isLoadingConversation && (
        <>
          <div className={cx('conversationHeader')}>
            <ConversationUserInfo data={memberInfo} isTyping={isTyping} />

            <ConversationActions onSearch={handleSearch} onCall={handleCall} />
          </div>

          <div ref={containerRef} className={cx('conversationContent')} onScroll={handleScroll}>
            {!isLoadingMessage ? (
              groupMessagesByDay(listMessages)?.map((messageGroup) => (
                <div key={messageGroup.day}>
                  <div className={cx('dateGroup')}>
                    <div className={cx('textDateGroup')}>{messageGroup.day}</div>
                  </div>

                  {messageGroup?.messages?.map((message) => (
                    <ConversationMessage key={message.id} data={message} />
                  ))}
                </div>
              ))
            ) : (
              <div className={cx('containerCenter')}>
                <Spinner />
              </div>
            )}
            {isLoadingMore && <Spinner />}
          </div>

          <div className={cx('conversationInputMessage')}>
            <ConversationInputMessage onSubmit={handleSendMessage} onFocus={handleFocus} onBlur={handleBlur} />
          </div>
        </>
      )}

      {isLoadingConversation && (
        <div className={cx('containerCenter')}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Conversation;
