import { urlApiLocation } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { IFormLocation, ILocation } from '~/utils/interface/crm/location';
import axiosClient from '../axiosClient';

const locationApi = {
  createLocation(body: IFormLocation) {
    const url = `${urlApiLocation.createLocation}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListLocation(params: IListQueryParams) {
    const url = `${urlApiLocation.getListLocation}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ILocation[]>>>(url, { params });
  },

  getDetailLocation(locationId: string) {
    const url = `${urlApiLocation.getDetailLocation(locationId)}`;
    return axiosClient.get<BaseResponse<ILocation>>(url);
  },
};

export default locationApi;
