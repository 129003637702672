// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Views } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  NewScheduleComponent,
  ModalUnderDevelopment,
  ToolBar,
  SelectStatus,
  FormShiftScheduleModal,
} from '~/components';
// Others
import { AccountRoleCodesEnum, ButtonTypeEnum, PageViewScheduleEnum } from '~/utils/enum';
import { TCalendarViewMode, TPageViewSchedule } from '~/utils/type/schedule';
import { optionsStatusShiftSchedule } from '~/mockData';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MultiViewSchedule.module.scss';
import { icons } from '~/assets';

type Props = {
  modeView: TCalendarViewMode;
  pageView: TPageViewSchedule;
  role: AccountRoleCodesEnum;
};

const cx = classNames.bind(styles);

const MultiViewSchedule = (props: Props) => {
  //#region Destructuring Props
  const { modeView, pageView, role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isOpenAddScheduleModal, setIsAddScheduleModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowAll = () => {
    // @TODO: Handle Print
    setIsShowModalUnderDevelopment(true);
  };

  const handleSortBy = () => {
    // @TODO: Handle Sort By
    setIsShowModalUnderDevelopment(true);
  };

  const handleFilter = () => {
    // @TODO: Handle Filter
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowModalAddSchedule = () => {
    setIsAddScheduleModal(!isOpenAddScheduleModal);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleNavigate = () => {
    if (
      (role === AccountRoleCodesEnum.ADMIN ||
        role === AccountRoleCodesEnum.SUPER_ADMIN ||
        role === AccountRoleCodesEnum.EMPLOYEE) &&
      (pageView === PageViewScheduleEnum.CLIENTS || pageView === PageViewScheduleEnum.CAREGIVER)
    ) {
      const routeMap = {
        [AccountRoleCodesEnum.SUPER_ADMIN]: {
          [PageViewScheduleEnum.CLIENTS]: superAdminRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: superAdminRouteAbsolute.weeklyCaregivers,
        },
        [AccountRoleCodesEnum.ADMIN]: {
          [PageViewScheduleEnum.CLIENTS]: adminRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: adminRouteAbsolute.weeklyCaregivers,
        },
        [AccountRoleCodesEnum.EMPLOYEE]: {
          [PageViewScheduleEnum.CLIENTS]: staffRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: staffRouteAbsolute.weeklyCaregivers,
        },
      };

      const route = routeMap[role]?.[pageView];

      if (route) {
        navigate(route);
      }
    }
  };

  const handleChangeTitle = (modeView: string, pageView: string) => {
    const titleMapping: Record<string, Record<string, string>> = {
      [Views.DAY]: {
        [PageViewScheduleEnum.CLIENTS]: t('tab_daily_clients_schedule'),
        [PageViewScheduleEnum.CAREGIVER]: t('tab_daily_caregivers_schedule'),
      },
      [Views.WEEK]: {
        [PageViewScheduleEnum.CLIENTS]: t('tab_weekly_clients_schedule'),
        [PageViewScheduleEnum.CAREGIVER]: t('tab_weekly_caregivers'),
      },
    };

    return titleMapping[modeView]?.[pageView] || EMPTY_STRING;
  };

  //#endregion Handle Function

  return (
    <div id='multiViewScheduleComponent' className={cx('container')}>
      <ToolBar title={handleChangeTitle(modeView, pageView)}>
        <SelectStatus
          options={optionsStatusShiftSchedule}
          placeholder={t('common_placeholder_select')}
          width={220}
          height={36}
        />
        <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('schedule_tab_button_add_schedule')}
          onClick={handleShowModalAddSchedule}
        />
      </ToolBar>

      <div className={cx('content')}>
        <NewScheduleComponent pageView={pageView} view={modeView} onNavigate={handleNavigate} />
      </div>

      {isOpenAddScheduleModal && (
        <FormShiftScheduleModal isOpen={isOpenAddScheduleModal} onClose={handleShowModalAddSchedule} />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default MultiViewSchedule;
