// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_DOT_TABLE_OPTION } from '~/utils/constants/common';
import { StatusEnum } from '~/utils/enum';
import { IPermissions } from '~/utils/interface/common';
// Styles=, images, icons
import { icons } from '~/assets';
import styles from './ThreeDotTableOptions.module.scss';

type Props<T> = {
  data?: T;
  permissions?: IPermissions;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onDeactivate?: () => void;
  onCall?: () => void;
  onSendMessage?: () => void;
  onSendEmail?: () => void;
  onViewOnQuickBooks?: () => void;
  onTerminated?: () => void;
};

const cx = classNames.bind(styles);

const ThreeDotTableOptions = <T extends Record<string, any>>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    data,
    onView,
    onEdit,
    onDeactivate,
    onCall,
    onSendMessage,
    onSendEmail,
    onDelete,
    permissions,
    onViewOnQuickBooks,
    onTerminated,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [permissionsAction, setPermissionsAction] = useState<IPermissions>(DEFAULT_DOT_TABLE_OPTION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setPermissionsAction((prev) => ({
      ...prev,
      ...permissions,
    }));
  }, [permissions]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleView = (closeFn: Function) => () => {
    onView && onView();
    closeFn();
  };

  const handleEdit = (closeFn: Function) => () => {
    onEdit && onEdit();
    closeFn();
  };

  const handleDeactivate = (closeFn: Function) => () => {
    onDeactivate && onDeactivate();
    closeFn();
  };

  const handleCall = (closeFn: Function) => () => {
    onCall && onCall();
    closeFn();
  };

  const handleSendMessage = (closeFn: Function) => () => {
    onSendMessage && onSendMessage();
    closeFn();
  };

  const handleSendEmail = (closeFn: Function) => () => {
    onSendEmail && onSendEmail();
    closeFn();
  };

  const handleDelete = (closeFn: Function) => () => {
    onDelete && onDelete();
    closeFn();
  };

  const handleViewOnQuickBooks = (closeFn: Function) => () => {
    onViewOnQuickBooks && onViewOnQuickBooks();
    closeFn();
  };

  const handleTerminated = (closeFn: Function) => () => {
    onTerminated && onTerminated();
    closeFn();
  };

  const renderNoActionTable = () => {
    if (
      !permissionsAction.isView &&
      !permissionsAction.isEdit &&
      !permissionsAction.isDelete &&
      !permissionsAction.isSendMessage &&
      !permissionsAction.isDeactivate &&
      !permissionsAction.isViewOnQuickBooks &&
      !permissionsAction.isTerminated
    ) {
      return <p className={cx('labelText')}>{t('common_label_no_action_available')}</p>;
    }
  };
  //#endregion Handle Function

  return (
    <div id='baseSortComponent'>
      <Popover className='relative'>
        <PopoverButton className={cx('btnGroup')}>
          <img src={icons.commonIconThreeDotHorizontal} alt={t('common_img_text_alt')} className={cx('btnImg')} />
        </PopoverButton>

        <PopoverPanel transition anchor={{ to: 'bottom end', gap: '4px' }} className={cx('popupContainer')}>
          {({ close }) => (
            <div className={cx('popBtnGroup')}>
              {permissionsAction.isView && (
                <button onClick={handleView(close)} className={cx('buttonOption')}>
                  <img src={icons.signInIconEyeShow} alt={t('common_text_view')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_view')}</span>
                </button>
              )}
              {permissionsAction.isViewOnQuickBooks && (
                <button onClick={handleViewOnQuickBooks(close)} className={cx('buttonOption')}>
                  <img src={icons.signInIconEyeShow} alt={t('common_text_view')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_view_quick_books')}</span>
                </button>
              )}
              {permissionsAction.isEdit && (
                <button onClick={handleEdit(close)} className={cx('buttonOption')}>
                  <img src={icons.commonIconEdit} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_edit')}</span>
                </button>
              )}
              {permissionsAction.isTerminated && (
                <button onClick={handleTerminated(close)} className={cx('buttonTerminated')}>
                  <img src={icons.commonIconDeactivate} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelDelete')}>{t('common_action_terminated')}</span>
                </button>
              )}
              {permissionsAction.isDelete && (
                <button onClick={handleDelete(close)} className={cx('buttonDelete')}>
                  <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelDelete')}>{t('common_delete_label')}</span>
                </button>
              )}
              {permissionsAction.isCall && (
                <button onClick={handleCall(close)} className={cx('buttonOption')}>
                  <img src={icons.crmIconCall} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_call')}</span>
                </button>
              )}
              {permissionsAction.isSendMessage && (
                <button onClick={handleSendMessage(close)} className={cx('buttonOption')}>
                  <img src={icons.crmIconSms} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_sms')}</span>
                </button>
              )}
              {permissionsAction.isSendEmail && (
                <button onClick={handleSendEmail(close)} className={cx('buttonOption')}>
                  <img src={icons.crmIconSendEmail} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_email')}</span>
                </button>
              )}
              {permissionsAction.isDeactivate && (
                <button
                  onClick={handleDeactivate(close)}
                  className={cx(data?.status === StatusEnum.ACTIVE ? 'buttonDelete' : 'buttonOption')}
                >
                  <img
                    src={data?.status === StatusEnum.ACTIVE ? icons.commonIconDeactivate : icons.commonIconTick}
                    alt={t('common_img_text_alt')}
                    width={16}
                    height={16}
                  />
                  <span className={cx(data?.status === StatusEnum.ACTIVE ? 'labelDelete' : 'labelText')}>
                    {data?.status === StatusEnum.ACTIVE ? t('common_action_deactivate') : t('common_action_activate')}
                  </span>
                </button>
              )}
              {renderNoActionTable()}
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default ThreeDotTableOptions;
