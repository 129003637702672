import { createSlice } from '@reduxjs/toolkit';
import { createLocation, getDetailLocation, getListLocations } from './locationThunk';
import { RootState } from '~/redux/store';

export interface LocationState {
  isRefreshListLocation: boolean;
}

const initialState: LocationState = {
  isRefreshListLocation: false,
};

const locationSlice = createSlice({
  name: 'locationState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshListLocation = action.payload;
    },
  },

  extraReducers(builder) {
    // Create Location
    builder
      .addCase(createLocation.pending, (state) => {})
      .addCase(createLocation.fulfilled, (state) => {
        state.isRefreshListLocation = true;
      })
      .addCase(createLocation.rejected, (state) => {});

    // Get List Location
    builder
      .addCase(getListLocations.pending, (state) => {})
      .addCase(getListLocations.fulfilled, (state) => {
        state.isRefreshListLocation = false;
      })
      .addCase(getListLocations.rejected, (state) => {});

    // Get Detail Location
    builder
      .addCase(getDetailLocation.pending, (state) => {})
      .addCase(getDetailLocation.fulfilled, (state) => {})
      .addCase(getDetailLocation.rejected, (state) => {});
  },
});

// Actions
export const clientActions = locationSlice.actions;

// Selectors
export const selectIsRefreshLocation = (state: RootState) => state.locationState.isRefreshListLocation;

// Reducer
const locationReducer = locationSlice.reducer;
export default locationReducer;
