// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  FormTimeClockModal,
  BaseButton,
  BaseDrawerModal,
  BasePagination,
  BaseTable,
  CurrentTask,
  ImageCircle,
  InputSearch,
  ModalUnderDevelopment,
  StatusLabel,
  ThreeDotTableOptions,
  TimeClockDetail,
  ToolBar,
  ConfirmModal,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  ImageCircleTypeEnum,
  StatusEnum,
  StatusTimeClockTypeEnum,
  StorageEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import {
  IChangeStatusTimeClockBody,
  ICurrentTimeCLock,
  IListTimeClock,
  ITimeClockResp,
} from '~/utils/interface/timeClock';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  EMPTY_STRING,
  HOURS_UNIT,
} from '~/utils/constants/common';
import { convertTime, getUserName, convertMinuteToHours, convertDateToFormatTime } from '~/utils/helper';
import { changeStatusTimeClock, deleteTimeClock, getListTimeClock } from '~/thunks/timeClock/timeClockThunk';
import useDebounce from '~/utils/customHook';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { selectIsRefreshTimeClockList, timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { adminRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './TimeClock.module.scss';
import { icons } from '~/assets';

type Props = {
  role: AccountRoleCodesEnum;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetail: (id: string) => void,
  handleEdit: (id: string) => void,
  handleDelete: (id: string) => void,
  role?: AccountRoleCodesEnum
): ColumnTableType<IListTimeClock>[] => {
  // return [
  //   {
  //     key: 'client',
  //     title: t('time_clock_page_table_client_label'),
  //     render: (_, record) => {
  //       return !record.firstName && !record.lastName ? (
  //         <>{EMPTY_STRING}</>
  //       ) : (
  //         <>
  //           <ImageCircle
  //             type={record.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
  //             imageUrl={record.avatarUrl}
  //             firstName={record.firstName}
  //             lastName={record.lastName}
  //             width={24}
  //             height={24}
  //             fontSize={10}
  //             margin={'0 6px 0 0'}
  //           />
  //           <span>{getUserName(record.firstName, record.lastName)}</span>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     key: 'task',
  //     title: t('time_clock_page_table_task_label'),
  //     render: (_, record) => {
  //       return <>{record.task?.name || EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'date',
  //     title: t('time_clock_page_table_date_label'),
  //     render: (_, record) => {
  //       return <>{record.date ? convertTime(record.date, TimeFormatEnum.MMMM_DD_YYYY) : EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'hours',
  //     title: t('time_clock_page_table_hours_label'),
  //     render: (_, record) => {
  //       return <>{convertToHours(record.startTime, record.endTime) || EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'jobCode',
  //     title: t('time_clock_page_table_job_code_label'),
  //     render: (_, record) => {
  //       return <>{EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'checkIn',
  //     title: t('time_clock_page_table_check_in_label'),
  //     render: (_, record) => {
  //       return <>{record.startTime ? convertTime(record.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'checkOut',
  //     title: t('time_clock_page_table_check_out_label'),
  //     render: (_, record) => {
  //       return <>{record.endTime ? convertTime(record.endTime, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'location',
  //     title: t('time_clock_page_table_location_label'),
  //     render: (_, record) => {
  //       return <>{record.location || EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'status',
  //     title: t('time_clock_page_table_status_label'),
  //     render: (_, record) => {
  //       return <>{record.status ? <StatusLabel bgOpacity={0.1} label={record.status} /> : EMPTY_STRING}</>;
  //     },
  //   },
  //   {
  //     key: 'action',
  //     title: t('time_clock_page_table_action_label'),
  //     render: (_, record) => {
  //       return (
  //         <ThreeDotTableOptions
  //           data={record}
  //           permissions={{
  //             isEdit: role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.CAREGIVER,
  //             isDelete: false,
  //           }}
  //           onView={() => handleViewDetail(record.id)}
  //           onEdit={() => handleEdit(record.id)}
  //         />
  //       );
  //     },
  //     width: 100,
  //   },
  // ];
  return [
    {
      key: 'name',
      title: t('time_clock_page_table_name_label'),
      render: (_, record) => {
        return !record.account.firstName && !record.account.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record.account.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record.account.avatarUrl}
              firstName={record.account.firstName}
              lastName={record.account.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record.account.firstName, record.account.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'date',
      title: t('time_clock_page_table_date_label'),
      render: (_, record) => {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'shift',
      title: t('time_clock_page_table_client_label'),
      render: (_, record) => {
        if (!record.shift || !record.shift.client) {
          return null;
        }

        return !record.shift.client.firstName && !!record.shift.client.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record.shift.client.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record.shift.client.avatarUrl}
              firstName={record.shift.client.firstName}
              lastName={record.shift.client.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record.shift.client.firstName, record.shift.client.lastName)}</span>
          </>
        );
      },
      width: 300,
    },
    {
      key: 'hours',
      title: t('time_clock_page_table_hours_label'),
      render: (_, record) => {
        return (
          <>{record?.duration ? `${convertMinuteToHours(Number(record.duration))} ${HOURS_UNIT}` : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'checkIn',
      title: t('time_clock_page_table_check_in_label'),
      render: (_, record) => {
        return <>{record.checkIn ? convertTime(record.checkIn, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'checkOut',
      title: t('time_clock_page_table_check_out_label'),
      render: (_, record) => {
        return <>{record.checkOut ? convertTime(record.checkOut, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'location',
      title: t('time_clock_page_table_location_label'),
      render: (_, record) => {
        return <>{record.location?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('time_clock_page_table_status_label'),
      render: (_, record) => {
        return <>{record.status ? <StatusLabel bgOpacity={0.1} label={record.status} /> : EMPTY_STRING}</>;
      },
    },
    {
      key: 'action',
      title: t('time_clock_page_table_action_label'),
      render: (_, record) => {
        const { status } = record;
        const isStatus = status !== StatusEnum.COMPLETED && status !== StatusEnum.EXPIRED;
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit: (role === AccountRoleCodesEnum.EMPLOYEE || role === AccountRoleCodesEnum.CAREGIVER) && isStatus,
              isDelete: false,
            }}
            onView={() => handleViewDetail(record.id)}
            onEdit={() => handleEdit(record.id)}
            // onDelete={() => handleDelete(record?.id)}
          />
        );
      },
      width: 50,
    },
  ];
};

const TimeClock = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshTimeClockList = useSelector(selectIsRefreshTimeClockList);
  //#endregion Selector

  //#region Declare State
  const [currentTimeClock, setCurrentTimeClock] = useState<ICurrentTimeCLock>();
  // const [timeClockList, setTimeClockList] = useState<IListTimeClock[]>();
  const [timeClockList, setTimeClockList] = useState<IListTimeClock[]>([]);
  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [timeClockId, setTimeClockId] = useState<string>();
  const [isShowTimeClockModal, setIsShowTimeClockModal] = useState<boolean>(false);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowTimeClockDetail, setIsShowTimeClockDetail] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  // useEffect(() => {
  //   const newParamObject: IListQueryParams = {
  //     ...paramObject,
  //     ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
  //   };

  //   handleGetListTimeClock(newParamObject);
  //   const newParam = debouncedSearchKey
  //     ? {
  //         ...params,
  //         page: paramObject.page.toString(),
  //         limit: DEFAULT_LIMIT_PAGE.toString(),
  //         textSearch: debouncedSearchKey,
  //       }
  //     : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

  //   setSearchParams(newParam);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paramObject]);

  // useEffect(() => {
  //   if (!pagination) return;
  //   setParamObject({
  //     ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
  //     page: DEFAULT_CURRENT_PAGE,
  //     limit: DEFAULT_LIMIT_PAGE,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedSearchKey]);

  // useEffect(() => {
  //   if (!isRefreshTimeClockList) {
  //     return;
  //   }

  //   handleGetListTimeClock(paramObject);
  //   dispatch(timeClockActions.setRefreshList(false));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isRefreshTimeClockList]);

  useEffect(() => {
    const newParamObject: IListQueryParams = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListTimeClock(newParamObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFilter = () => {
    // TODO: Handle Later
    handleOpenModalUnderDevelopment();
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleViewDetail = (id?: string) => {
    // if (id) {
    //   setTimeClockId(id);
    // }

    // setIsShowTimeClockDetail(!isShowTimeClockDetail);
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.timeClock}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.timeClock}/${id}`);
        return;

      default:
        return;
    }
  };

  const handleEdit = (id?: string) => {
    if (id) {
      setTimeClockId(id);
    }

    setIsShowTimeClockModal(!isShowTimeClockModal);
  };

  const handleVisibleTimeClockModal = () => {
    setTimeClockId(EMPTY_STRING);
    setIsShowTimeClockModal(!isShowTimeClockModal);
  };

  const handleOpenModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const handleBreakCurrentTask = () => {
    handleChangeStatusTimeClock(StatusTimeClockTypeEnum.BREAK);
  };

  const handleCheckOutCurrentTask = () => {
    handleChangeStatusTimeClock(StatusTimeClockTypeEnum.CHECK_OUT);
  };

  const handleContinueCurrentTask = () => {
    handleChangeStatusTimeClock(StatusTimeClockTypeEnum.RESUME);
  };

  const handleGetListTimeClock = (params: IListQueryParams) => {
    loading?.show();

    dispatch(getListTimeClock(params))
      .unwrap()
      .then((res) => {
        const { responses: listTimeClock, pagination, currentTimeClock }: ITimeClockResp = res?.data;
        setTimeClockList(listTimeClock);
        setPagination(pagination);
        setCurrentTimeClock(currentTimeClock);
      })
      .catch((_error) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeStatusTimeClock = (status: StatusTimeClockTypeEnum) => {
    if (!currentTimeClock?.id || !status) return;
    loading?.show();

    const data: IChangeStatusTimeClockBody = {
      timeClockId: currentTimeClock?.id,
      body: {
        type: status,
      },
    };

    dispatch(changeStatusTimeClock(data))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleDeleteTimeClock = () => {
    if (!timeClockId) return;

    loading?.show();
    dispatch(deleteTimeClock(timeClockId))
      .unwrap()
      .then((_res) => {
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((_error) => {})
      .finally(() => {
        loading?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowModalDelete = (id?: string) => {
    handleOpenModalUnderDevelopment();
    // setIsShowConfirmDelete(!isShowConfirmDelete);
    // setTimeClockId(id);
  };

  const handleTableRowClick = (data: IListTimeClock) => {
    if (!data || !data?.id) return;

    handleViewDetail(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='timeClockPage' className={cx('container')}>
      {(role === AccountRoleCodesEnum.CAREGIVER || role === AccountRoleCodesEnum.EMPLOYEE) && currentTimeClock && (
        <CurrentTask
          data={currentTimeClock}
          onBreak={handleBreakCurrentTask}
          onView={handleEdit}
          onCheckOut={handleCheckOutCurrentTask}
          onContinue={handleContinueCurrentTask}
        />
      )}
      <div className={cx('contentWrap')}>
        <div className={cx('headerToolbar')}>
          <ToolBar title={t('time_clock_page_title')}>
            {/* <BaseButton
              iconLeft={icons.commonIconSort}
              text={t('common_text_filter')}
              width={67}
              height={36}
              onClick={handleFilter}
            /> */}

            <InputSearch
              height={36}
              placeholder={t('common_placeholder_search_by_name')}
              onChange={handleChangeSearch}
              value={textSearchParams}
            />

            {/* {role !== AccountRoleCodesEnum.ADMIN && (
              <BaseButton
                iconLeft={icons.commonIconPlus}
                text={t('time_clock_page_button_add_new')}
                height={36}
                typeStyle={ButtonTypeEnum.PRIMARY}
                onClick={handleVisibleTimeClockModal}
              />
            )} */}

            {role !== AccountRoleCodesEnum.ADMIN && role !== AccountRoleCodesEnum.SUPER_ADMIN && (
              <BaseButton
                iconLeft={icons.commonIconPlus}
                text={t('time_clock_page_button_add_new')}
                height={36}
                typeStyle={ButtonTypeEnum.PRIMARY}
                onClick={handleVisibleTimeClockModal}
              />
            )}
          </ToolBar>
        </div>

        <div className={cx('content')}>
          <div className={cx('tableWrap')}>
            <BaseTable
              onClickRow={handleTableRowClick}
              columns={columns(t, handleViewDetail, handleEdit, handleShowModalDelete, role)}
              dataSource={timeClockList ?? []}
            />
          </div>

          <div className={cx('paginationTable')}>
            <BasePagination
              onChange={handlePaginationChange}
              defaultCurrentPage={pageSelected}
              totalItems={pagination?.totalItems}
              totalPages={pagination?.totalPages}
            />
          </div>
        </div>
      </div>

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleOpenModalUnderDevelopment} />}

      {isShowTimeClockModal && (
        <FormTimeClockModal
          timeClockId={timeClockId}
          isOpen={isShowTimeClockModal}
          onClose={handleVisibleTimeClockModal}
          role={role}
        />
      )}

      {/* <BaseDrawerModal isOpen={isShowTimeClockDetail} onClose={handleViewDetail}>
        <TimeClockDetail timeClockId={timeClockId} />
      </BaseDrawerModal> */}

      {isShowConfirmDelete && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: 'this time clock',
          })}
          titleAction={t('common_delete_label')}
          onCancel={handleShowModalDelete}
          onAction={handleDeleteTimeClock}
        />
      )}
    </div>
  );
};
export default TimeClock;
