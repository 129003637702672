// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  AddApplicantModal,
  BaseButton,
  BasePagination,
  BaseTable,
  ButtonStatus,
  ImageCircle,
  InputSearch,
  Modal,
  ToolBar,
} from '~/components';
// Others
import { ColumnTableType, IListDataResponse, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  ImageCircleTypeEnum,
  statusEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import { getUserName } from '~/utils/helper';
import { adminRouteAbsolute } from '~/utils/constants/route';
import { DEFAULT_CURRENT_PAGE, DEFAULT_DELAY_TIME, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { IApplicant } from '~/utils/interface/applicant';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import useDebounce from '~/utils/customHook';
import { getListApplicant } from '~/thunks/applicant/applicantThunk';
import { applicantActions, selectIsRefreshApplicantList } from '~/thunks/applicant/applicantSlice';
// Styles, images, icons
import styles from './RequestApplicant.module.scss';
import { icons } from '~/assets';
import { RequestApplicantTemplate } from '~/pages';

type Props = {};

const cx = classNames.bind(styles);

const RequestApplicant = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='requestApplicantPage' className={cx('requestApplicantContainer')}>
      <RequestApplicantTemplate role={role as AccountRoleCodesEnum} />
    </div>
  );
};

export default RequestApplicant;
