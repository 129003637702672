// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import reportApi from '~/apis/report';
import {
  GET_ALL_CAREGIVERS_BY_LOCATION,
  GET_ALL_CLIENTS_BY_LOCATION,
  GET_LOCATIONS_CAREGIVER_IN_REPORT,
} from '~/utils/constants/actionType';
import { IQueryGetAllUserByLocation, IQueryGetLocationsCaregiverInReport } from '~/utils/interface/report';

export const getLocationsCaregiverInReport = createAsyncThunk(
  GET_LOCATIONS_CAREGIVER_IN_REPORT,
  async (payload: IQueryGetLocationsCaregiverInReport, { rejectWithValue }) => {
    try {
      const res = await reportApi.getLocationsCaregiverInReport(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllCaregiversByLocation = createAsyncThunk(
  GET_ALL_CAREGIVERS_BY_LOCATION,
  async (payload: IQueryGetAllUserByLocation, { rejectWithValue }) => {
    try {
      const res = await reportApi.getAllCaregiversByLocation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllClientsByLocation = createAsyncThunk(
  GET_ALL_CLIENTS_BY_LOCATION,
  async (payload: IQueryGetAllUserByLocation, { rejectWithValue }) => {
    try {
      const res = await reportApi.getAllClientsByLocation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
