import { createAsyncThunk } from '@reduxjs/toolkit';
import rateApi from '~/apis/rates';
import { GET_LIST_RATE } from '~/utils/constants/actionType';
import { IListQueryParamsRate } from '~/utils/interface/rate';

export const getListRate = createAsyncThunk(
  GET_LIST_RATE,
  async (payload: IListQueryParamsRate, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListRate(payload);
      return { data: res.data, userType: payload.userType };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
