// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, ModalUnderDevelopment, ToolBar, SelectStatus, OfficeAndShiftSchedule } from '~/components';
// Context
// Others
import { ButtonTypeEnum, PageViewScheduleEnum } from '~/utils/enum';
import { selectAllTypesOptions } from '~/mockData';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './OfficeCalendar.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const OfficeCalendar = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [typeStatusSelect, setTypeStatusSelect] = useState<string>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const typeStatus: string = String(selectAllTypesOptions[DEFAULT_NUMBER_ZERO].value);

    setTypeStatusSelect(typeStatus);
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSortBy = () => {
    // @TODO: Handle Sort by
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowModalAddSchedule = () => {
    setIsShowModalUnderDevelopment(true);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <div id='openShiftsCalendarPage' className={cx('container')}>
      <ToolBar title={t('page_office_calendar_title')}>
        <SelectStatus
          options={selectAllTypesOptions}
          placeholder={t('common_select_placeholder')}
          width={220}
          height={36}
          value={typeStatusSelect}
        />
        <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('schedule_tab_button_add_schedule')}
          onClick={handleShowModalAddSchedule}
        />
      </ToolBar>

      <div className={cx('content')}>
        <OfficeAndShiftSchedule pageView={PageViewScheduleEnum.OFFICE} />
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default OfficeCalendar;
