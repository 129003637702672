// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseSelectSingle,
  BaseTable,
  CaregiverReferralModal,
  ImageCircle,
  InputSearch,
  Modal,
  ModalUnderDevelopment,
  ThreeDotOptionsCaregiverReferral,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import { ColumnTableType, Option } from '~/utils/interface/common';
import { ButtonTypeEnum, ImageCircleTypeEnum, TranslationEnum } from '~/utils/enum';
import { formatPhoneNumber, getRoutesByRole, getUserName } from '~/utils/helper';
import { ICaregiverReferral } from '~/mockData/mockInterface';
import { caregiverReferralShowAllData, dataSelectCaregiverReferral } from '~/mockData';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CaregiverReferralShowAll.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<ICaregiverReferral>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_referral_show_all_name_title'),
      render(_, record: ICaregiverReferral) {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'referralID',
      title: t('caregiver_referral_show_all_referral_id_title'),
      dataIndex: 'referralID',
      render(_, record: ICaregiverReferral) {
        return (
          <div className={cx('textBase')}>
            {record?.referralID ? <span>{record.referralID}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'address',
      title: t('caregiver_referral_show_all_address_title'),
      dataIndex: 'address',
      render(_, record: ICaregiverReferral) {
        return (
          <div className={cx('textBase')}>
            {record?.address ? <span>{record.address}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'email',
      title: t('caregiver_referral_show_all_email_title'),
      dataIndex: 'email',
      render(_, record: ICaregiverReferral) {
        return (
          <div className={cx('textBase')}>
            {record?.email ? <span>{record.email}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'phoneNumber',
      title: t('caregiver_referral_show_all_phone_number_title'),
      dataIndex: 'phoneNumber',
      render(_, record: ICaregiverReferral) {
        return (
          <div className={cx('textBase')}>
            {record?.phoneNumber ? <span>{formatPhoneNumber(record?.phoneNumber)}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    // {
    //   key: 'action',
    //   title: t('caregiver_referral_show_all_action_title'),
    //   render(_) {
    //     return (
    //       <button onClick={onAction}>
    //         <ThreeDotTableOptions />
    //       </button>
    //     );
    //   },
    //   width: '12%',
    // },
  ];
};

const CaregiverReferralShowAll = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedData, setSelectedData] = useState<Option>();
  const [isOpenModalReferral, setIsOpenModalReferral] = useState<boolean>(false);
  const [dataRecordTable, setDataRecordTable] = useState<ICaregiverReferral>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: HuyPahmGRF-ADB - Handle change search
  };

  const handleAddEditReferral = () => {
    setIsOpenModalReferral(!isOpenModalReferral);
  };

  const handleSelectChange = (selected: Option) => {
    setSelectedData(selected);
  };

  const handlePaginationChange = (page: number) => {};

  const handleClickUnderdevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleCaregiverReferralRowClick = (data: ICaregiverReferral) => {
    handleClickUnderdevelopment();
  };
  //#endregion Handle Function

  return (
    <div id='caregiverReferralShowAllPage' className={cx('container')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('caregiver_referral_show_all_referral_title')}>
          {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} height={36} /> */}
          <InputSearch height={36} placeholder={t('common_placeholder_search')} onChange={handleChangeSearch} />
          <BaseSelectSingle
            width={96}
            height={36}
            icon={icons.commonIconArrowBottom}
            iconWidthHeight={14}
            options={dataSelectCaregiverReferral}
            onChange={handleSelectChange}
          />
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_referral_show_all_add_referral_button')}
            onClick={handleAddEditReferral}
            width={130}
            height={36}
          />
        </ToolBar>
      </div>

      <div className={cx('baseTable')}>
        <BaseTable
          columns={columns(t)}
          dataSource={caregiverReferralShowAllData}
          onClickRow={handleCaregiverReferralRowClick}
        />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination onChange={handlePaginationChange} defaultCurrentPage={1} totalItems={1} totalPages={1} />
      </div>

      <Modal
        isOpen={isOpenModalReferral}
        onClose={handleAddEditReferral}
        title={dataRecordTable ? t('caregiver_modal_edit_referral_title') : t('caregiver_modal_add_referral_title')}
      >
        <CaregiverReferralModal onClose={handleAddEditReferral} data={dataRecordTable} />
      </Modal>

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderdevelopment} />}
    </div>
  );
};

export default CaregiverReferralShowAll;
