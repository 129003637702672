// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
import { useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages

// Others
import { getDetailLocation } from '~/thunks/crm/location/locationThunk';
import { ILocation } from '~/utils/interface/crm/location';
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { convertEnumToString } from '~/utils/helper';
import { BaseButton, BaseGoogleMap, ToolBar } from '~/components';
import { googleMapApiKey } from '~/utils/constants/env';
import { AccountRoleCodesEnum, ButtonTypeEnum, StorageEnum } from '~/utils/enum';
import { adminRoute, superAdminRoute } from '~/utils/constants/route';
import { LoadingData } from '~/context';

// Styles, images, icons
import styles from './CrmLocationDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CrmLocationDetail = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadingData = useContext(LoadingData);
  const { locationId } = useParams();
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [locationDetail, setLocationDetail] = useState<ILocation>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!locationId) return;
    handleGetDetailLocation();
  }, [locationId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailLocation = () => {
    loadingData?.show();
    dispatch(getDetailLocation(locationId || EMPTY_STRING))
      .unwrap()
      .then((_res) => {
        const locationDetail = _res?.data;
        setLocationDetail(locationDetail);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleBack = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmLocation}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.crm}${adminRoute.crmLocation}`);
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='crmLocationDetailTemplate' className={cx('container')}>
      <BaseButton
        onClick={handleBack}
        text={t('common_text_back')}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        height={17}
      />

      <div className={cx('headerToolBar')}>
        <ToolBar title={t('location_detail_title')}></ToolBar>
      </div>

      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_name_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.name || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_type_label')}</span>
            <span className={cx('valueContent')}>
              <span>{convertEnumToString(locationDetail?.type) || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_address_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.address || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_line_second_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.secondaryAddress || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_city_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.city || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_state_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.state || EMPTY_STRING}</span>
            </span>
          </div>

          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_zip_code_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.zipCode || EMPTY_STRING}</span>
            </span>
          </div>
          <div className={cx('itemDetail')}>
            <span className={cx('textLabel')}>{t('location_detail_country_label')}</span>
            <span className={cx('valueContent')}>
              <span>{locationDetail?.country || EMPTY_STRING}</span>
            </span>
          </div>
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  locationDetail?.lat && locationDetail?.lng
                    ? { lat: locationDetail.lat, lng: locationDetail.lng }
                    : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmLocationDetail;
