import { useCallback, useState } from 'react';

interface ICoordinates {
  latitude: number;
  longitude: number;
}

interface ILocationError {
  code: number;
  message: string;
}

interface IUseUserLocationReturn {
  userLocation: ICoordinates | null;
  error: ILocationError | null;
  getUserLocation: () => void;
}

const useUserLocation = (): IUseUserLocationReturn => {
  const [userLocation, setUserLocation] = useState<ICoordinates | null>(null);
  const [error, setError] = useState<ILocationError | null>(null);

  const getUserLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setError({ code: 0, message: 'Geolocation is not supported by your browser.' });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });
        setError(null);
      },
      (err) => {
        const errorMessage = (() => {
          switch (err.code) {
            case 1:
              return 'Permission to access location was denied.';
            case 2:
              return 'Location is unavailable.';
            case 3:
              return 'Location request timed out.';
            default:
              return 'An unknown error occurred.';
          }
        })();

        setError({ code: err.code, message: errorMessage });
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
      }
    );
  }, []);

  return { userLocation, error, getUserLocation };
};

export default useUserLocation;
