// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseInput,
  BaseSelect,
  Loading,
  Modal,
  ModalUnderDevelopment,
  PlacesAutocomplete,
} from '~/components';
// Context

// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormLocation } from '~/utils/interface/crm/location';
import { IAddress } from '~/utils/interface/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { optionsTypeLocation } from '~/mockData';
import { createLocation, getDetailLocation } from '~/thunks/crm/location/locationThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import styles from './FormLocationModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    name: yup.string().trim().required(t('common_required_field')),
    type: yup.string().trim().required(t('common_required_field')),
    address: yup.string().trim().required(t('common_required_field')),
    secondaryAddress: yup.string().trim().optional(),
    city: yup.string().trim().required(t('common_required_field')),
    state: yup.string().trim().required(t('common_required_field')),
    zipCode: yup.string().trim().required(t('common_required_field')),
    country: yup.string().trim().required(t('common_required_field')),
  });
};

const defaultValues: IFormLocation = {
  name: EMPTY_STRING,
  type: EMPTY_STRING,
  address: EMPTY_STRING,
  secondaryAddress: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  country: EMPTY_STRING,
  lat: EMPTY_STRING,
  lng: EMPTY_STRING,
};

const FormLocationModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormLocation>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('zipCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.country &&
      setValue('country', address?.country ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lat', address?.lat ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lng', address?.lng ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleUnderDevelopmentModal = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleSubmitLocation = (data: IFormLocation) => {
    setIsLoading(true);

    const filteredData: Partial<IFormLocation> = {};
    Object.entries(data).forEach(([key, value]) => {
      if (
        (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
        (Array.isArray(value) && value?.length > DEFAULT_NUMBER_ZERO)
      ) {
        filteredData[key as keyof IFormLocation] = value;
      }
    });

    dispatch(createLocation(filteredData as IFormLocation))
      .unwrap()
      .then((_res) => {
        handleClose();
      })
      .catch((_error) => {})
      .finally(() => setIsLoading(!isLoading));
  };
  //#endregion Handle Function
  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t('form_location_modal_add_location_title')}>
      <form id='addLocationModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitLocation)}>
        <div className={cx('contentModal')}>
          <div className={cx('twoCol')}>
            <Controller
              name='name'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='name'
                  label={t('form_location_modal_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.name?.message}
                  required
                />
              )}
            />
            <Controller
              name='type'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionsTypeLocation || []}
                  label={t('form_location_modal_type_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  errorMessage={errors.type?.message}
                  required
                />
              )}
            />
          </div>
          <div className={cx('theeCol')}>
            <Controller
              name='address'
              control={control}
              render={({ field: { value, onChange } }) => (
                <PlacesAutocomplete
                  id='address'
                  value={value || EMPTY_STRING}
                  label={t('form_location_modal_address_label')}
                  onChange={(address) => handleChangeAddress(address)}
                  messageError={errors.address?.message}
                  required
                />
              )}
            />
            <Controller
              name='secondaryAddress'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='secondaryAddress'
                  label={t('form_location_modal_line_second_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name='city'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='city'
                  label={t('form_location_modal_city_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.city?.message}
                  required
                />
              )}
            />
          </div>
          <div className={cx('theeCol')}>
            <Controller
              name='state'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseInput
                  id='state'
                  value={value || EMPTY_STRING}
                  label={t('form_location_modal_state_label')}
                  onChange={onChange}
                  messageError={errors.state?.message}
                  required
                />
              )}
            />
            <Controller
              name='zipCode'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='zipCode'
                  label={t('form_location_modal_zip_code_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.zipCode?.message}
                  required
                />
              )}
            />
            <Controller
              name='country'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='country'
                  label={t('form_location_modal_country_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.country?.message}
                  required
                />
              )}
            />
          </div>
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} />
          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>
      </form>

      {isLoading && <Loading />}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleUnderDevelopmentModal} />}
    </Modal>
  );
};
export default FormLocationModal;
