// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';

// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormRateModal,
  InputSearch,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { DEFAULT_NUMBER_ONE, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType } from '~/utils/interface/common';
import { IRate } from '~/utils/interface/rate';
import { convertEnumToString } from '~/utils/helper';
import { mockDataClientRate } from '~/mockData';

// Styles, images, icons
import styles from './RateTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IRate>[] => {
  return [
    {
      key: 'rateName',
      title: t('rate_tab_rate_name_label'),
      dataIndex: 'name',
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '50%',
    },
    {
      key: 'rateType',
      title: t('rate_tab_type_label'),
      dataIndex: 'rateType',
      render(value, record, index) {
        return <>{record.rateType ? convertEnumToString(record.rateType) : EMPTY_STRING}</>;
      },
      width: '20%',
    },
    {
      key: 'userType',
      title: t('rate_tab_user_type_label'),
      dataIndex: 'userType',
      render(value, record, index) {
        return <>{record.userType ? convertEnumToString(record.userType) : EMPTY_STRING}</>;
      },
      width: '20%',
    },
    {
      key: 'action',
      title: t('rate_tab_action_label'),
      render: (_, record) => {
        return (
          <ThreeDotTableOptions
            permissions={{
              isView: false,
              isDelete: false,
              isEdit: false,
            }}
            data={record}
          />
        );
      },
    },
  ];
};

const RateTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [rateList, setRateList] = useState<IRate[]>([]);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowFormRateModal, setIsShowFormRateModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setRateList(mockDataClientRate);
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // @TODO: Handle Sort By
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowModalAddRate = () => {
    setIsShowFormRateModal(true);
  };

  const handleCloseFormRateModal = () => {
    setIsShowFormRateModal(false);
  };

  const handleChangeSearch = () => {
    // Handle Update Later
  };

  const handlePaginationChange = () => {
    // Handle Pagination Change
  };
  //#endregion Handle Function

  return (
    <div id='rateTabComponent' className={cx('container')}>
      <ToolBar title={EMPTY_STRING}>
        <InputSearch placeholder={t('common_placeholder_search_by_name')} width={185} onChange={handleChangeSearch} />
        <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('rate_tab_add_rate_title')}
          onClick={handleShowModalAddRate}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable columns={columns(t)} dataSource={rateList ?? []} />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            totalItems={DEFAULT_NUMBER_ONE}
            totalPages={DEFAULT_NUMBER_ONE}
          />
        </div>
      </div>
      {isShowFormRateModal && <FormRateModal isOpen={isShowFormRateModal} onClose={handleCloseFormRateModal} />}
      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default RateTab;
