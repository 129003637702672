// Others
import axiosClient from '~/apis/axiosClient';
import { urlApiReport } from '~/utils/constants/api';
import { BaseResponse } from '~/utils/interface/common';
import {
  IAllCaregiversByLocation,
  IAllClientsByLocation,
  ILocationCaregiver,
  IQueryGetAllUserByLocation,
  IQueryGetLocationsCaregiverInReport,
} from '~/utils/interface/report';

const reportApi = {
  getLocationsCaregiverInReport(params: IQueryGetLocationsCaregiverInReport) {
    const url = `${urlApiReport.getLocationsCaregiverInReport}`;
    return axiosClient.get<BaseResponse<ILocationCaregiver[]>>(url, { params });
  },

  getAllCaregiversByLocation(params: IQueryGetAllUserByLocation) {
    const url = `${urlApiReport.getAllCaregiversByLocation}`;
    return axiosClient.get<BaseResponse<IAllCaregiversByLocation>>(url, { params });
  },

  getAllClientsByLocation(params: IQueryGetAllUserByLocation) {
    const url = `${urlApiReport.getAllClientsByLocation}`;
    return axiosClient.get<BaseResponse<IAllClientsByLocation>>(url, { params });
  },
};

export default reportApi;
