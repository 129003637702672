export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const VERIFY_CODE = 'VERIFY_CODE';

// Employee
export const GET_LIST_EMPLOYEE = 'GET_LIST_EMPLOYEE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';

// Caregiver
export const GET_LIST_CAREGIVER = 'GET_LIST_CAREGIVER';
export const CREATE_CAREGIVER = 'CREATE_CAREGIVER';
export const EDIT_CAREGIVER = 'EDIT_CAREGIVER';
export const GET_DETAIL_CAREGIVER = 'GET_DETAIL_CAREGIVER';
export const DELETE_CAREGIVER = 'DELETE_CAREGIVER';
export const GET_PROFILE_TAGS = 'GET_PROFILE_TAGS';
export const GET_CAREGIVERS_BY_LOCATION = 'GET_CAREGIVERS_BY_LOCATION';

// User
export const GET_DETAIL_USER = 'GET_DETAIL_USER';
export const GET_DETAIL_PROFILE = 'GET_DETAIL_PROFILE';
export const CHANGE_STATUS_ACCOUNT = 'CHANGE_STATUS_ACCOUNT';
export const GET_LIST_ASSIGNEE = 'GET_LIST_ASSIGNEE';
export const DELETE_USER = 'DELETE_USER';

// Applicant
export const GET_LIST_APPLICANT = 'GET_LIST_APPLICANT';
export const CREATE_APPLICANT = 'CREATE_APPLICANT';

// Conversation
export const GET_LIST_CONVERSATION = 'GET_LIST_CONVERSATION';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const GET_DETAIL_CONVERSATION = 'GET_DETAIL_CONVERSATION';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

// Care Alert
export const GET_LIST_CARE_ALERT = 'GET_LIST_CARE_ALERT';

// Clients
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const GET_LIST_CLIENT = 'GET_LIST_CLIENT';
export const GET_DETAIL_CLIENT = 'GET_DETAIL_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPLOAD_FILE_CLIENT = 'UPLOAD_FILE_CLIENT';
export const GET_LIST_RELATES = 'GET_LIST_RELATES';
export const SEND_EMAIL_TO_CLIENT = 'SEND_EMAIL_TO_CLIENT';
export const CHANGE_STATUS_CLIENT = 'CHANGE_STATUS_CLIENT';
export const GET_DETAIL_CARE_PLAN = 'GET_DETAIL_CARE_PLAN';
export const CREATE_CARE_PLAN = 'CREATE_CARE_PLAN';
// Prospect
export const GET_LIST_PROSPECT = 'GET_LIST_PROSPECT';

// Referral
export const GET_LIST_REFERRALS = 'GET_LIST_REFERRALS';
export const CREATE_REFERRALS = 'CREATE_REFERRALS';
export const UPDATE_REFERRALS = 'UPDATE_REFERRALS';
export const GET_DETAIL_REFERRALS = 'GET_DETAIL_REFERRALS';

// File
export const GET_LIST_DOCUMENTS = 'GET_LIST_DOCUMENTS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const GET_DETAIL_DOCUMENT = 'GET_DETAIL_DOCUMENT';

// Task
export const GET_LIST_TASK = 'GET_LIST_TASK';
export const GET_SELECT_TASK = 'GET_SELECT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';

// Timeclock
export const GET_LIST_TIME_CLOCK = 'GET_LIST_TIME_CLOCK';
export const CREATE_TIME_CLOCK = 'CREATE_TIME_CLOCK';
export const GET_DETAIL_TIME_CLOCK = 'GET_DETAIL_TIME_CLOCK';
export const UPDATE_TIME_CLOCK = 'UPDATE_TIME_CLOCK';
export const DELETE_TIME_CLOCK = 'DELETE_TIME_CLOCK';
export const CHANGE_STATUS_TIME_CLOCK = 'CHANGE_STATUS_TIME_CLOCK';

// Shift Schedule
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const GET_LIST_SHIFT_SCHEDULE = 'GET_LIST_SHIFT_SCHEDULE';
export const GET_LIST_SCHEDULE = 'GET_LIST_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const GET_DETAIL_SCHEDULE = 'GET_DETAIL_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const GET_SCHEDULE_CLIENT = 'GET_SCHEDULE_CLIENT';
export const GET_OFFICE_SCHEDULE = 'GET_OFFICE_SCHEDULE';
export const GET_SCHEDULES_LIVE_VIEW = 'GET_SCHEDULES_LIVE_VIEW';
export const CREATE_SHIFT_SCHEDULE = 'CREATE_SHIFT_SCHEDULE';
export const UPDATE_SHIFT_SCHEDULE = 'UPDATE_SHIFT_SCHEDULE';
export const GET_DETAIL_SHIFT = 'GET_DETAIL_SHIFT';
export const GET_LIST_NOTE_SCHEDULE = 'GET_LIST_NOTE_SCHEDULE';
export const CREATE_SHIFT_NOTE_SCHEDULE = 'CREATE_SHIFT_NOTE_SCHEDULE';

// Dashboard
export const GET_STATISTIC_TOTAL = 'GET_STATISTIC_TOTAL';

// Activity
export const GET_LIST_ACTIVITY_BY_CLIENT = 'GET_LIST_ACTIVITY_BY_CLIENT';
export const GET_LIST_ACTIVITY_BY_CAREGIVER = 'GET_LIST_ACTIVITY_BY_CAREGIVER';

// QuickBooks
export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_TOKEN_QUICK_BOOK = 'GET_TOKEN_QUICK_BOOK';
export const DISCONNECT_QUICK_BOOK = 'DISCONNECT_QUICK_BOOK';

// Notifications
export const GET_LIST_NOTIFICATIONS = 'GET_LIST_NOTIFICATIONS';
export const MARKED_READ_NOTIFICATION = 'MARKED_READ_NOTIFICATION';

// Report
export const GET_LOCATIONS_CAREGIVER_IN_REPORT = 'GET_LOCATIONS_CAREGIVER_IN_REPORT';
export const GET_ALL_CAREGIVERS_BY_LOCATION = 'GET_ALL_CAREGIVERS_BY_LOCATION';
export const GET_ALL_CLIENTS_BY_LOCATION = 'GET_ALL_CLIENTS_BY_LOCATION';

// Rate
export const GET_LIST_RATE = 'GET_LIST_RATE';

// Location
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const GET_LIST_LOCATION = 'GET_LIST_LOCATION';
export const GET_DETAIL_LOCATION = 'GET_DETAIL_LOCATION';
