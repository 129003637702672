// Libs
import { Description, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames/bind';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { InputSearch } from '~/components';
// Others
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import { IBaseOption } from '~/utils/interface/common';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
import { TSelect } from '~/utils/type/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseSelect.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  options: IBaseOption[];
  name?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: IBaseOption, name?: string) => void;
  mode?: TSelect;
  icon?: string;
};

const cx = classNames.bind(styles);

const BaseSelect = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    height = DEFAULT_SELECT_HEIGHT,
    label,
    placeholder,
    errorMessage,
    options,
    name,
    value,
    required,
    disabled = false,
    onChange,
    mode,
    icon = icons.commonIconArrowBottom,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IBaseOption | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options;
    return options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [searchQuery, options]);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    if (!value) {
      setSelectOption(null);
      return;
    }
    const optionMatchWithValue = options?.find((item) => item.value === value);

    setSelectOption(optionMatchWithValue || null);
  }, [value, options]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (value: IBaseOption) => {
    setSelectOption(value);
    onChange && onChange(value, name ?? EMPTY_STRING);
  };
  //#endregion Handle Function

  return (
    <Field id='baseSelectComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('selectBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Listbox value={selectOption} onChange={handleOptionChange}>
        <ListboxButton className={cx('btnSelect', disabled && 'bgDisable')} style={{ height }} disabled={disabled}>
          {({ open }) => (
            <>
              {placeholder && !selectOption ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <span className={cx('btnText')}>{selectOption?.label}</span>
              )}
              <img src={icon} className={cx(open ? 'iconActive' : '')} alt={t('common_img_text_alt')} />
            </>
          )}
        </ListboxButton>

        <ListboxOptions className={cx('optionList', mode)} transition anchor={{ to: 'bottom', gap: '8px' }}>
          {mode && (
            <InputSearch
              fontSize={12}
              placeholder={t('common_placeholder_search')}
              height={32}
              value={selectOption?.label}
              onChange={handleSearch}
            />
          )}
          {filteredOptions.length > 0 ? (
            <div className={cx('options')}>
              {filteredOptions.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ selected, focus }) =>
                    cx(
                      'optionItem',
                      mode,
                      (selected || selectOption?.value === option.value) && 'optionActive',
                      focus && 'optionHover'
                    )
                  }
                >
                  {option.label}
                </ListboxOption>
              ))}
            </div>
          ) : (
            <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
          )}
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default BaseSelect;
