// Libs
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// Components, Layouts, Pages
import { CustomDateHeader, DatePickerFieldControl } from '~/components';
import { DatePickerFieldControlProps } from '~/components/common/baseDatePicker/fieldControl/DatePickerFieldControl';
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import 'react-datepicker/dist/react-datepicker.css';
import './BaseDatePicker.scss';

type Props = Omit<DatePickerFieldControlProps, 'value' | 'label'> & {
  value?: Date | string;
  label?: string;
  required?: boolean;
  disable?: boolean;
  onDateSelected?: (date: string, name?: string) => void;
  maxDate?: Date;
};

const BaseDatePicker = (props: Props) => {
  //#region Destructuring Props
  const {
    value,
    label,
    placeholderText,
    width,
    height,
    errorMessage,
    name,
    required = false,
    disable = false,
    onDateSelected,
    maxDate,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const currentYear = new Date().getFullYear();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateText, setDateText] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      setDateText('');
      return;
    }

    const date = moment(value).toDate();
    const dateString = moment(date).format('YYYY-MM-DD');

    setDateText(dateString);
    setSelectedDate(date);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSelectDate = (date: Date | null) => {
    if (!date) return;
    const dateString = moment(date).format('YYYY-MM-DD');

    setSelectedDate(date);
    setDateText(dateString);
    onDateSelected && onDateSelected(dateString, name ?? '');
  };
  //#endregion Handle Function
  return (
    <div id='baseDatePickerComponent' className='baseDatePickerContainer' style={{ width }}>
      {label && (
        <div className='labelField'>
          {label} {required && <span className={'required'}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className='baseDatePickerContent'>
        <DatePicker
          onSelect={handleSelectDate}
          selected={selectedDate}
          dateFormat='MM/dd/YYYY'
          showPopperArrow={false}
          disabled={disable}
          maxDate={maxDate}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <CustomDateHeader
              date={date}
              onChangeYear={changeYear}
              onChangeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          customInput={
            <DatePickerFieldControl
              value={dateText}
              width={width}
              height={height}
              placeholderText={placeholderText}
              errorMessage={errorMessage}
              disable={disable}
            />
          }
        />
      </div>
    </div>
  );
};

export default BaseDatePicker;
