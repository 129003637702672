// Libs
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { publicRoute } from '~/utils/constants/route';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
// Styles, images, icons

type Props = {
  roles: AccountRoleCodesEnum[];
};

const ProtectedRoute = (props: Props) => {
  //#region Destructuring Props
  const { roles } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoggedIn] = useState<string | null>(localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE));
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const userRole = localStorage.getItem(StorageEnum.ROLE);
    if (userRole && roles.includes(userRole as AccountRoleCodesEnum)) {
      return;
    }
    localStorage.clear();
    navigate(publicRoute.auth);
  }, [location]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return isLoggedIn && currentRole && roles.includes(currentRole as AccountRoleCodesEnum) ? (
    <Outlet />
  ) : (
    <Navigate to={publicRoute.auth} />
  );
};

export default ProtectedRoute;
