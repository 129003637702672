// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
// Components, Layouts, Pages
import { IntegrationTab, RateTab, Tab } from '~/components';
// Others
import { QUERY_KEY_TAB, settingsSuperAdminTabs, settingsTabs } from '~/utils/constants/common';
import { AccountRoleCodesEnum, StorageEnum, TabQuerySettingsEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Settings.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Settings = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector

  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQuerySettingsEnum.INTEGRATION;
    switch (tabToRender) {
      case TabQuerySettingsEnum.INTEGRATION:
        return <IntegrationTab />;

      // case TabQuerySettingsEnum.RATE:
      //   return <RateTab />;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, t]);
  //#endregion Handle Function

  return (
    <div id='settingsPage' className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>{t('settings_title')}</div>
      </div>

      <div className={cx('body')}>
        <div className={cx('tabs')}>
          {/* <Tab items={role === AccountRoleCodesEnum.SUPER_ADMIN ? settingsSuperAdminTabs : settingsTabs} type='col' /> */}
          <Tab items={settingsTabs} type='col' />
        </div>

        <div className={cx('tabContent')}>{renderTabContent}</div>
      </div>
    </div>
  );
};

export default Settings;
