// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseInput, Modal, BaseTimePicker, BaseSelect, Loading } from '~/components';
// Context
// Others
import { IBaseOption, IListDataResponse, IListQueryParams, IPosition } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  FormTimeClockKeyEnum,
  StatusEnum,
  StatusTimeClockTypeEnum,
} from '~/utils/enum';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  changeStatusTimeClock,
  createTimeClock,
  getDetailTimeClock,
  updateTimeClock,
} from '~/thunks/timeClock/timeClockThunk';
import {
  IChangeStatusTimeClockBody,
  IDataEditTimeClock,
  IDetailTimeClock,
  IFormTimeClock,
} from '~/utils/interface/timeClock';
import { timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { convertToISO, formatMinutesToHourMinute, getUserName } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  GEOLOCATION_MAXIMUM_AGE,
  GEOLOCATION_TIMEOUT,
} from '~/utils/constants/common';
import { getSelectTask } from '~/thunks/task/taskThunk';
import { ISelectTask } from '~/utils/interface/task';
import { selectUserProfile } from '~/thunks/user/userSlice';
import { googleMapApiKey } from '~/utils/constants/env';

// Styles, images, icons
import styles from './FormTimeClockModal.module.scss';

type Props = {
  timeClockId?: string;
  isOpen: boolean;
  role?: AccountRoleCodesEnum;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    taskId: yup.string().required(t('common_validate_require_task')),
  });
};

const defaultForm: IFormTimeClock = {
  taskId: EMPTY_STRING,
  location: 'California',
  checkIn: EMPTY_STRING,
  checkOut: EMPTY_STRING,
  duration: EMPTY_STRING,
  totalBreak: EMPTY_STRING,
  taskName: EMPTY_STRING,
  projectName: EMPTY_STRING,
};

const FormTimeClockModal = (props: Props) => {
  //#region Destructuring Props
  const { timeClockId, isOpen, role, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const userProfile = useAppSelector(selectUserProfile);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormTimeClock>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultForm,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [originalFormData, setOriginalFormData] = useState<IFormTimeClock>(defaultForm);
  const [optionsTask, setOptionsTask] = useState<IBaseOption[]>([]);
  const [listTask, setListTask] = useState<ISelectTask[]>([]);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isLoaded) return;
    fetchCurrentLocation();
  }, [isLoaded]);

  useEffect(() => {
    handleGetListTask();
    timeClockId && handleGetDetailTimeClock(timeClockId);
  }, []);

  useEffect(() => {
    const data = getValues();
    if (!data.taskId) return;

    handleChangeTask(data.taskId);
  }, [originalFormData]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailTimeClock = (timeClockId: string) => {
    setIsLoading(true);
    if (!timeClockId) return;
    dispatch(getDetailTimeClock(Number(timeClockId)))
      .unwrap()
      .then((resp) => {
        const data: IDetailTimeClock = resp?.data;

        if (!data) return;

        const { id, task, ...remainingObject } = data;

        const updatedFormData: IFormTimeClock = {
          ...remainingObject,
          lat: Number(data?.lat) ?? DEFAULT_NUMBER_ZERO,
          lng: Number(data?.lng) ?? DEFAULT_NUMBER_ZERO,
          taskId: data.task?.id || EMPTY_STRING,
          taskName: data.task?.name ?? EMPTY_STRING,
          checkIn: data?.startTime || EMPTY_STRING,
          totalBreak: data?.totalBreak || EMPTY_STRING,
          checkOut: data?.endTime || EMPTY_STRING,
          location: data?.location || 'California',
          projectName: data.task?.name || EMPTY_STRING,
          status: data?.status || EMPTY_STRING,
        };

        reset(updatedFormData);
        setOriginalFormData(updatedFormData);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleGetListTask = () => {
    const paramsObject: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getSelectTask(paramsObject))
      .unwrap()
      .then((response) => {
        const { responses }: IListDataResponse<ISelectTask[]> = response?.data;
        if (!responses) return;

        setOptionsTask(convertListTaskOption(responses));
        setListTask(responses);
      })
      .catch((error) => {});
  };

  const handleCreateTimeClock = (data: IFormTimeClock) => {
    setIsLoading(true);

    const newData: IFormTimeClock = {
      taskId: data.taskId,
      location: data.location || EMPTY_STRING,
      lat: latitude || DEFAULT_NUMBER_ZERO,
      lng: longitude || DEFAULT_NUMBER_ZERO,
    };
    dispatch(createTimeClock(newData))
      .unwrap()
      .then((res) => {
        dispatch(timeClockActions.setRefreshList(true));
        onClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditTimeClock = (data: IFormTimeClock) => {
    if (!timeClockId) return;

    setIsLoading(true);
    const bodyData = {
      startTime: data.checkIn && convertToISO(data.checkIn),
      endTime: data.checkOut && convertToISO(data.checkOut),
    };

    const payload: IDataEditTimeClock = {
      timeClockId: +timeClockId,
      body: bodyData,
    };

    dispatch(updateTimeClock(payload))
      .unwrap()
      .then((res) => {
        onClose && onClose();
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeStatusTimeClock = (status: StatusTimeClockTypeEnum) => {
    if (!timeClockId || !status) return;
    setIsLoading(true);

    const data: IChangeStatusTimeClockBody = {
      timeClockId: timeClockId,
      body: {
        type: status,
      },
    };

    dispatch(changeStatusTimeClock(data))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        onClose && onClose();
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleOnSubmit = (data: IFormTimeClock) => {
    if (timeClockId) {
      return handleEditTimeClock(data);
    }
    handleCreateTimeClock(data);
  };

  const handleCheckIn = () => {
    switch (originalFormData.status) {
      case StatusEnum.TODO:
        return handleChangeStatusTimeClock(StatusTimeClockTypeEnum.CHECK_IN);
      case StatusEnum.BREAK:
        return handleChangeStatusTimeClock(StatusTimeClockTypeEnum.RESUME);
    }
  };

  const getAddress = (value: string) => {
    if (!value) return;

    reset((data) => ({ ...data, location: value }));
  };

  const getPosition = (value: IPosition) => {
    if (!value) return;

    reset((data) => ({ ...data, lat: value.lat, lng: value.lng }));
  };

  const convertListTaskOption = (listTaskResp: ISelectTask[]): IBaseOption[] => {
    if (!Array.isArray(listTaskResp)) {
      return [];
    }

    return listTaskResp.map((task) => ({
      label: task.name || EMPTY_STRING,
      value: task.id,
    }));
  };

  const handleChangeTask = (id: string | number) => {
    const data = listTask.find((item) => item.id.toString() === id.toString());

    setValue(FormTimeClockKeyEnum.TASK_NAME, data?.name ?? EMPTY_STRING, { shouldDirty: true });
    setValue(FormTimeClockKeyEnum.TASK_ID, id as string, { shouldDirty: true });
  };

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: GEOLOCATION_TIMEOUT,
          maximumAge: GEOLOCATION_MAXIMUM_AGE,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(timeClockId ? 'modal_update_time_clock_title' : 'modal_add_time_clock_title')}
    >
      <form id='addTimeClockComponent' className={cx('addTimeClockComponent')} onSubmit={handleSubmit(handleOnSubmit)}>
        <div className={cx('contentModal')}>
          <div className={cx('bodyModal')}>
            {timeClockId && (
              <div className={cx('threeCol')}>
                <Controller
                  name={FormTimeClockKeyEnum.CHECK_IN}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseTimePicker
                      name={name}
                      label={t('modal_add_time_clock_check_in_label')}
                      placeholderText={t('common_select_placeholder')}
                      value={value}
                      disable={role !== AccountRoleCodesEnum.ADMIN && role !== AccountRoleCodesEnum.SUPER_ADMIN}
                      onTimeChange={(date: Date) => onChange(date.toISOString())}
                    />
                  )}
                />

                {/* <Controller
                  name={FormTimeClockKeyEnum.CHECK_OUT}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseTimePicker
                      name={FormTimeClockKeyEnum.CHECK_OUT}
                      label={t('modal_add_time_clock_check_out_label')}
                      placeholderText={t('modal_add_time_clock_check_out_label')}
                      value={value}
                      disable={role !== AccountRoleCodesEnum.ADMIN}
                      onTimeChange={(date: Date) => onChange(date.toISOString())}
                    />
                  )}
                /> */}

                <Controller
                  name={FormTimeClockKeyEnum.DURATION}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseInput
                      id={name}
                      label={t('time_clock_modal_label_duration')}
                      //placeholder={t('time_clock_modal_label_duration')}
                      onChange={onChange}
                      value={value ? formatMinutesToHourMinute(value) : EMPTY_STRING}
                      disabled
                    />
                  )}
                />
                <Controller
                  name={FormTimeClockKeyEnum.TOTAL_BREAK}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseInput
                      id={name}
                      label={t('time_clock_modal_label_total_break')}
                      //placeholder={t('time_clock_modal_label_total_break')}
                      onChange={onChange}
                      value={value ? formatMinutesToHourMinute(value) : EMPTY_STRING}
                      disabled={true}
                    />
                  )}
                />
              </div>
            )}

            <Controller
              name={FormTimeClockKeyEnum.TASK_ID}
              control={control}
              render={({ field: { name, value } }) => (
                <BaseSelect
                  name={name}
                  options={optionsTask}
                  label={t('modal_add_time_clock_select_task_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(item) => handleChangeTask(item.value)}
                  value={value}
                  required
                  disabled={timeClockId ? true : false}
                  errorMessage={errors.taskId?.message}
                  // disabled
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name={FormTimeClockKeyEnum.USER_NAME}
                control={control}
                render={({ field: { name, onChange } }) => (
                  <BaseInput
                    id={name}
                    label={t('modal_add_time_clock_user_name_label')}
                    //placeholder={t('modal_add_time_clock_user_name_label')}
                    onChange={onChange}
                    value={getUserName(userProfile.firstName, userProfile.lastName) ?? EMPTY_STRING}
                    disabled
                  />
                )}
              />

              <Controller
                name={FormTimeClockKeyEnum.TASK_NAME}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseInput
                    id={name}
                    label={t('modal_add_time_clock_task_name_label')}
                    //placeholder={t('modal_add_time_clock_task_name_label')}
                    onChange={onChange}
                    value={value || EMPTY_STRING}
                    disabled
                  />
                )}
              />
            </div>

            <Controller
              name={FormTimeClockKeyEnum.PROJECT_NAME}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <BaseInput
                  id={name}
                  label={t('modal_add_time_clock_project_name_label')}
                  //placeholder={t('modal_add_time_clock_project_name_label')}
                  onChange={onChange}
                  value={value ?? EMPTY_STRING}
                  disabled
                />
              )}
            />

            {/* <div className={cx('mapView')}>
              {isLoaded && <BaseGoogleMap onGetAddress={getAddress} onGetPosition={getPosition} />}
            </div> */}

            <div className={cx('mapView')}>
              {/* Update Later */}
              <label className={cx('label')} htmlFor='mapView'>
                {t('modal_add_time_clock_map_view_label')}
              </label>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5736.254409334056!2d-76.17743605275045!3d43.029276073883025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3f0124b6a67%3A0xf83a21dd8fc44ddc!2sThe%20Highland!5e0!3m2!1sen!2s!4v1726109271087!5m2!1sen!2s'
                width='100%'
                height='450'
                style={{ border: 0 }}
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              ></iframe>
            </div>
          </div>

          <div className={cx('footerModal')}>
            <BaseButton text={t('common_cancel_label')} width={65} onClick={handleCloseModal} />
            {(role === AccountRoleCodesEnum.CAREGIVER || role === AccountRoleCodesEnum.EMPLOYEE) &&
              originalFormData.status !== StatusEnum.DONE &&
              originalFormData.status !== StatusEnum.EXPIRED && (
                <>
                  {(!timeClockId || originalFormData.status === StatusEnum.TODO) && (
                    <BaseButton
                      text={t('modal_add_time_clock_check_in_btn')}
                      typeStyle={ButtonTypeEnum.PRIMARY}
                      width={80}
                      // onClick={handleCheckIn}
                      type='submit'
                    />
                  )}

                  {originalFormData.status === StatusEnum.IN_PROGRESS && (
                    <>
                      <BaseButton
                        text={t('modal_add_time_clock_break_btn')}
                        typeStyle={ButtonTypeEnum.PRIMARY}
                        width={80}
                        onClick={() => {
                          handleChangeStatusTimeClock(StatusTimeClockTypeEnum.BREAK);
                        }}
                        type='button'
                      />
                      <BaseButton
                        text={t('modal_add_time_clock_check_out_btn')}
                        typeStyle={ButtonTypeEnum.PRIMARY}
                        width={109}
                        onClick={() => {
                          handleChangeStatusTimeClock(StatusTimeClockTypeEnum.CHECK_OUT);
                        }}
                        type='button'
                      />
                    </>
                  )}

                  {originalFormData.status === StatusEnum.BREAK && (
                    <>
                      {/* <BaseButton
                        text={t('modal_add_time_clock_continue_btn')}
                        typeStyle={ButtonTypeEnum.PRIMARY}
                        width={80}
                        onClick={handleCheckIn}
                        type='button'
                      /> */}
                      <BaseButton
                        text={t('modal_add_time_clock_end_break_btn')}
                        typeStyle={ButtonTypeEnum.PRIMARY}
                        width={80}
                        onClick={handleCheckIn}
                        type='button'
                      />
                    </>
                  )}
                </>
              )}
          </div>
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormTimeClockModal;
