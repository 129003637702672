import { createSlice } from '@reduxjs/toolkit';
import { getListRate } from './rateThunk';
import { RootState } from '~/redux/store';
import { TypeUserEnum } from '~/utils/enum';
import { IBaseOption } from '~/utils/interface/common';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';

export interface RateState {
  isRefreshRateList: boolean;
  listClientRate: IBaseOption[];
  listCaregiverRate: IBaseOption[];
}

const initialState: RateState = {
  isRefreshRateList: false,
  listClientRate: [],
  listCaregiverRate: [],
};

const rateSlice = createSlice({
  name: 'rateState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshRateList = action.payload;
    },
  },

  extraReducers(builder) {
    // Get list Rate
    builder
      .addCase(getListRate.pending, (state) => {})
      .addCase(getListRate.fulfilled, (state, action) => {
        const { data, userType } = action.payload;
        switch (userType) {
          case TypeUserEnum.CLIENT:
            state.listClientRate = data.data.responses.map((data) => ({
              label: `${data.name} - ${DOLLAR}${data.price}` || EMPTY_STRING,
              value: data.id,
            }));
            break;

          case TypeUserEnum.CAREGIVER:
            state.listCaregiverRate = data.data.responses.map((data) => ({
              label: `${data.name} - ${DOLLAR}${data.price}` || EMPTY_STRING,
              value: data.id,
            }));
            break;
        }
      })
      .addCase(getListRate.rejected, (state) => {});
  },
});

// Actions
export const rateActions = rateSlice.actions;

// Selectors
export const selectIsRefreshRateList = (state: RootState) => state.RateState.isRefreshRateList;

// Reducer
const rateReducer = rateSlice.reducer;

export default rateReducer;
