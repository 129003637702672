import { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '~/thunks/auth/authSlice';
import {
  CODE_CLOSE_WSS_LOGOUT,
  DEFAULT_TIMEOUT_RECONNECT_WSS,
  REASON_CLOSE_WSS_LOGOUT,
} from '~/utils/constants/common';
import { wssURL } from '~/utils/constants/env';
import { StorageEnum, WebSocketEvent } from '~/utils/enum';
import { deliveredMessage, login } from '~/utils/helpers/wss';
import { IDataWSSSendMessage, IMessageWSS } from '~/utils/interface/wss';

type ContextValueWS = {
  wss?: WebSocket | null;
  message?: IMessageWSS<IDataWSSSendMessage>;
  socketId?: string;
};

const WebSocketContext = createContext<ContextValueWS>({});

type WebSocketContextProp = {
  children: ReactNode;
};

const WebSocketProvider = ({ children }: WebSocketContextProp) => {
  const labelLog = '[WebSocketProvider]';

  const accessToken = useSelector(selectAccessToken);

  // const [wss, setWebSocket] = useState<WebSocket>();
  const wssRef = useRef<WebSocket | null>();
  const [message, setWSData] = useState<IMessageWSS<IDataWSSSendMessage>>();
  const [socketId, setSocketId] = useState<string>();
  const timeoutReconnect = useRef<NodeJS.Timeout>();
  const contextValueWS: ContextValueWS = { wss: wssRef.current, message, socketId };

  useEffect(() => {
    if (wssRef.current) {
      return;
    }
    const accessTokenPersist = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
    if (accessTokenPersist || accessToken) {
      connectWebSocket();
    }
  }, [accessToken]);

  useEffect(() => {
    return () => {
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current);
    };
  }, []);

  const connectWebSocket = () => {
    const labelLogLocal = `${labelLog} [connectWebSocket]`;
    if (!wssURL) {
      return;
    }

    const ws = new WebSocket(wssURL);
    wssRef.current = ws;
    wssRef.current.onopen = () => {
      console.log(`${labelLogLocal} [onopen] webSocket connected`);
      wssRef.current && login(wssRef.current);
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current); // Clear timeout reconnect
    };

    wssRef.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === WebSocketEvent.CONNECT_SUCCESS && data.socketId) {
        setSocketId(data.socketId as string);
      }
      if (data.type === WebSocketEvent.SEND_MESSAGE) {
        if (wssRef.current) {
          deliveredMessage(wssRef.current, data.data?.conversationId, [Number(data?.data?.id)]);
        }
      }
      setWSData(data);
    };

    wssRef.current.onclose = (e) => {
      if (e.code === CODE_CLOSE_WSS_LOGOUT && e.reason === REASON_CLOSE_WSS_LOGOUT) {
        wssRef.current = null;
        return;
      }
      timeoutReconnect.current = setTimeout(() => {
        console.log(`${labelLogLocal} [onclose] webSocket reconnect`);
        connectWebSocket();
      }, DEFAULT_TIMEOUT_RECONNECT_WSS);
    };

    wssRef.current.onerror = (err) => {
      ws.close();
    };
  };

  return <WebSocketContext.Provider value={contextValueWS}>{children}</WebSocketContext.Provider>;
};

export { WebSocketContext, WebSocketProvider };
