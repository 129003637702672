// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Field, Label, Description } from '@headlessui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
// Components, Layouts, Pages
// Others
import { ITag } from '~/utils/interface/common';
import { ASTERISK_SYMBOL, DEFAULT_MAX_TAG, EMPTY_STRING } from '~/utils/constants/common';
import { HTMLEventEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './SelectMultipleTag.module.scss';
import './SelectMultipleTag.scss';
import { icons } from '~/assets';

type Props = {
  width?: number | string;
  height?: number | string;
  label?: string;
  errorMessage?: string;
  options: ITag[];
  name?: string;
  value?: string[];
  required?: boolean;
  maxLength?: number;
  isCheckEnter?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string[], name?: string) => void;
};

const cx = classNames.bind(styles);

const SelectMultipleTag = (props: Props) => {
  //#region Destructuring Props
  const {
    width = '100%',
    label,
    errorMessage,
    options = [],
    name,
    value,
    required,
    placeholder,
    maxLength = DEFAULT_MAX_TAG,
    onChange,
    isCheckEnter = false,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listOption, setListOption] = useState<ITag[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [valueSearch, setValueSearch] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!options) return;

    setListOption(options);
  }, [options]);

  useEffect(() => {
    if (!value || !options) return;
    const formattedValue = value.map((item) => item.toString());

    const matchedOptions: ITag[] = options.filter((option) => formattedValue.includes(option.id));

    const unmatchedValues = formattedValue.filter((val) => !options.some((option) => option.id === val));

    const newOptions: ITag[] = unmatchedValues.map((val) => ({
      id: val,
      value: val,
    }));

    const finalOptions = [...matchedOptions, ...newOptions];

    setSelectedTags(finalOptions);
    setListOption(options.filter((option) => !finalOptions.some((selected) => selected.id === option.id)));
  }, [value, options]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleDisplayOption = () => {
    setIsOpen(!isOpen);
  };

  const handleAddItem = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === HTMLEventEnum.SPACE) {
      event.stopPropagation();
    }

    if (event.key === HTMLEventEnum.ENTER) {
      event.preventDefault();

      if (selectedTags.length < maxLength && !isCheckEnter) {
        handleAddOption();
      }
    }
  };

  const handleSelectedTags = (values: ITag[], event: React.SyntheticEvent, details: string) => {
    setSelectedTags(values);

    onChange?.(
      values.map((item) => item.id),
      name || EMPTY_STRING
    );

    setListOption((prevListOptions) => {
      const selectedIds = new Set(values.map((item) => item.id));

      return prevListOptions.filter((option) => !selectedIds.has(option.id));
    });
  };

  const handleAddOption = () => {
    if (!valueSearch?.trim()) return;

    setSelectedTags((prevSelectedItems) => {
      const isValueInListOption = listOption.find((item) => item.value === valueSearch);
      const isValueInSelectedTags = prevSelectedItems.some((item) => item.value === valueSearch);

      if (isValueInSelectedTags) return prevSelectedItems;

      const newTag: ITag = isValueInListOption || { id: valueSearch, value: valueSearch };
      const updatedItems = [...prevSelectedItems, newTag];

      onChange?.(
        updatedItems.map((item) => item.id),
        name || EMPTY_STRING
      );

      return updatedItems;
    });
  };

  const handleSearchOption = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValueSearch(value);
  };

  const handleTagClick = (option: ITag) => {
    setSelectedTags((prevSelectedItems) => {
      const isItemSelected = prevSelectedItems.some((i) => i.id === option.id);
      let updatedSelectedItems;

      if (isItemSelected) {
        updatedSelectedItems = prevSelectedItems.filter((i) => i.id !== option.id);
      } else {
        if (prevSelectedItems.length >= maxLength) {
          return prevSelectedItems;
        }
        updatedSelectedItems = [...prevSelectedItems, option];
      }

      onChange?.(
        updatedSelectedItems.map((option) => option.id),
        name || EMPTY_STRING
      );

      return updatedSelectedItems;
    });

    if (listOption && options.some((existingOption) => existingOption.id === option.id)) {
      setListOption((prevListOption) => [...prevListOption, option]);
    }
  };

  const handleRenderTags = (tagValue: ITag[]) => {
    return tagValue.map((option) => (
      <div onClick={() => handleTagClick(option)} key={option.id} className={cx('optionSelected')}>
        <span className={cx('optionSelectedLabel')}>{option.value}</span>
        <img src={icons.commonIconModalClose} alt={t('common_img_alt')} className={cx('optionSelectedIconClose')} />
      </div>
    ));
  };
  //#endregion Handle Function

  return (
    <Field id='selectMultipleTagComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Autocomplete
        multiple
        open={isOpen}
        onOpen={handleDisplayOption}
        onClose={handleDisplayOption}
        disableClearable
        value={selectedTags || []}
        onChange={(event, newValue, details) => handleSelectedTags(newValue, event, details)}
        options={listOption}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        popupIcon={null}
        disableCloseOnSelect
        className={cx('inputSearchTag')}
        classes={{
          noOptions: cx('noOption'),
          option: cx(selectedTags.length < maxLength ? 'option' : 'optionDisable'),
          listbox: cx('listbox'),
        }}
        onKeyDown={(event) => handleAddItem(event)}
        noOptionsText={t('common_empty_data_select')}
        renderTags={(tagValue) => handleRenderTags(tagValue)}
        renderInput={(params) => (
          <div className={cx('inputSearch')}>
            <TextField
              {...params}
              variant='outlined'
              focused={false}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
              onChange={handleSearchOption}
              value={valueSearch}
            />
            <div className={cx('searchIconBox')} onClick={handleDisplayOption}>
              <img src={icons.commonIconSearch} alt={t('common_img_alt')} className={cx('searchIcon')} />
            </div>
          </div>
        )}
      />

      {errorMessage && <Description className={cx('errorMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default SelectMultipleTag;
