// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getAllCaregiversByLocation, getAllClientsByLocation, getLocationsCaregiverInReport } from './reportThunk';

export interface ReportState {
  isRefreshLocations: boolean;
}

const initialState: ReportState = {
  isRefreshLocations: false,
};

const reportSlice = createSlice({
  name: 'reportState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshLocations = action.payload;
    },
  },
  extraReducers(builder) {
    // Get Locations Caregiver In Report
    builder
      .addCase(getLocationsCaregiverInReport.pending, (state) => {})
      .addCase(getLocationsCaregiverInReport.fulfilled, (state) => {})
      .addCase(getLocationsCaregiverInReport.rejected, (state) => {});

    // Get All Caregivers By Location
    builder
      .addCase(getAllCaregiversByLocation.pending, (state) => {})
      .addCase(getAllCaregiversByLocation.fulfilled, (state) => {})
      .addCase(getAllCaregiversByLocation.rejected, (state) => {});

    // Get All Clients By Location
    builder
      .addCase(getAllClientsByLocation.pending, (state) => {})
      .addCase(getAllClientsByLocation.fulfilled, (state) => {})
      .addCase(getAllClientsByLocation.rejected, (state) => {});
  },
});

// Actions
export const reportActions = reportSlice.actions;

// Selectors
export const selectIsRefreshLocations = (state: RootState) => state.reportState.isRefreshLocations;

// Reducer
const reportReducer = reportSlice.reducer;
export default reportReducer;
