// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';

// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormLocationModal,
  InputSearch,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { AccountRoleCodesEnum, ButtonTypeEnum, StorageEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IListDataResponse, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import { DEFAULT_CURRENT_PAGE, DEFAULT_DELAY_TIME, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { ILocation } from '~/utils/interface/crm/location';
import useDebounce from '~/utils/customHook';
import { RootState } from '~/redux/store';
import { adminRoute, adminRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';

// Styles, images, icons
import styles from './CrmLocation.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columnsLocation = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleView: (record: ILocation) => void
): ColumnTableType<ILocation>[] => {
  return [
    {
      key: 'name',
      title: t('location_table_location_name_label'),
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '25%',
    },
    {
      key: 'address',
      title: t('location_table_location_address_label'),
      render(value, record, index) {
        return <>{record.address ? record.address : EMPTY_STRING}</>;
      },
      width: '70%',
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isView: true,
              isDelete: false,
              isEdit: false,
            }}
            onView={() => handleView(record)}
          />
        );
      },
      width: 50,
    },
  ];
};
const CrmLocation = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const dispatch = useAppDispatch();
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListLocation } = useAppSelector((state: RootState) => state.locationState);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [dataLocation, setDataLocation] = useState<ILocation[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [isOpenAddLocationModal, setIsOpenAddLocationModal] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParams = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListLocations(newParamObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshListLocation) return;

    handleGetListLocations(paramObject);
  }, [isRefreshListLocation]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleAddLocationModal = () => {
    setLocationId(EMPTY_STRING);
    setIsOpenAddLocationModal(!isOpenAddLocationModal);
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleViewLocation = (data: ILocation) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crm}${adminRoute.crmLocation}/${data.id}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crm}${adminRoute.crmLocation}/${data.id}`);
        break;
    }
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListLocations = (params: IListQueryParams) => {
    loading?.show();
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<ILocation[]> = res?.data;
        setDataLocation(responses);
        setPagination(pagination);
      })
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='crmLocation' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_location_title')}</span>
          </div>
        }
      >
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={textSearchParams}
        />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('location_add_location_button')}
          onClick={handleAddLocationModal}
          height={36}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columnsLocation(t, handleViewLocation)}
            dataSource={dataLocation}
            onClickRow={handleViewLocation}
          />
        </div>
        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isOpenAddLocationModal && <FormLocationModal isOpen={isOpenAddLocationModal} onClose={handleAddLocationModal} />}

      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default CrmLocation;
