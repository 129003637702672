import { urlApiRates } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IListQueryParamsRate, IRate } from '~/utils/interface/rate';

const rateApi = {
  getListRate(params: IListQueryParamsRate) {
    const url = `${urlApiRates.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IRate[]>>>(url, { params });
  },
};
export default rateApi;
