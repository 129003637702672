// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import conversationApi from '~/apis/conversation';
import { CREATE_MESSAGE, GET_DETAIL_CONVERSATION, GET_LIST_CONVERSATION } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import { CREATE_CONVERSATION } from '~/utils/constants/actionType';
import { ICreateConversationReq, IPayloadGetDetailConversation } from '~/utils/interface/conversation';
import { IPayloadCreateMessage } from '~/utils/interface/message';

export const getListConversation = createAsyncThunk(
  GET_LIST_CONVERSATION,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await conversationApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createConversation = createAsyncThunk(
  CREATE_CONVERSATION,
  async (payload: ICreateConversationReq, { rejectWithValue }) => {
    try {
      const res = await conversationApi.createConversation(payload);
      return res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getDetailConversation = createAsyncThunk(
  GET_DETAIL_CONVERSATION,
  async (payload: IPayloadGetDetailConversation, { rejectWithValue }) => {
    try {
      const res = await conversationApi.getDetailConversation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMessage = createAsyncThunk(
  CREATE_MESSAGE,
  async (payload: IPayloadCreateMessage, { rejectWithValue }) => {
    try {
      const res = await conversationApi.createMessage(payload);
      return res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
