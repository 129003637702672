// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import caregiverApi from '~/apis/caregiver';
import {
  GET_LIST_CAREGIVER,
  CREATE_CAREGIVER,
  GET_DETAIL_CAREGIVER,
  DELETE_CAREGIVER,
  GET_PROFILE_TAGS,
  EDIT_CAREGIVER,
  GET_CAREGIVERS_BY_LOCATION,
} from '~/utils/constants/actionType';
import { IEditCaregiver, IProfileTag, IQueryGetCaregiversByLocation } from '~/utils/interface/caregiver';
import { IListQueryParams } from '~/utils/interface/common';

export const getListCaregiver = createAsyncThunk(
  GET_LIST_CAREGIVER,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await caregiverApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCaregiver = createAsyncThunk(CREATE_CAREGIVER, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await caregiverApi.createCaregiver(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editCaregiver = createAsyncThunk(EDIT_CAREGIVER, async (payload: IEditCaregiver, { rejectWithValue }) => {
  try {
    const res = await caregiverApi.editCaregiver(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailCaregiver = createAsyncThunk(
  GET_DETAIL_CAREGIVER,
  async (accountId: string, { rejectWithValue }) => {
    try {
      const res = await caregiverApi.getDetailCaregiver(accountId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCaregiver = createAsyncThunk(DELETE_CAREGIVER, async (accountId: string, { rejectWithValue }) => {
  try {
    const res = await caregiverApi.deleteCaregiver(accountId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProfileTags = createAsyncThunk(GET_PROFILE_TAGS, async (payload: IProfileTag, { rejectWithValue }) => {
  try {
    const res = await caregiverApi.getProfileTags(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCaregiversByLocation = createAsyncThunk(
  GET_CAREGIVERS_BY_LOCATION,
  async (payload: IQueryGetCaregiversByLocation, { rejectWithValue }) => {
    try {
      const res = await caregiverApi.getCaregiversByLocation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
