// Others
import axiosClient from '../axiosClient';
import { urlApiSchedule } from '~/utils/constants/api';
import {
  IFormCreateSchedule,
  IListShiftScheduleResp,
  IQueryParamGetListSchedule,
} from '~/utils/interface/crm/shiftSchedule';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IDetailShift,
  IFormSchedule,
  IFormUpdateSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
} from '~/utils/interface/schedule';

const shiftScheduleApi = {
  createSchedule(body: IFormCreateSchedule) {
    const url = `${urlApiSchedule.createSchedule}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListShiftSchedule(params: IQueryParamGetListSchedule) {
    const url = `${urlApiSchedule.getListShiftSchedule}`;
    return axiosClient.get<BaseResponse<IListShiftScheduleResp[]>>(url, { params });
  },

  getListSchedule(query: IQueryParamGetListSchedule) {
    const url = `${urlApiSchedule.getListSchedule}`;
    return axiosClient.get<
      BaseResponse<IScheduleEvent[] | IListDataResponse<IScheduleEvent[] | IScheduleTimelineData[]>>
    >(url, { params: query });
  },

  createShiftSchedule(body: IFormSchedule) {
    const url = `${urlApiSchedule.createShiftSchedule}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateShiftSchedule(body: IFormUpdateSchedule) {
    const { id, ...restBody } = body;
    const url = `${urlApiSchedule.updateShiftSchedule(id)}`;
    return axiosClient.put<BaseResponse>(url, restBody);
  },

  getDetailShift(shiftId: string) {
    const url = `${urlApiSchedule.getDetailShiftSchedule(shiftId)}`;
    return axiosClient.get<BaseResponse<IDetailShift>>(url);
  },
};

export default shiftScheduleApi;
