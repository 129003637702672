// Libs
// Others
import { urlApiConversation } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { IConversation, IDetailConversationRes, IPayloadGetDetailConversation } from '~/utils/interface/conversation';
import { ICreateConversationReq, ICreateConversationRes } from '~/utils/interface/conversation';
import { ICreateMessageRes, IPayloadCreateMessage } from '~/utils/interface/message';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const conversationApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiConversation.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IConversation[]>>>(url, { params });
  },

  createConversation(payload: ICreateConversationReq) {
    const url = `${urlApiConversation.create}`;
    return axiosClient.post<BaseResponse<ICreateConversationRes>>(url, payload);
  },

  getDetailConversation(payload: IPayloadGetDetailConversation) {
    const url = `${urlApiConversation.getDetail(payload.conversationId)}`;
    return axiosClient.get<BaseResponse<IDetailConversationRes>>(url, { params: payload.query });
  },

  createMessage(payload: IPayloadCreateMessage) {
    const { conversationId, formData } = payload;
    const url = `${urlApiConversation.createMessage(conversationId)}`;
    return axiosClient.post<BaseResponse<ICreateMessageRes>>(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },
};

export default conversationApi;
