// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  deleteCaregiver,
  editCaregiver,
  getCaregiversByLocation,
  getDetailCaregiver,
  getListCaregiver,
  getProfileTags,
} from '~/thunks/caregiver/caregiverThunk';
import { createCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { RootState } from '~/redux/store';

export interface CaregiverState {
  isRefreshCaregiverList: boolean;
}

const initialState: CaregiverState = {
  isRefreshCaregiverList: false,
};

const caregiverSlice = createSlice({
  name: 'caregiverState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshCaregiverList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list Caregiver
    builder
      .addCase(getListCaregiver.pending, (state) => {})
      .addCase(getListCaregiver.fulfilled, (state) => {})
      .addCase(getListCaregiver.rejected, (state) => {});

    // Create Caregiver
    builder
      .addCase(createCaregiver.pending, (state) => {})
      .addCase(createCaregiver.fulfilled, (state) => {})
      .addCase(createCaregiver.rejected, (state) => {});

    // Get Detail Caregiver
    builder
      .addCase(getDetailCaregiver.pending, (state) => {})
      .addCase(getDetailCaregiver.fulfilled, (state) => {})
      .addCase(getDetailCaregiver.rejected, (state) => {});

    // Delete Caregiver
    builder
      .addCase(deleteCaregiver.pending, (state) => {})
      .addCase(deleteCaregiver.fulfilled, (state) => {})
      .addCase(deleteCaregiver.rejected, (state) => {});

    // Get Profile Tags
    builder
      .addCase(getProfileTags.pending, (state) => {})
      .addCase(getProfileTags.fulfilled, (state) => {})
      .addCase(getProfileTags.rejected, (state) => {});

    // Edit Caregiver
    builder
      .addCase(editCaregiver.pending, (state) => {})
      .addCase(editCaregiver.fulfilled, (state) => {})
      .addCase(editCaregiver.rejected, (state) => {});

    // Get Caregivers By Location
    builder
      .addCase(getCaregiversByLocation.pending, (state) => {})
      .addCase(getCaregiversByLocation.fulfilled, (state) => {})
      .addCase(getCaregiversByLocation.rejected, (state) => {});
  },
});

// Actions
export const caregiverActions = caregiverSlice.actions;

// Selectors
export const selectIsRefreshCaregiverList = (state: RootState) => state.caregiverState.isRefreshCaregiverList;

// Reducer
const caregiverReducer = caregiverSlice.reducer;
export default caregiverReducer;
