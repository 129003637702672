// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import moment from 'moment';
// Components, Layouts, Pages
import { EventPopup, ModalUnderDevelopment, ViewSchedule } from '~/components';
// Others
import { TCalendarViewMode, TPageViewSchedule } from '~/utils/type/schedule';
import { DateFormatEnum, PageViewScheduleEnum, SchedulesLiveViewTypeEnum } from '~/utils/enum';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
import {
  IGetSchedulesLiveViewParams,
  IOfficeSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
} from '~/utils/interface/schedule';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getSchedulesLiveView } from '~/thunks/schedule/scheduleThunk';
import { convertTimelineSchedule } from '../helper';
import { RootState } from '~/redux/store';
import { LoadingData } from '~/context';
import { DEFAULT_LIMIT_MAX_ITEM } from '~/utils/constants/common';
// Styles, images, icons
import styles from './NewScheduleComponent.module.scss';

type Props = {
  view: TCalendarViewMode;
  onNavigate: () => void;
  pageView: TPageViewSchedule;
};

const cx = classNames.bind(styles);

const NewScheduleComponent = (props: Props) => {
  //#region Destructuring Props
  const { view, onNavigate, pageView } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);

  //#endregion Declare Hook

  //#region Selector
  const { isRefreshShiftSchedules } = useAppSelector((state: RootState) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>([]);
  const [dataEvent, setDataEvent] = useState<IScheduleTimelineEvent>();
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const timeValue = useMemo(() => {
    switch (view) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM DD, YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [currentTime]);

  const [isOpenModalUnderDevelop, setIsOpenModalUnderDevelop] = useState<boolean>(false);
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetScheduleLiveView();
  }, [currentTime]);

  useEffect(() => {
    if (isRefreshShiftSchedules) {
      handleGetScheduleLiveView();
    }
  }, [isRefreshShiftSchedules]);

  //#endregion Implement Hook

  //#region Handle Function
  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, view);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, view);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleChangeCalendarView = () => {
    //TODO: HuyPahmGRF-ABD: Handle logic here
    onNavigate();
  };

  const handleCloseModalUnderDevelop = () => {
    setIsOpenModalUnderDevelop(!isOpenModalUnderDevelop);
  };

  const handleShowEvent = (showEvent: boolean, event?: IScheduleEvent | IScheduleTimelineEvent | IOfficeSchedule) => {
    if (event) setDataEvent(event as IScheduleTimelineEvent);
    setIsShowEvent(showEvent);
  };

  const handleGetScheduleLiveView = () => {
    const startOfWeek = moment(currentTime).startOf('week').format(DateFormatEnum.YYYY_MM_DD);
    const endOfWeek = moment(currentTime).endOf('week').format(DateFormatEnum.YYYY_MM_DD);

    const payload: IGetSchedulesLiveViewParams = {
      startDate: view === Views.WEEK ? startOfWeek : currentTime,
      endDate: view === Views.WEEK ? endOfWeek : currentTime,
      type:
        pageView === PageViewScheduleEnum.CLIENTS
          ? SchedulesLiveViewTypeEnum.CLIENT
          : SchedulesLiveViewTypeEnum.CAREGIVER,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    loading?.show();
    dispatch(getSchedulesLiveView(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data.responses) return;

        const dataTimeLine = convertTimelineSchedule(res?.data.responses, pageView);
        setTimelineEvents(dataTimeLine);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='dailyScheduleComponent' className={cx('dailyScheduleComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('toolbarWrapper')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('dateControl')}>
              <button className={cx('dateControlBtn')} onClick={goPreviousTime}>
                <CommonIconPrev strokePath={WHITE} />
              </button>
              <button className={cx('dateControlBtn')} onClick={goNextTime}>
                <CommonIconNext strokePath={WHITE} />
              </button>
            </div>
            <button className={cx('dateControlBtnToday')} onClick={goToday}>
              {t('common_text_today')}
            </button>
          </div>

          <div className={cx('label')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {view === Views.DAY && (
              <div className={cx('modeViewCalendar')}>
                <button className={cx('btnCalendarView')} onClick={() => handleChangeCalendarView()}>
                  {t('crm_client_detail_schedule_btn_view_week')}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          <ViewSchedule
            view={view}
            pageView={pageView}
            date={currentTime}
            events={Array.isArray(timelineEvents) ? timelineEvents : []}
            onSelectEvent={(events) => handleShowEvent(true, events)}
          />
        </div>
      </div>

      {isOpenModalUnderDevelop && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelop} />}

      <EventPopup isOpen={isShowEvent} onClose={() => handleShowEvent(false)} data={dataEvent} />
    </div>
  );
};

export default NewScheduleComponent;
