// Libs
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// Components, Layouts, Pages
import { TimePickerFieldControl } from '~/components';
// Others
import { TimePickerFieldControlProps } from '~/components/common/baseTimePicker/fieldControl/TimePickerFieldControl';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import 'react-datepicker/dist/react-datepicker.css';
import './BaseTimePicker.scss';

type Props = Omit<TimePickerFieldControlProps, 'value' | 'label'> & {
  value?: Date | string;
  label?: string;
  onTimeChange?: (date: Date, name?: string) => void;
  required?: boolean;
  disable?: boolean;
  timeIntervals?: number;
};

const BaseTimePicker = (props: Props) => {
  //#region Destructuring Props
  const {
    value,
    label,
    placeholderText,
    width,
    height,
    errorMessage,
    name,
    required = false,
    disable,
    onTimeChange,
    timeIntervals = 15,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [timeText, setTimeText] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;
    const time = moment(value).toDate();
    setSelectedTime(time);
    setTimeText(moment(value).format('h:mm A'));
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleTimeChange = (selectedTime: Date | null) => {
    if (!selectedTime) return;
    setSelectedTime(selectedTime);
    setTimeText(moment(selectedTime).format('hh:mm A'));
    onTimeChange && onTimeChange(moment(selectedTime).toDate(), name ?? '');
  };
  //#endregion Handle Function

  return (
    <div id='baseTimePickerComponent' className='baseTimePickerContainer' style={{ width }}>
      {label && (
        <div className='labelField'>
          {label} {required && <span className='required'>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className='baseTimePickerContent'>
        <DatePicker
          selected={selectedTime}
          showTimeSelect
          showTimeSelectOnly
          timeFormat='HH:mm'
          timeIntervals={timeIntervals}
          showPopperArrow={false}
          onChange={handleTimeChange}
          disabled={disable}
          customInput={
            <TimePickerFieldControl
              value={timeText}
              width={width}
              height={height}
              placeholderText={placeholderText}
              errorMessage={errorMessage}
              disable={disable}
            />
          }
          popperPlacement='top-end'
          dateFormat='HH:mm'
        />
      </div>
    </div>
  );
};

export default BaseTimePicker;
