// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseButton, BaseInput, Loading, Modal, ModalUnderDevelopment, RadioAddSchedule } from '~/components';
// Context

// Others
import { ButtonTypeEnum, TypeUserEnum, TypeRateEnum, FormRateTypeEnum } from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
import { IFormRate } from '~/utils/interface/rate';

// Styles, images, icons
import styles from './FormRateModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    type: yup.string().trim().required(t('common_required_field')),
    name: yup.string().trim().required(t('common_required_field')),
    userType: yup.string().trim().required(t('common_required_field')),
  });
};

const defaultValues: IFormRate = {
  type: TypeRateEnum.HOURLY,
  userType: TypeUserEnum.CAREGIVER,
  name: EMPTY_STRING,
};

const FormRateModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormRate>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Handle Function
  const handleClose = () => {
    onClose?.();
  };

  const handleSubmitRate = (data: IFormRate) => {
    //@Todo: Handle submit
    handleShowDevelopment();
  };

  const handleShowDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t('settings_form_rate_add_title')}>
      <form id='formRateModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitRate)}>
        <div className={cx('contentModal')}>
          <Controller
            name={FormRateTypeEnum.NAME}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id={FormRateTypeEnum.NAME}
                height={34}
                label={t('settings_form_rate_name_label')}
                onChange={onChange}
                value={value}
                messageError={errors.name?.message}
                required
              />
            )}
          />

          <div>
            <div className={cx('textLabel')}>
              {t('settings_form_rate_type_label')}
              <span className={cx('inputBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>
            </div>
            <Controller
              name={FormRateTypeEnum.TYPE}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={cx('radioGroup')}>
                  <RadioAddSchedule
                    id={FormRateTypeEnum.TYPE}
                    name={FormRateTypeEnum.TYPE}
                    label={t('settings_form_rate_hourly_content')}
                    value={TypeRateEnum.HOURLY}
                    checked={value === TypeRateEnum.HOURLY}
                    onChange={onChange}
                  />
                  <RadioAddSchedule
                    id={FormRateTypeEnum.TYPE}
                    name={FormRateTypeEnum.TYPE}
                    label={t('settings_form_rate_per_visit_content')}
                    value={TypeRateEnum.PER_VISIT}
                    checked={value === TypeRateEnum.PER_VISIT}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>

          <div>
            <div className={cx('textLabel')}>
              {t('settings_form_rate_user_type_label')}
              <span className={cx('inputBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>
            </div>
            <Controller
              name={FormRateTypeEnum.USER_TYPE}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={cx('radioGroup')}>
                  <RadioAddSchedule
                    id={FormRateTypeEnum.USER_TYPE}
                    name={FormRateTypeEnum.USER_TYPE}
                    label={t('settings_form_rate_caregiver_content')}
                    value={TypeUserEnum.CAREGIVER}
                    checked={value === TypeUserEnum.CAREGIVER}
                    onChange={onChange}
                  />
                  <RadioAddSchedule
                    id={FormRateTypeEnum.USER_TYPE}
                    name={FormRateTypeEnum.USER_TYPE}
                    label={t('settings_form_rate_client_content')}
                    value={TypeUserEnum.CLIENT}
                    checked={value === TypeUserEnum.CLIENT}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} type='button' />
          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>
      </form>

      {isLoading && <Loading />}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </Modal>
  );
};

export default FormRateModal;
