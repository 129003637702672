// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
// Components, Layouts, Pages
import { BaseDatePicker, BaseGoogleMap, BaseSelect, DonutChartReport } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getCaregiversByLocation } from '~/thunks/caregiver/caregiverThunk';
import { getAllCaregiversByLocation, getLocationsCaregiverInReport } from '~/thunks/report/reportThunk';
import { IQueryGetCaregiversByLocation } from '~/utils/interface/caregiver';
import {
  IAllCaregiversByLocation,
  ILocationCaregiver,
  IQueryGetAllUserByLocation,
  IQueryGetLocationsCaregiverInReport,
} from '~/utils/interface/report';
import { IBaseOption, IListQueryParams, IPlaceGoogleMap, IPosition } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { googleMapApiKey } from '~/utils/constants/env';
import { BLUE, ORANGE } from '~/utils/constants/color';
import { ColorEnum, DateFormatEnum } from '~/utils/enum';
import { getUserName } from '~/utils/helper';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
// Styles, images, icons
import styles from './ReportingCaregiver.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ReportingCaregiver = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [caregivers, setCaregivers] = useState<IBaseOption[]>([]);
  const [dataChart, setDataChart] = useState<IAllCaregiversByLocation>();
  const [listLocationCheckIn, setListLocationCheckIn] = useState<ILocationCaregiver[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [locationCaregiverSelected, setLocationCaregiverSelected] = useState<string>();
  const [caregiverSelected, setCaregiverSelected] = useState<string>();
  const [locationAllCaregiversSelected, setLocationAllCaregiversSelected] = useState<string>();
  const [location, setLocations] = useState<IBaseOption[]>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const currentDate = moment().format(DateFormatEnum.YYYY_MM_DD);
    setSelectedDate(currentDate);

    handleGetListLocations();
  }, []);

  useEffect(() => {
    if (!locationCaregiverSelected) return;

    handleGetCaregiversByLocation(locationCaregiverSelected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCaregiverSelected]);

  useEffect(() => {
    if (!locationAllCaregiversSelected) return;

    handleGetAllCaregiversByLocation(locationAllCaregiversSelected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAllCaregiversSelected]);

  useEffect(() => {
    if (!Array.isArray(caregivers) || caregivers?.length === DEFAULT_NUMBER_ZERO) return;

    setCaregiverSelected(String(caregivers[DEFAULT_NUMBER_ZERO]?.value));
  }, [caregivers]);

  useEffect(() => {
    if (caregiverSelected && selectedDate) {
      const payload: IQueryGetLocationsCaregiverInReport = {
        caregiverId: caregiverSelected,
        date: selectedDate,
      };

      handleGetLocationsCaregiver(payload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverSelected, selectedDate]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListLocations = () => {
    loadingData?.show();

    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => {
          return { label: data.name || EMPTY_STRING, value: data.id };
        });

        if (listLocation?.length > DEFAULT_NUMBER_ZERO) {
          setLocations(listLocation);
          setLocationCaregiverSelected(listLocation[DEFAULT_NUMBER_ZERO].value);
          setLocationAllCaregiversSelected(listLocation[DEFAULT_NUMBER_ZERO].value);
        }
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleGetCaregiversByLocation = (location: string) => {
    const payload: IQueryGetCaregiversByLocation = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
      locationId: location,
    };

    dispatch(getCaregiversByLocation(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const { responses } = res?.data;

        const caregiversFormatted: IBaseOption[] = responses?.map((option) => ({
          label: getUserName(option?.firstName, option?.middleName, option?.lastName),
          value: String(option?.id),
        }));

        if (caregiversFormatted) {
          setCaregivers(caregiversFormatted);
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetAllCaregiversByLocation = (locationId: string) => {
    if (!locationId) return;

    const payload: IQueryGetAllUserByLocation = {
      locationId: locationId,
    };

    loadingData?.show();

    dispatch(getAllCaregiversByLocation(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        setDataChart(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleGetLocationsCaregiver = (payload: IQueryGetLocationsCaregiverInReport) => {
    loadingData?.show();

    dispatch(getLocationsCaregiverInReport(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        setListLocationCheckIn(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleLocationCaregiverChange = (optionSelected: IBaseOption) => {
    const { value } = optionSelected;

    if (!value) return;

    setLocationCaregiverSelected(String(value));
  };

  const handleCaregiverChange = (optionSelected: IBaseOption) => {
    const { value } = optionSelected;

    if (!value) return;

    setCaregiverSelected(String(value));
  };

  const handleSelectedDate = (date: string) => {
    if (!date) return;

    setSelectedDate(date);
  };

  const handleLocationAllCaregiversChange = (location: IBaseOption) => {
    const { value } = location;

    if (!value) return;

    setLocationAllCaregiversSelected(String(value));
  };

  const dataChartConverted = () => {
    if (
      !dataChart ||
      (dataChart?.totalCaregiverHired === DEFAULT_NUMBER_ZERO &&
        dataChart?.totalCaregiverTerminated === DEFAULT_NUMBER_ZERO)
    )
      return [];

    const dataArr: number[] = Object.values(dataChart);

    return dataArr;
  };

  const chartPlacesConverted = () => {
    if (!Array.isArray(listLocationCheckIn) || listLocationCheckIn?.length === DEFAULT_NUMBER_ZERO) return null;

    const places: IPlaceGoogleMap[] = listLocationCheckIn?.map((option) => ({
      id: Number(option?.id),
      startTime: option?.startTime,
      location: option?.location,
      position: { lat: option?.lat, lng: option?.lng },
    }));

    return places;
  };

  const getPositionCaregiver = (): IPosition | null => {
    if (!Array.isArray(listLocationCheckIn) || listLocationCheckIn?.length === DEFAULT_NUMBER_ZERO) return null;

    return { ...listLocationCheckIn[listLocationCheckIn?.length - DEFAULT_NUMBER_ONE] };
  };
  //#endregion Handle Function

  return (
    <div id='reportingCaregiverPage' className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>{t('reporting_caregiver_title')}</div>
      </div>

      <div className={cx('body')}>
        <div className={cx('filtersAndMap')}>
          <div className={cx('filtersAndMapContainer')}>
            <div className={cx('threeCol')}>
              <BaseSelect
                value={locationCaregiverSelected}
                options={location || []}
                label={t('reporting_select_location_title')}
                placeholder={t('common_select_placeholder')}
                onChange={handleLocationCaregiverChange}
              />

              <BaseSelect
                value={caregiverSelected}
                options={caregivers || []}
                label={t('reporting_select_caregiver_title')}
                placeholder={t('common_select_placeholder')}
                onChange={handleCaregiverChange}
              />

              <BaseDatePicker
                value={selectedDate}
                label={t('reporting_select_date_title')}
                onDateSelected={handleSelectedDate}
                placeholderText={t('common_select_placeholder')}
              />
            </div>

            <div className={cx('mapContainer')}>
              {isLoaded && (
                <BaseGoogleMap
                  title={t('modal_add_time_clock_map_view_label')}
                  position={getPositionCaregiver()}
                  places={chartPlacesConverted()}
                  triggerLocate
                />
              )}
            </div>
          </div>
        </div>

        <div className={cx('chartWrapper')}>
          <div className={cx('chartContainer')}>
            <DonutChartReport
              title={t('reporting_caregiver_table_title_chart')}
              data={dataChartConverted()}
              colors={[
                {
                  name: ColorEnum.BLUE,
                  value: BLUE,
                },
                {
                  name: ColorEnum.ORANGE,
                  value: ORANGE,
                },
              ]}
              options={location}
              labels={[t('reporting_tab_label_hired'), t('reporting_tab_label_terminated')]}
              height={300}
              value={locationAllCaregiversSelected}
              onLocationChange={handleLocationAllCaregiversChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingCaregiver;
