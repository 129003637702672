import { ApexOptions } from 'apexcharts';
import { EDWARD300 } from './color';
import { DEFAULT_NUMBER_ONE, DOLLAR } from './common';

export const configDonutChart = (payload: { labels: string[]; colors: string[] }): ApexOptions => {
  const { colors, labels } = payload;
  return {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
      zoom: {
        enabled: false,
      },
    },
    labels: labels,
    stroke: {
      show: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          background: 'transparent',
          size: '75%',
          labels: {
            show: true,
            value: {
              fontSize: '48px',
              fontWeight: 200,
              show: true,
              offsetY: 16,
            },
            name: {
              show: false,
            },
            total: {
              show: true,
              fontSize: '48px',
              fontWeight: 200,
            },
          },
        },
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
      },
    },
    colors: colors,
  };
};

export const configDonutChartReport = (payload: { labels: string[]; colors: string[] }): ApexOptions => {
  const { colors, labels } = payload;
  return {
    chart: {
      id: 'donut',
      type: 'donut',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: '80%',
          labels: {
            show: false,
            value: {
              fontSize: '48px',
              fontWeight: 200,
              show: true,
              offsetY: 16,
            },
            name: {
              show: false,
            },
            total: {
              show: true,
              fontSize: '48px',
              fontWeight: 200,
            },
          },
        },
        expandOnClick: false,
        offsetX: 0,
        offsetY: 0,
      },
    },
    labels: labels,
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: colors,
  };
};

export const configColumChart = (payload: {
  categories: string[];
  colors: string[];
  colWidth?: number;
  stepSize?: number;
}): ApexOptions => {
  const { categories, colors, colWidth = 48, stepSize = 400 } = payload;
  return {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      stacked: true,
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: colWidth,
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: true,
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    stroke: {
      show: false,
    },
    yaxis: {
      stepSize: stepSize,
      show: true,
      labels: {
        offsetX: -17,
        style: {
          fontSize: '14px',
          colors: EDWARD300,
          fontWeight: 400,
        },
        formatter(val, opts) {
          return DOLLAR + val;
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: categories,
      labels: {
        style: {
          fontSize: '14px',
          colors: EDWARD300,
          fontWeight: 400,
        },
      },
    },
    colors: colors,
  } as ApexOptions;
};

export const configLineChart = (payload: { categories: string[]; colors?: string[] }): ApexOptions => {
  const { categories, colors } = payload;

  return {
    chart: {
      type: 'line',
      width: '100%',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: colors,
      type: 'gradient',
      fillTo: 100,
    },
    markers: {
      strokeWidth: 2,
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 0,
          fillColor: colors && colors[0],
          strokeColor: '#fff',
          size: categories.length > DEFAULT_NUMBER_ONE ? 5 : 0,
        },
        {
          seriesIndex: 0,
          dataPointIndex: categories.length - DEFAULT_NUMBER_ONE,
          fillColor: colors && colors[0],
          strokeColor: '#fff',
          size: categories.length > DEFAULT_NUMBER_ONE ? 5 : 0,
        },
      ],
    },
    stroke: {
      width: 2,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      stepSize: 100,
    },
    dataLabels: {
      enabled: false,
    },
    sparkline: {
      enabled: false,
    },
    colors: colors,
    legend: {
      show: false,
    },
  } as ApexOptions;
};
