// Libs
import { Description, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
// Others
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import { IBaseOptionMultiple } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_PADDING_OPTION_SELECT,
  PADDING_OPTION_SELECT_MULTIPLE,
  PLUS,
} from '~/utils/constants/common';
import { generateGuid } from '~/utils/helpers/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseSelectMultiple.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  options: IBaseOptionMultiple[];
  name?: string;
  value?: string[];
  required?: boolean;
  isCheckbox?: boolean;
  onChange?: (value: string[], name?: string) => void;
};

const cx = classNames.bind(styles);

const BaseSelectMultiple = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    height = DEFAULT_SELECT_HEIGHT,
    label,
    placeholder,
    errorMessage,
    options,
    name,
    value,
    required,
    isCheckbox = false,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const genId = generateGuid();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IBaseOptionMultiple[]>([]);
  const [displayOptions, setDisplayOptions] = useState<IBaseOptionMultiple[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [remainingCount, setRemainingCount] = useState<number>(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value || value.length === DEFAULT_NUMBER_ZERO) return;

    const updatedSelectOption = value
      .map((value) => options.find((item) => item.value === value))
      .filter(Boolean) as IBaseOptionMultiple[];

    setSelectOption(updatedSelectOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  useEffect(() => {
    if (!buttonRef.current) return;

    const calculateDisplayOptions = () => {
      const buttonWidth = buttonRef.current?.offsetWidth || DEFAULT_NUMBER_ZERO;
      const tempCanvas = document.createElement('canvas');
      const context = tempCanvas.getContext('2d');

      if (!context) return;

      if (buttonRef.current) {
        const fontStyle = window.getComputedStyle(buttonRef.current).font;
        context.font = fontStyle;
        let totalWidth = 0;
        const visibleOptions: IBaseOptionMultiple[] = [];

        for (const option of selectOption) {
          const textWidth = context.measureText(option.label).width + DEFAULT_PADDING_OPTION_SELECT;
          totalWidth += textWidth;

          if (totalWidth <= buttonWidth - PADDING_OPTION_SELECT_MULTIPLE) {
            visibleOptions.push(option);
          } else {
            break;
          }
        }

        setDisplayOptions(visibleOptions);
        setRemainingCount(selectOption.length - visibleOptions.length);
      }
    };

    calculateDisplayOptions();

    const observer = new ResizeObserver(calculateDisplayOptions);
    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) observer.unobserve(buttonRef.current);
    };
  }, [selectOption]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (value: IBaseOptionMultiple[]) => {
    const valueMultipleSelect = value.map((item) => {
      return item.value;
    });

    setSelectOption(value);
    onChange && onChange(valueMultipleSelect, name ?? '');
  };

  const isSelected = (value: string) => {
    return selectOption.find((el) => el.value === value) ? true : false;
  };

  const handleCloseItem = (value: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const newItem = selectOption.filter((item) => item.value !== value);
    setSelectOption(newItem);
    setIsOpen(true);
    const valueMultipleSelect = newItem.map((item) => {
      return item.value;
    });
    onChange && onChange(valueMultipleSelect, name ?? '');
  };
  //#endregion Handle Function

  return (
    <Field id='baseSelectComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('selectBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Listbox value={selectOption} onChange={handleOptionChange} multiple>
        <ListboxButton
          ref={buttonRef}
          className={cx('btnSelect')}
          style={{ height }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {({ open }) => (
            <>
              {placeholder && selectOption.length === DEFAULT_NUMBER_ZERO ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <>
                  {displayOptions.map((option, index) => (
                    <div key={index} className={cx('btnText')}>
                      {option.label}
                      <img
                        onClick={(e) => handleCloseItem(option.value, e)}
                        className={cx('iconClose')}
                        src={icons.commonIconModalClose}
                        alt={t('common_img_text_alt')}
                      />
                    </div>
                  ))}

                  {remainingCount > DEFAULT_NUMBER_ZERO && (
                    <div
                      className={cx('btnTextMore')}
                      data-tooltip-id={`selectedMultiple-${genId}`}
                      data-tooltip-place='top'
                    >
                      <span>{`${PLUS} ${remainingCount}`}</span>
                    </div>
                  )}
                </>
              )}
              <div className={cx('iconWrap')}>
                <img
                  src={icons.commonIconArrowBottom}
                  className={cx(open ? 'iconActive' : '')}
                  alt={t('common_img_text_alt')}
                />
              </div>
            </>
          )}
        </ListboxButton>

        <ListboxOptions className={cx('optionList')} transition anchor={{ to: 'bottom', gap: '8px' }}>
          {options.length > 0 ? (
            <div className={cx('options')}>
              {options.map((option) => {
                const selected = isSelected(option.value);
                return (
                  <ListboxOption
                    key={option.value}
                    value={option}
                    className={({ focus }) => cx('optionItem', selected && 'optionActive', focus && 'optionHover')}
                  >
                    <div className={cx('leftSelect')}>
                      {isCheckbox && (
                        <input type='checkbox' checked={isSelected(option.value)} className={cx('checkBox')} />
                      )}

                      <span> {option.label}</span>
                    </div>

                    {selected && (
                      <img className={cx('iconSelected')} src={icons.commonIconTick} alt={t('common_img_text_alt')} />
                    )}
                  </ListboxOption>
                );
              })}
            </div>
          ) : (
            <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
          )}
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}

      <Tooltip id={`selectedMultiple-${genId}`} className={cx('tooltipText')}>
        <div className={cx('tooltip')}>
          {selectOption.slice(displayOptions.length).length > DEFAULT_NUMBER_ZERO &&
            selectOption.slice(displayOptions.length).map((option, index) => <span key={index}>{option.label}</span>)}
        </div>
      </Tooltip>
    </Field>
  );
};

export default BaseSelectMultiple;
