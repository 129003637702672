// Libs
import classNames from 'classnames/bind';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useState, useEffect, ChangeEvent, useContext, useMemo } from 'react';
import { useAppDispatch } from '~/redux/hooks';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  BaseUploadImage,
  ModalUnderDevelopment,
  PlacesAutocomplete,
} from '~/components';
// Others
import {
  ButtonTypeEnum,
  CreateClientEnum,
  RelatesClientTypeEnum,
  UpdateClientNameModalEnum,
  ReferrerTypeEnum,
} from '~/utils/enum';
import { IFormCreateClientCrm } from '~/utils/interface/crm/clients';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FILE_UPLOAD_LIMIT_50_MB,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import { mockOptionsTimezone, referrerOptions } from '~/mockData';
import { IAddress, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import { IFormUpdateClient } from '~/utils/interface/activity';
import { createClients, getDetailClients, updateClient } from '~/thunks/crm/clients/clientsThunk';
import { LoadingData } from '~/context';
import { createFormData, formatPhoneNumber } from '~/utils/helper';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
// Styles, images, icons
import styles from './CrmAddClient.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    avatarAccount: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('crm_add_new_client_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= FILE_UPLOAD_LIMIT_50_MB;
            }),
        })
      )
      .optional(),

    firstName: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.FIRST_NAME,
      })
    ),
    lastName: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.LAST_NAME,
      })
    ),
    address: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.ADDRESS,
      })
    ),
    city: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.CITY,
      })
    ),
    state: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.STATE,
      })
    ),
    postalCode: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.POSTAL_CODE,
      })
    ),
    mobilePhone: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.MOBILE_PHONE,
      })
    ),
    locationId: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.LOCATION,
      })
    ),
    referrer: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.REFERRER,
      })
    ),
    timeZone: yup.string().required(
      t('common_error_message_required', {
        message: CreateClientEnum.TIME_ZONE,
      })
    ),
    email: yup
      .string()
      .optional()
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    middleName: yup.string().trim().optional(),
    dob: yup.string().trim().optional(),
    secondaryAddress: yup.string().trim().optional(),
    notes: yup.string().trim().optional(),
    ssn: yup.string().trim().optional(),
    homePhone: yup.string().trim().optional(),
    workPhone: yup.string().trim().optional(),
    externalId: yup.string().trim().optional(),
    isDeleteAvatar: yup.boolean().optional(),
    careClientType: yup.string().trim().optional(),
  });
};

const CrmAddClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();

  const defaultValues = useMemo(() => {
    return {
      profileClient: [],
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      address: EMPTY_STRING,
      secondaryAddress: EMPTY_STRING,
      city: EMPTY_STRING,
      state: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      mobilePhone: EMPTY_STRING,
      referrer: EMPTY_STRING,
      locationId: EMPTY_STRING,
      timeZone: EMPTY_STRING,
      ssn: EMPTY_STRING,
      homePhone: EMPTY_STRING,
      workPhone: EMPTY_STRING,
      email: EMPTY_STRING,
      dob: EMPTY_STRING,
      type: RelatesClientTypeEnum.CLIENT || EMPTY_STRING,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormCreateClientCrm>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  // //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);
  }, [clientId]);

  useEffect(() => {
    handleGetListLocations();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function

  const handleGetDetailClient = async (clientId: string) => {
    loadingContext?.show();
    await dispatch(getDetailClients(clientId))
      .unwrap()
      .then((response) => {
        const data = response.data;
        const formattedData = {
          firstName: data.firstName || EMPTY_STRING,
          middleName: data.middleName || EMPTY_STRING,
          lastName: data.lastName || EMPTY_STRING,
          dob: data.dob || EMPTY_STRING,
          address: data.address || EMPTY_STRING,
          secondaryAddress: data.secondaryAddress || EMPTY_STRING,
          city: data.city || EMPTY_STRING,
          state: data.state || EMPTY_STRING,
          postalCode: data.postalCode || EMPTY_STRING,
          notes: data.notes || EMPTY_STRING,
          ssn: data.ssn || EMPTY_STRING,
          careClientType: data.careClientType || EMPTY_STRING,
          homePhone: data.homePhone || EMPTY_STRING,
          mobilePhone: data.mobilePhone || EMPTY_STRING,
          workPhone: data.workPhone || EMPTY_STRING,
          email: data.email || EMPTY_STRING,
          locationId: data?.location?.id || EMPTY_STRING,
          externalId: data.externalId || EMPTY_STRING,
          timeZone: data.timeZone || EMPTY_STRING,
          referrer: data?.referrer ? ReferrerTypeEnum.SELF : ReferrerTypeEnum.NONE,
          profileClient: data.avatarUrl ? [{ url: data.avatarUrl }] : undefined,
        };
        reset(formattedData);
      })
      .catch((error) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleCreateClient = (formData: FormData) => {
    loadingContext?.show();

    dispatch(createClients(formData))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleUpdateClient = (formData: FormData) => {
    if (!clientId) return;

    loadingContext?.show();

    const payload: IFormUpdateClient = {
      clientId: clientId,
      body: formData,
    };

    dispatch(updateClient(payload))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleSubmitFormClient = async (dataForm: IFormCreateClientCrm) => {
    const { careClientType, ...restDataForm } = dataForm;

    const newData: IFormCreateClientCrm = {
      ...restDataForm,
      profileClient:
        Array.isArray(dataForm?.profileClient) && dataForm?.profileClient?.length > DEFAULT_NUMBER_ZERO
          ? dataForm?.profileClient
          : undefined,
      ...(clientId && {
        isDeleteAvatar:
          !Array.isArray(dataForm?.profileClient) || dataForm?.profileClient?.length === DEFAULT_NUMBER_ZERO,
        homePhone: dataForm.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
        mobilePhone: dataForm.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
        workPhone: dataForm.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      }),
    };

    const filterPayload: Partial<IFormCreateClientCrm> = {};
    const filterEditPayload: Partial<IFormCreateClientCrm> = {};

    Object.entries(newData).forEach(([key, value]) => {
      if (
        (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
        (Array.isArray(value) && value?.length > DEFAULT_NUMBER_ZERO)
      ) {
        filterPayload[key as keyof IFormCreateClientCrm] = value;
      }
      filterEditPayload[key as keyof IFormCreateClientCrm] = value;
    });

    const formData = await createFormData(
      clientId ? filterEditPayload : filterPayload,
      UpdateClientNameModalEnum.PROFILE_CLIENT
    );

    if (clientId) {
      handleUpdateClient(formData);
    } else {
      handleCreateClient(formData);
    }
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleResetForm = () => {
    reset(defaultValues);
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(false);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
  };

  const handleChangeLocation = (option: IBaseOption) => {
    setValue('locationId', String(option?.value), { shouldDirty: true, shouldValidate: true });
    setValue('careClientType', String(option?.type));
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='crmAddClient' className={cx('container')}>
      <BaseButton
        onClick={handleGoBack}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />
      <div className={cx('header')}>
        <div className={cx('title')}>{t(clientId ? 'crm_edit_client_modal_title' : 'crm_add_new_client_title')}</div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitFormClient)}>
          <div className={cx('addClientFormContent')}>
            <Controller
              name={'profileClient'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('crm_add_new_client_profile_image_label')}
                  textBtn={t('crm_add_new_client_profile_image_content')}
                  height={64}
                  maxUpload={DEFAULT_NUMBER_ONE}
                  defaultImageList={value ?? undefined}
                  onChange={onChange}
                  errorMessage={errors.profileClient?.message}
                />
              )}
            />

            <div className={cx('threeCol')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='firstName'
                    label={t('crm_add_modal_first_name_label')}
                    value={value}
                    onChange={onChange}
                    messageError={errors.firstName?.message}
                    required
                  />
                )}
              />

              <Controller
                name='middleName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='middleName'
                    label={t('crm_add_modal_middle_name_label')}
                    onChange={onChange}
                    value={value || EMPTY_STRING}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    label={t('crm_add_modal_last_name_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.lastName?.message}
                    required
                  />
                )}
              />
            </div>

            <Controller
              name='dob'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseDatePicker
                  label={t('crm_add_modal_date_of_birth_label')}
                  placeholderText={t('common_select_placeholder')}
                  name='dob'
                  value={value || EMPTY_STRING}
                  onDateSelected={onChange}
                  maxDate={new Date()}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name='address'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PlacesAutocomplete
                    id='address'
                    value={value || EMPTY_STRING}
                    label={t('crm_add_modal_address_label')}
                    onChange={(address) => handleChangeAddress(address)}
                    required
                    messageError={errors.address?.message}
                  />
                )}
              />

              <Controller
                name='secondaryAddress'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='secondaryAddress'
                    label={t('crm_add_modal_secondary_address_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='city'
                    label={t('crm_add_modal_city_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.city?.message}
                    required
                  />
                )}
              />

              <Controller
                name='state'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseInput
                    id='state'
                    value={value || EMPTY_STRING}
                    label={t('crm_add_modal_state_label')}
                    onChange={onChange}
                    required
                    messageError={errors.state?.message}
                  />
                )}
              />

              <Controller
                name='postalCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='postalCode'
                    label={t('crm_add_modal_postal_code_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.postalCode?.message}
                    required={true}
                  />
                )}
              />
            </div>

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id='notes'
                  label={t('crm_add_modal_notes_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name='ssn'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='ssn'
                  label={t('crm_add_modal_ssn_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name='homePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='homePhone'
                    label={t('crm_add_modal_home_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                  />
                )}
              />

              <Controller
                name='mobilePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='mobilePhone'
                    label={t('crm_add_modal_mobile_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.mobilePhone?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='workPhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='workPhone'
                    label={t('crm_add_modal_work_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    label={t('crm_add_modal_email_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='locationId'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseSelect
                    options={locations || []}
                    label={t('crm_add_modal_location_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={handleChangeLocation}
                    value={value || EMPTY_STRING}
                    errorMessage={errors.locationId?.message}
                    required
                  />
                )}
              />

              <Controller
                name='referrer'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    options={referrerOptions || []}
                    label={t('crm_add_modal_referrer_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => onChange(value)}
                    value={value || EMPTY_STRING}
                    errorMessage={errors.referrer?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='externalId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='externalId'
                    label={t('crm_add_modal_external_id_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name='timeZone'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseSelect
                    options={mockOptionsTimezone || []}
                    label={t('crm_add_modal_time_zone_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => onChange(value)}
                    value={value || EMPTY_STRING}
                    errorMessage={errors.timeZone?.message}
                    required
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('footerButton')}>
            <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

            <BaseButton
              type='submit'
              text={clientId ? t('common_update_label') : t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={clientId ? !isDirty : false}
            />
          </div>
        </form>
      </div>

      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default CrmAddClient;
