// Libs
import { Description, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import { IBaseOption } from '~/utils/interface/common';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
import { StatusEnum } from '~/utils/enum';
import { handleEventStatusColor } from '~/utils/helpers/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './SelectStatus.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  options: IBaseOption[];
  name?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  icon?: string;
  subLabel?: string;
  onChange?: (value: IBaseOption, name?: string) => void;
};

const cx = classNames.bind(styles);

const SelectStatus = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    height = DEFAULT_SELECT_HEIGHT,
    label,
    placeholder,
    errorMessage,
    options,
    name,
    value,
    required,
    disabled = false,
    onChange,
    icon = icons.commonIconArrowBottom,
    subLabel,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IBaseOption | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;
    const optionMatchWithValue = options?.find((item) => item.value === value);

    setSelectOption(optionMatchWithValue || null);
  }, [value, options]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (value: IBaseOption) => {
    setSelectOption(value);
    onChange && onChange(value, name ?? EMPTY_STRING);
  };
  //#endregion Handle Function

  return (
    <Field id='selectStatusComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('selectBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Listbox value={selectOption} onChange={handleOptionChange}>
        <ListboxButton className={cx('btnSelect', disabled && 'bgDisable')} style={{ height }} disabled={disabled}>
          {({ open }) => (
            <>
              {placeholder && !selectOption ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <span className={cx('btnText')}>{selectOption?.label}</span>
              )}
              <img src={icon} className={cx(open ? 'iconActive' : '')} alt={t('common_img_text_alt')} />
            </>
          )}
        </ListboxButton>

        <ListboxOptions className={cx('optionList')} transition anchor={{ to: 'bottom', gap: '8px' }}>
          {subLabel && <span className={cx('subLabel')}>{subLabel}</span>}

          {options.length > 0 ? (
            <div className={cx('options')}>
              {options.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ selected, focus }) =>
                    cx(
                      'optionItem',
                      (selected || selectOption?.value === option.value) && 'optionActive',
                      focus && 'optionHover'
                    )
                  }
                >
                  {option.value !== StatusEnum.SHOW_ALL_TYPES && option.value && (
                    <div
                      className={cx('colorStatus')}
                      style={{ backgroundColor: handleEventStatusColor(option.value.toString()) }}
                    />
                  )}
                  <span>{option.label}</span>
                </ListboxOption>
              ))}
            </div>
          ) : (
            <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
          )}
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default SelectStatus;
