// Libs
import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
// Components, Layouts, Pages
import {
  Modal,
  BaseButton,
  BaseSelect,
  BaseDatePicker,
  BaseTimePicker,
  BaseInput,
  RadioAddSchedule,
  Loading,
  AssigneePopup,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { AddScheduleModalEnum, ButtonTypeEnum, RadioCrmEnum, TimeFormatEnum } from '~/utils/enum';
import { IAddAssignee, IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { optionsRecurrenceSchedule } from '~/mockData';
import { compareDataUpdate, convertCamelCaseToTitleCase, convertTime, getTimezone, getUserName } from '~/utils/helper';
import { IFormCreateSchedule } from '~/utils/interface/crm/shiftSchedule';
import { IClientDetail, IListClient, IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { RED_FF0000 } from '~/utils/constants/color';
import { createSchedule, getDetailSchedule, updateSchedule } from '~/thunks/schedule/scheduleThunk';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { IFormEditSchedule, IPayloadEditSchedule } from '~/utils/interface/schedule';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
// Styles, images, icons
import styles from './FormScheduleModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  scheduleId?: string;
  dataDetail?: IClientDetail;
  isGetListClient: boolean;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    startDate: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.START_DATE),
      })
    ),
    endDate: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.END_DATE),
      })
    ),
    startTime: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.START_TIME),
      })
    ),
    endTime: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.END_TIME),
      })
    ),
    isAllDay: yup.boolean().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.ALL_DAY),
      })
    ),
    recurrence: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.RECURRENCE),
      })
    ),
    assignees: yup
      .array()
      .of(yup.string().required())
      .min(
        DEFAULT_NUMBER_ONE,
        t('common_error_message_min_item', {
          message: convertCamelCaseToTitleCase(AddScheduleModalEnum.CAREGIVER),
          min: DEFAULT_NUMBER_ONE,
        })
      )
      .required(
        t('common_error_message_required', {
          message: convertCamelCaseToTitleCase(AddScheduleModalEnum.CAREGIVER),
        })
      ),
    accountId: yup.string().required(
      t('common_error_message_required', {
        message: convertCamelCaseToTitleCase(AddScheduleModalEnum.CLIENT_NAME),
      })
    ),
  });
};

const FormScheduleModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, scheduleId, dataDetail, isGetListClient = false } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const DEFAULT_VALUE = {
    startDate: EMPTY_STRING,
    endDate: EMPTY_STRING,
    startTime: EMPTY_STRING,
    endTime: EMPTY_STRING,
    accountId: dataDetail?.id || EMPTY_STRING,
    isAllDay: false,
    recurrence: optionsRecurrenceSchedule[DEFAULT_NUMBER_ZERO].value,
    assignees: [],
    caregiverRate: RadioCrmEnum.HOURLY,
    clientRate: RadioCrmEnum.HOURLY,
    timeZone: getTimezone(),
  };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormCreateSchedule>({
    resolver: yupResolver(schema(t)),
    defaultValues: DEFAULT_VALUE,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientList, setClientList] = useState<IBaseOption[]>([]);
  const [disableSelectDate, setDisableSelectDate] = useState<boolean>(false);
  const [listAssignee, setListAssignee] = useState<IAddAssignee[]>([]);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [initialScheduleData, setInitialScheduleData] = useState<IFormCreateSchedule>(DEFAULT_VALUE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListCaregiver({
      limit: DEFAULT_LIMIT_MAX_ITEM,
      page: DEFAULT_CURRENT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scheduleId) {
      handleGetDetailSchedule(scheduleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);

  useEffect(() => {
    if (isGetListClient) {
      handleGetListClient({
        limit: DEFAULT_LIMIT_MAX_ITEM,
        page: DEFAULT_CURRENT_PAGE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetListClient]);

  useEffect(() => {
    const { isAllDay } = getValues();

    setDisableSelectDate(isAllDay);

    if (isAllDay) {
      setValue(AddScheduleModalEnum.START_DATE, moment().format('YYYY-MM-DD'), { shouldValidate: false });
      setValue(AddScheduleModalEnum.END_DATE, moment().format('YYYY-MM-DD'), { shouldValidate: false });
    }
  }, [watch(AddScheduleModalEnum.ALL_DAY)]);
  //#endregion Implement Hook

  const handleGetDetailSchedule = (scheduleId: string) => {
    setIsLoading(true);

    dispatch(getDetailSchedule(scheduleId))
      .unwrap()
      .then((response) => {
        const { data } = response;
        const formattedEditForm: IFormCreateSchedule = {
          startDate: data.startDate || EMPTY_STRING,
          endDate: data.endDate || EMPTY_STRING,
          startTime: data.startTime || EMPTY_STRING,
          endTime: data.endTime || EMPTY_STRING,
          assignees: data.assignees?.map((assignee) => assignee.id.toString()) || [],
          isAllDay: data.isAllDay || false,
          clientRate: data.clientRate,
          caregiverRate: data.caregiverRate,
          recurrence: data.recurrence || EMPTY_STRING,
          accountId: data?.client?.id || EMPTY_STRING,
          caregiverConfirmation: data.caregiverConfirmation || false,
        };

        reset(formattedEditForm);
        setInitialScheduleData(formattedEditForm);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeSelect = (option: IBaseOption, name?: keyof IFormCreateSchedule) => {
    const { value } = option;
    if (name) {
      setValue(name, value as string, { shouldDirty: true });
      trigger(name);
    }
  };

  const handleSelectDate = (date: string, name?: keyof IFormCreateSchedule) => {
    if (name) {
      setValue(name, date, { shouldDirty: true });
      trigger(name);
    }
  };

  const handleSelectTime = (date: Date, name?: keyof IFormCreateSchedule) => {
    if (name) {
      setValue(name, date.toString(), { shouldDirty: true });
      trigger(name);
    }
  };

  const convertClientToOptions = (clients: IListClient[]): IBaseOption[] => {
    return clients.map((client) => ({
      value: client.id,
      label: getUserName(client.firstName, client.lastName),
    }));
  };

  const handleGetListClient = (params: IListQueryParamsClients) => {
    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<IListClient[]> = res?.data;

        setClientList(convertClientToOptions(responses));
      })
      .catch((error) => {});
  };

  const handleGetListCaregiver = (payload: IListQueryParams) => {
    dispatch(getListCaregiver(payload))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;

        setListAssignee(responses);
      })
      .catch((err) => {});
  };

  const handleSave = (data: IFormCreateSchedule) => {
    if (scheduleId) {
      handleEditSchedule(data);
    } else {
      handleAddSchedule(data);
    }
  };

  const handleAddSchedule = (data: IFormCreateSchedule) => {
    setIsLoading(true);

    const updateData: IFormCreateSchedule = {
      ...data,
      accountId: dataDetail?.id ? dataDetail?.id : data.accountId,
      startTime: convertTime(data.startTime, TimeFormatEnum.HH_MM),
      endTime: convertTime(data.endTime, TimeFormatEnum.HH_MM),
    };

    dispatch(createSchedule(updateData))
      .unwrap()
      .then((res) => {
        dispatch(scheduleActions.setRefreshScheduleList(true));
        onClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditSchedule = (data: IFormCreateSchedule) => {
    setIsLoading(true);
    if (scheduleId) {
      const dataChanges = compareDataUpdate(initialScheduleData, data);
      const updateData: IFormEditSchedule = {
        ...dataChanges,
        accountId: dataDetail?.id ? dataDetail?.id : data.accountId,
        startTime: convertTime(data.startTime, TimeFormatEnum.HH_MM),
        endTime: convertTime(data.endTime, TimeFormatEnum.HH_MM),
        startDate: convertTime(data.startDate, TimeFormatEnum.YYYY_MM_DD),
        endDate: convertTime(data.endDate, TimeFormatEnum.YYYY_MM_DD),
        timeZone: getTimezone(),
      };

      const payload: IPayloadEditSchedule = {
        scheduleId: scheduleId,
        body: updateData,
      };

      dispatch(updateSchedule(payload))
        .unwrap()
        .then((res) => {
          dispatch(scheduleActions.setRefreshScheduleList(true));
          onClose && onClose();
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false));
    }
  };
  //#endregion Handle Function

  return (
    <Modal
      title={
        scheduleId
          ? t('crm_client_detail_schedule_tab_edit_schedule_title')
          : t('crm_client_detail_schedule_tab_add_schedule_title')
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id='crmAddScheduleModalComponent' className={cx('crmAddScheduleModal')} onSubmit={handleSubmit(handleSave)}>
        <div className={cx('contentModal')}>
          <div className={cx('fourItemRow')}>
            <div className={cx('twoItemRow')}>
              <Controller
                name={AddScheduleModalEnum.START_DATE}
                control={control}
                render={({ field: { name, value } }) => (
                  <BaseDatePicker
                    name={AddScheduleModalEnum.START_DATE}
                    label={t('crm_client_detail_schedule_tab_start_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value}
                    onDateSelected={(date: string) => handleSelectDate(date, name)}
                    errorMessage={!disableSelectDate ? errors.startDate?.message : EMPTY_STRING}
                    disable={disableSelectDate}
                    required
                  />
                )}
              />

              <Controller
                name={AddScheduleModalEnum.START_TIME}
                control={control}
                render={({ field: { name, value } }) => (
                  <BaseTimePicker
                    name={AddScheduleModalEnum.START_TIME}
                    label={t('crm_client_detail_schedule_tab_start_time_label')}
                    placeholderText={t('crm_client_detail_schedule_tab_time_placeholder')}
                    value={value}
                    onTimeChange={(date: Date) => handleSelectTime(date, name)}
                    errorMessage={errors.startTime?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('textTo')}>{t('crm_client_detail_schedule_tab_text_to_label')}</div>

            <div className={cx('twoItemRow')}>
              <Controller
                name={AddScheduleModalEnum.END_DATE}
                control={control}
                render={({ field: { name, value } }) => (
                  <BaseDatePicker
                    name={AddScheduleModalEnum.END_DATE}
                    label={t('crm_client_detail_schedule_tab_end_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value}
                    onDateSelected={(date: string) => handleSelectDate(date, name)}
                    errorMessage={!disableSelectDate ? errors.endDate?.message : EMPTY_STRING}
                    disable={disableSelectDate}
                    required
                  />
                )}
              />

              <Controller
                name={AddScheduleModalEnum.END_TIME}
                control={control}
                render={({ field: { name, value } }) => (
                  <BaseTimePicker
                    name={AddScheduleModalEnum.END_TIME}
                    label={t('crm_client_detail_schedule_tab_end_time_label')}
                    placeholderText={t('crm_client_detail_schedule_tab_time_placeholder')}
                    value={value}
                    onTimeChange={(date: Date) => handleSelectTime(date, name)}
                    errorMessage={errors.endTime?.message}
                    required
                  />
                )}
              />
            </div>
          </div>

          <Controller
            name={'accountId'}
            control={control}
            render={({ field }) =>
              !isGetListClient ? (
                <BaseInput
                  label={t('crm_client_detail_schedule_tab_schedule_client_name_label')}
                  id={AddScheduleModalEnum.CLIENT_NAME}
                  name={'accountId'}
                  value={`${dataDetail?.firstName} ${dataDetail?.lastName}`}
                  //placeholder={t('crm_client_detail_schedule_tab_schedule_client_name_label')}
                  onChange={field.onChange}
                  messageError={errors.accountId?.message}
                  required
                  disabled
                />
              ) : (
                <BaseSelect
                  options={clientList}
                  name={'accountId'}
                  label={t('crm_client_detail_schedule_tab_schedule_client_name_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => handleChangeSelect(optionSelected, field.name)}
                  value={field.value}
                  errorMessage={errors.accountId?.message}
                  required
                />
              )
            }
          />

          <div className={cx('twoCol')}>
            <div className={cx('twoRowCheckbox')}>
              <div className={cx('textAllDay')}>{t('crm_client_schedule_form_all_day_label')}</div>
              <Controller
                name={AddScheduleModalEnum.ALL_DAY}
                control={control}
                render={({ field }) => (
                  <input
                    id='allDay'
                    type='checkbox'
                    className={cx('inputCheckbox')}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                )}
              />
            </div>

            <Controller
              name={AddScheduleModalEnum.RECURRENCE}
              control={control}
              render={({ field: { name, value } }) => (
                <BaseSelect
                  options={optionsRecurrenceSchedule}
                  name={AddScheduleModalEnum.RECURRENCE}
                  label={t('crm_client_detail_schedule_tab_recurrence_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => handleChangeSelect(optionSelected, name)}
                  value={value}
                  errorMessage={errors.recurrence?.message}
                />
              )}
            />
          </div>

          <div className={cx('contentWrap')}>
            <Controller
              name={'assignees'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <AssigneePopup
                  name='assignees'
                  value={value}
                  label={t('crm_client_detail_schedule_tab_caregiver_label')}
                  assigneeList={listAssignee}
                  onChange={(value) => onChange(value)}
                  errorMessage={errors.assignees?.message}
                  placeholder={t('common_select_placeholder')}
                  required
                  icon={icons.commonIconArrowBottom}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <div className={cx('textLabel')}>
                {t('crm_client_detail_schedule_tab_or_label')}
                <span className={cx('textLabelBold')}>{t('crm_client_detail_schedule_tab_check_schedule_label')}</span>
                {t('crm_client_detail_schedule_tab_availability_label')}
              </div>
              <div className={cx('twoRow')}>
                <Controller
                  name={'caregiverConfirmation'}
                  control={control}
                  render={({ field }) => (
                    <input
                      id='caregiverConfirmationId'
                      type='checkbox'
                      name='caregiverConfirmation'
                      className={cx('inputCheckbox')}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <div className={cx('textLabel')}>{t('crm_client_schedule_form_caregiver_confirm_label')}</div>
              </div>
            </div>
          </div>

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>{t('crm_client_schedule_form_client_rate_label')}</div>

            <div className={cx('contentWrapRadio')}>
              <Controller
                name={'clientRate'}
                control={control}
                render={({ field }) => (
                  <div className={cx('radioGroup')}>
                    <RadioAddSchedule
                      id={'hourlySelectCaregiverId'}
                      name={'clientRate'}
                      label={t('crm_client_schedule_form_hourly_radio_value')}
                      value={RadioCrmEnum.HOURLY}
                      checked={field.value === RadioCrmEnum.HOURLY}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'perVisitSelectCaregiverId'}
                      name={'clientRate'}
                      label={t('crm_client_schedule_form_per_visit_radio_value')}
                      value={RadioCrmEnum.PER_VISIT}
                      checked={field.value === RadioCrmEnum.PER_VISIT}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'liveInSelectCaregiverId'}
                      name={'clientRate'}
                      label={t('crm_client_schedule_form_live_radio_value')}
                      value={RadioCrmEnum.LIVE_IN}
                      checked={field.value === RadioCrmEnum.LIVE_IN}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'notBillableSelectCaregiverId'}
                      name={'clientRate'}
                      label={t('crm_client_schedule_form_not_billable_radio_value')}
                      value={RadioCrmEnum.NOT_BILLABLE}
                      checked={field.value === RadioCrmEnum.NOT_BILLABLE}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          {/* <Controller
            name={AddScheduleModalEnum.RECURRENCE}
            control={control}
            render={({ field: { name, value } }) => (
              <BaseSelect
                options={optionsRecurrenceSchedule}
                name={AddScheduleModalEnum.RECURRENCE}
                onChange={(optionSelected: IBaseOption) => handleChangeSelect(optionSelected, name)}
                value={value}
                errorMessage={errors.recurrence?.message}
                required
              />
            )}
          /> */}

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>{t('crm_client_schedule_form_caregiver_rate_label')}</div>

            <div className={cx('contentWrapRadio')}>
              <Controller
                name={'caregiverRate'}
                control={control}
                render={({ field }) => (
                  <div className={cx('radioGroup')}>
                    <RadioAddSchedule
                      id={'hourlyCaregiverRateId'}
                      name={'caregiverRate'}
                      label={t('crm_client_schedule_form_hourly_radio_value')}
                      value={RadioCrmEnum.HOURLY}
                      checked={field.value === RadioCrmEnum.HOURLY}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'perVisitCaregiverRateId'}
                      name={'caregiverRate'}
                      label={t('crm_client_schedule_form_per_visit_radio_value')}
                      value={RadioCrmEnum.PER_VISIT}
                      checked={field.value === RadioCrmEnum.PER_VISIT}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'liveInCaregiverRateId'}
                      name={'caregiverRate'}
                      label={t('crm_client_schedule_form_live_radio_value')}
                      value={RadioCrmEnum.LIVE_IN}
                      checked={field.value === RadioCrmEnum.LIVE_IN}
                      onChange={field.onChange}
                    />
                    <RadioAddSchedule
                      id={'notPayableCaregiverRateId'}
                      name={'caregiverRate'}
                      label={t('crm_client_schedule_form_not_billable_radio_value')}
                      value={RadioCrmEnum.NOT_PAYABLE}
                      checked={field.value === RadioCrmEnum.NOT_PAYABLE}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className={cx('twoCol')}>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_add_mileage_title')}</div>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_expense_credit_title')}</div>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_add_bill_pay_title')}</div>
          </div>
        </div>

        <div className={cx('footerButton')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} type='button' />
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={scheduleId ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormScheduleModal;
