// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Components, Layouts, Pages
// Others
import { INotification } from '~/utils/interface/notification';
import { AccountRoleCodesEnum, BusinessNotificationTypeEnum, NotificationStatusEnum, StorageEnum } from '~/utils/enum';
import {
  EMPTY_STRING,
  HOURS_IN_A_DAY,
  MINUTES_IN_HOUR,
  ONE_SECOND,
  SECONDS_IN_A_MINUTE,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './NotificationItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data: INotification;
  onClick: (notification: INotification) => void;
};

const cx = classNames.bind(styles);

const NotificationItem = (props: Props) => {
  //#region Destructuring Props
  const { data, onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderDescription = (businessType: string) => {
    if (!role) return;

    switch (businessType) {
      case BusinessNotificationTypeEnum.TASK:
        if (role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.SUPER_ADMIN) {
          return t('notification_task_admin_role');
        }
        return t('notification_task_staff_role');
      default:
        return EMPTY_STRING;
    }
  };

  const renderTitleNotification = (notification: INotification) => {
    switch (notification.businessType) {
      case BusinessNotificationTypeEnum.TASK:
        return t('notification_title_task', { taskId: notification.businessId });
      default:
        return EMPTY_STRING;
    }
  };

  const timeElapsed = (dateStr: string | null) => {
    if (!dateStr) return EMPTY_STRING;

    const now = moment();
    const past = moment(dateStr);
    const diffInSeconds = now.diff(past, 'seconds');
    const diffInMinutes = Math.floor(diffInSeconds / SECONDS_IN_A_MINUTE);
    const diffInHours = Math.floor(diffInMinutes / MINUTES_IN_HOUR);
    const pastDays = past.startOf('day');
    const diffInDays = now.diff(pastDays, 'days');

    if (diffInSeconds < ONE_SECOND) {
      return EMPTY_STRING;
    }

    if (diffInSeconds < SECONDS_IN_A_MINUTE) {
      return `${t('notification_seconds_ago_label', { time: diffInSeconds })}`;
    }

    if (diffInMinutes < MINUTES_IN_HOUR) {
      return `${t('notification_minutes_ago_label', { time: diffInMinutes })}`;
    }

    if (diffInHours < HOURS_IN_A_DAY) {
      return `${t('notification_hours_ago_label', { time: diffInHours })}`;
    }

    return `${t('notification_days_ago_label', { time: diffInDays })}`;
  };

  const renderIcon = (businessType: string) => {
    switch (businessType) {
      case BusinessNotificationTypeEnum.TASK:
        return icons.commonIconNote;
      default:
        break;
    }
  };

  const handleNotificationClicked = () => {
    onClick(data);
  };
  //#endregion Handle Function

  return (
    <div
      id='notificationItemComponent'
      className={cx('container', data.status === NotificationStatusEnum.UNREAD && 'unRead')}
      onClick={handleNotificationClicked}
    >
      <div className={cx('notificationContainer')}>
        <div className={cx('notificationIcon')}>
          {renderIcon(data.businessType) && <img src={icons.commonIconNote} alt={t('common_image_notification')} />}
        </div>

        <div className={cx('notificationContentBox')}>
          <div className={cx('notificationContent')}>
            <div className={cx('notificationTitleBox')}>
              <div className={cx('notificationTitle')}>{renderTitleNotification(data)}</div>
              <span className={cx('notificationTime')}>{timeElapsed(data.createdAt)}</span>
            </div>

            <div className={cx('notificationDescription')}>{renderDescription(data.businessType)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
