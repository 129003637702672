// Apis
export const urlApiAuth = {
  login: 'auth/login',
  refreshToken: 'auth/refresh-token',
  forgotPassword: 'auth/forgot-password',
  newPassword: 'auth/new-password',
  changePassword: 'users/change-password',
  verifyCode: 'auth/verify-code',
};

export const urlApiEmployee = {
  getList: 'employees',
  createEmployee: 'employees/new',
  updateEmployee: (employeeId: string) => `employees/${employeeId}`,
};

export const urlApiCaregiver = {
  getList: 'caregivers',
  createCaregiver: 'caregivers/new',
  editCaregiver: (id: string) => `caregivers/${id}`,
  getDetail: (id: string) => `caregivers/${id}`,
  deleteCaregiver: (accountId: string) => `caregivers/${accountId}`,
  getProfileTags: 'master-data',
  getCaregiversByLocation: 'caregivers/by-location',
};

export const urlApiUser = {
  getDetail: (accountId: string) => `users/${accountId}`,
  getProfile: 'users/profile',
  changeStatusAccount: (accountId: string) => `users/${accountId}/change-status`,
  getListAssignee: 'users/assignee',
  deleteUser: (accountId: string) => `users/${accountId}`,
};

export const urlApiApplicant = {
  getList: 'applicants',
  createApplicant: 'applicants/new',
};

export const urlApiConversation = {
  getList: 'conversations',
  create: 'conversations/new',
  getDetail: (conversationId: string) => `conversations/${conversationId}/messages`,
  createMessage: (conversationId: string) => `conversations/${conversationId}/messages/new`,
};

export const urlApiCareAlert = {
  getList: 'care-alerts',
};

export const urlApiCrm = {
  getList: 'clients',
  createClient: 'clients/new',
  changeStatusClient: (clientId: string) => `clients/${clientId}/change-status`,
  getDetailClient: (clientId: string) => `clients/${clientId}`,
  updateClient: (clientId: string) => `clients/${clientId}`,
  uploadFileClients: 'file/documents/new',
  getListRelates: 'clients/options/relates',
  getListActivityByClient: (clientId: string) => `activities/client/${clientId}`,
  sendEmailToClient: (clientId: string) => `clients/${clientId}/send-email`,
  getDetailCarePlan: (clientId: string) => `clients/${clientId}/care-plan`,
  createCarePlan: (clientId: string) => `clients/${clientId}/care-plan`,
};

export const urlApiReferral = {
  getListReferrals: 'referrals',
  createReferrals: 'referrals/new',
  updateReferrals: (accountId: string) => `referrals/${accountId}`,
  getDetailReferrals: (accountId: string) => `referrals/${accountId}`,
};

export const urlApiSchedule = {
  createSchedule: 'schedules/new',
  getListShiftSchedule: 'schedules',
  getListSchedule: 'schedules',
  getScheduleClient: 'schedules/client',
  getOfficeSchedule: 'schedules/calendars/office',
  getSchedulesLiveView: '/schedules/live-view',
  deleteSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  getDetailSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  updateSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  createShiftSchedule: '/shifts/new',
  updateShiftSchedule: (shiftId: string) => `/shifts/${shiftId}`,
  getDetailShiftSchedule: (shiftId: string) => `/shifts/${shiftId}`,
  getListNoteSchedule: '/shifts/note',
  createShiftNote: '/shifts/note/new',
};

export const urlApiFile = {
  getListDocuments: `file`,
  deleteDocument: (fileId: string) => `file/${fileId}`,
  downloadDocument: (fileId: string) => `file/download/${fileId}`,
  uploadDocument: `file/new`,
  updateDocument: (fileId: string) => `file/${fileId}`,
  getDetailDocument: (fileId: string) => `file/${fileId}`,
};

export const urlApiTask = {
  getListTask: 'tasks',
  getSelectTask: 'tasks/select',
  createTask: 'tasks/new',
  getTaskDetail: (taskId: string) => `tasks/${taskId}`,
  deleteTask: (taskId: string) => `tasks/${taskId}`,
  updateTask: (taskId: string) => `tasks/${taskId}`,
};

export const urlApiTimeClock = {
  getListTimeClock: 'time-clocks',
  createTimeClock: 'time-clocks/new',
  detail: (timeClockId: number) => `time-clocks/${timeClockId}`,
  update: (timeClockId: number) => `time-clocks/${timeClockId}`,
  deleteTimeClock: (timeClockId: string) => `time-clocks/${timeClockId}`,
  changeStatus: (timeClockId: string) => `time-clocks/${timeClockId}/change-status`,
};

export const urlApiDashboard = {
  statistics: 'statistics/summary',
};

export const urlApiActivity = {
  getListActivityByClient: (userId: string) => `activities/client/${userId}`,
  getListActivityByCaregiver: (userId: string) => `activities/caregiver/${userId}`,
};

export const urlApiQuickBook = {
  getAuthUrl: 'quick-books/auth-url',
  getToken: 'quick-books/token',
  disconnect: 'quick-books/disconnect',
};

export const urlApiNotifications = {
  getList: 'notifications',
  markedRead: (notificationId: string) => `notifications/${notificationId}/read`,
};

export const urlApiReport = {
  getLocationsCaregiverInReport: '/reports/caregivers/check-in-locations',
  getAllCaregiversByLocation: '/reports/caregivers/by-location',
  getAllClientsByLocation: '/reports/clients/by-location',
};

export const urlApiRates = {
  getList: '/rates',
};

export const urlApiLocation = {
  createLocation: 'locations/new',
  getListLocation: 'locations',
  getDetailLocation: (locationId: string) => `locations/${locationId}`,
};
