import { urlApiCaregiver } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IListQueryParams, ITag } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import {
  ICaregiver,
  ICaregiverByLocation,
  ICaregiverDetail,
  IEditCaregiver,
  IProfileTag,
  IQueryGetCaregiversByLocation,
} from '~/utils/interface/caregiver';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const caregiverApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiCaregiver.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICaregiver[]>>>(url, { params });
  },

  createCaregiver(body: FormData) {
    const url = `${urlApiCaregiver.createCaregiver}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  editCaregiver({ caregiverId, body }: IEditCaregiver) {
    const url = `${urlApiCaregiver.editCaregiver(caregiverId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailCaregiver(accountId: string) {
    const url = `${urlApiCaregiver.getDetail(accountId)}`;
    return axiosClient.get<BaseResponse<ICaregiverDetail>>(url);
  },

  deleteCaregiver(accountId: string) {
    const url = `${urlApiCaregiver.deleteCaregiver(accountId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getProfileTags(params: IProfileTag) {
    const url = `${urlApiCaregiver.getProfileTags}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITag[]>>>(url, { params });
  },

  getCaregiversByLocation(params: IQueryGetCaregiversByLocation) {
    const url = `${urlApiCaregiver.getCaregiversByLocation}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICaregiverByLocation[]>>>(url, { params });
  },
};

export default caregiverApi;
