// Libs
// Components, Layouts, Pages
// Others
import { MALACHITE } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

const CommonIconMessageStatus = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 14, height = 14, strokePath = MALACHITE, fill = 'none', double = true } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14 1.46484L4.92857 10.5361L1.5 6.53607'
        stroke={strokePath}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {double && (
        <path
          d='M20 1.46484L10.9286 10.5361L7.5 6.53607'
          stroke={strokePath}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      )}
    </svg>
  );
};

export default CommonIconMessageStatus;
