// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { IConversation } from '~/utils/interface/conversation';
import { convertTime } from '~/utils/helper';
import { ImageCircleTypeEnum, MessageStatusEnum, MessageTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING, FILE_IMAGE_TYPE } from '~/utils/constants/common';
import { CommonIconMessageStatus } from '~/assets/svgComponents';
import { MALACHITE, MINERAL_GREEN } from '~/utils/constants/color';
// Styles, images, icons
import styles from './MessageItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data: IConversation;
  onClick: (id: string) => void;
};

const cx = classNames.bind(styles);

const MessageItem = (props: Props) => {
  //#region Destructuring Props
  const { data, onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { conversationId } = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isActive, setIsActive] = useState<boolean>(conversationId === data?.id?.toString());
  const [dateLastMessage] = useState<string>(() => {
    if (!data.lastMessage?.createdAt) {
      return EMPTY_STRING;
    }
    return convertTime(data.lastMessage?.createdAt, TimeFormatEnum.HOUR_MINUTE_AM_PM);
  });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data?.id || !conversationId) return;

    setIsActive(conversationId === data?.id?.toString());
  }, [conversationId, data?.id]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClick = () => {
    onClick && onClick(data.id);
  };

  const renderStatusMessage = (data: IConversation) => {
    if (data?.lastMessage) {
      if (data.countUnseen > DEFAULT_NUMBER_ZERO) return <span className={cx('countUnSeen')}>{data.countUnseen}</span>;

      if (data.lastMessage.status === MessageStatusEnum.SENT)
        return <CommonIconMessageStatus strokePath={MINERAL_GREEN} double={false} />;

      if (data.lastMessage.status === MessageStatusEnum.DELIVERED)
        return <CommonIconMessageStatus strokePath={MINERAL_GREEN} />;

      if (data.lastMessage.status === MessageStatusEnum.SEEN) return <CommonIconMessageStatus strokePath={MALACHITE} />;
    }

    return EMPTY_STRING;
  };

  const renderContent = () => {
    if (data.isTyping) {
      return <p className={cx('messageItemMessage')}>{t('message_title_typing')}</p>;
    }

    if (data.lastMessage) {
      if (data.lastMessage?.type === MessageTypeEnum.TEXT) {
        return <p className={cx('messageItemMessage')}>{data.lastMessage?.content}</p>;
      }

      if (data.lastMessage?.type === MessageTypeEnum.FILE && data.lastMessage?.mimetype?.startsWith(FILE_IMAGE_TYPE)) {
        return (
          <div className={cx('containerMessageContent')}>
            <img src={icons.commonIconGallery} alt={t('common_img_text_alt')} className={cx('messageIcon')} />
            <p className={cx('messageItemMessage')}>{t('message_icon_image')}</p>
          </div>
        );
      }
    }

    return <p className={cx('messageItemMessage')}></p>;
  };
  //#endregion Handle Function

  return (
    <div id='messageItem' className={cx('messageItem', isActive && 'active')} onClick={handleClick}>
      <ImageCircle
        type={data?.account?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        width={40}
        height={40}
        fontSize={20}
        imageUrl={data?.account?.avatarUrl}
        firstName={data.account?.firstName}
      />
      <div className={cx('messageItemContent')}>
        <span className={cx('messageItemName')}>{`${data.account?.firstName} ${
          data.account?.lastName ?? EMPTY_STRING
        }`}</span>
        {renderContent()}
      </div>

      <div className={cx('messageItemTime')}>
        <p className={cx('dateTime')}>{data?.lastMessage && dateLastMessage}</p>
        <p className={cx('statusMessage')}> {renderStatusMessage(data)}</p>
      </div>
    </div>
  );
};

export default MessageItem;
