// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseTextarea, CheckboxSingle, ModalUnderDevelopment } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  COMMA_SEPARATOR,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { createShiftNoteSchedule, getListNoteSchedule } from '~/thunks/schedule/scheduleThunk';
import { IListDataResponse } from '~/utils/interface/common';
import { ICreateShiftNote, IParamsGetNotes, ITabNotes } from '~/utils/interface/schedule';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './TabNotes.module.scss';

type Props = {
  shiftId?: string;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    shiftId: yup.string().required(),
    description: yup.string().required(),
  });
};

const defaultValues: ICreateShiftNote = {
  shiftId: EMPTY_STRING,
  description: EMPTY_STRING,
};

const TabNotes = (props: Props) => {
  //#region Destructuring Props
  const { shiftId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ICreateShiftNote>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshShiftNote } = useAppSelector((state) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [isOpenDevelopment, setIsOpenDevelopment] = useState<boolean>(false);
  const [notesList, setNotesList] = useState<ITabNotes[]>([]);
  const [isBillingPage, setIsBillingPage] = useState(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListNoteSchedule();
  }, []);

  useEffect(() => {
    if (isRefreshShiftNote) {
      handleGetListNoteSchedule();
    }
  }, [isRefreshShiftNote]);

  useEffect(() => {
    if (shiftId) {
      setValue('shiftId', shiftId);
    }
  }, [shiftId]);
  //#endregion Implement Hook

  //#region Handle Function
  // const handleCheckboxSingle = (value: boolean, name?: keyof ITabNotes) => {
  //   if (name) {
  //     setValue(name, value, { shouldDirty: true });
  //     trigger(name);
  //   }
  // };

  const handleCloseDevelopment = () => {
    setIsOpenDevelopment(false);
  };

  const handleSubmitForm = (data: ICreateShiftNote) => {
    const payload: ICreateShiftNote = {
      ...data,
      shiftId: shiftId || EMPTY_STRING,
      description: data.description,
    };

    handleAddShiftNoteSchedule(payload);
  };

  const handleClockIn = () => {
    //TODO: Handle logic clock in
    setIsOpenDevelopment(true);
  };

  const handleGetListNoteSchedule = () => {
    if (!shiftId) return;

    const payload: IParamsGetNotes = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      shiftId: shiftId,
    };

    dispatch(getListNoteSchedule(payload))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<ITabNotes[]> = res?.data;
        setNotesList(responses);
      })
      .catch((err) => {});
  };

  const handleAddShiftNoteSchedule = (params: ICreateShiftNote) => {
    dispatch(createShiftNoteSchedule(params))
      .unwrap()
      .then((res) => {
        reset({ shiftId: shiftId, description: EMPTY_STRING });
      })
      .catch((err) => {});
  };

  const handleCheckboxChange = (value: boolean) => {
    setIsBillingPage(value);
  };

  const renderNoteDetails = (note: ITabNotes) => {
    return (
      <>
        <span className={cx('label')} />
        <span>
          "{note.description || EMPTY_STRING}"{COMMA_SEPARATOR}
          <span className={cx('noteContent')}>
            {` ${t('tab_notes_modal_created_by_label')} ${
              note?.createdBy ? getUserName(note.createdBy.firstName, note.createdBy.lastName) : EMPTY_STRING
            }${COMMA_SEPARATOR} ${
              note?.createdAt
                ? convertDateToFormatTime(note.createdAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
                : EMPTY_STRING
            }`}
          </span>
        </span>
      </>
    );
  };
  //#endregion Handle Function

  return (
    <form id='TabNotesComponent' className={cx('TabNotesComponent')} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={cx('container')}>
        <p className={cx('tabNotesTitle')}>{t('tab_notes_title')}</p>
        <div className={cx('tabNotesContainer')}>
          <div className={cx('tabNotesContent')}>
            <div className={cx('noteListWrapper')}>
              {notesList.length > DEFAULT_NUMBER_ZERO &&
                notesList.map((note, index) => (
                  <div key={index} className={cx('noteItem')}>
                    {renderNoteDetails(note)}
                  </div>
                ))}
            </div>

            <div className={cx('tabNotes')}>
              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    id='description'
                    label={t('tab_notes_modal_notes_label')}
                    placeholder={t('tab_notes_modal_notes_placeholder')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    height={35}
                  />
                )}
              />
            </div>
          </div>

          <CheckboxSingle
            label={t('tab_notes_modal_display_on_billing_page')}
            value={isBillingPage}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>

      <div className={cx('border')}></div>

      <div className={cx('footerButton')}>
        <BaseButton
          text={t('tab_notes_button_clock_in')}
          width={80}
          type='button'
          typeStyle={ButtonTypeEnum.OUT_LINE}
          color='primary'
          onClick={handleClockIn}
        />
        <BaseButton
          text={t('common_save_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          width={80}
          type='submit'
          disabled={!isValid}
        />
      </div>
      {isOpenDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}
    </form>
  );
};

export default memo(TabNotes);
