// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { handleLogin } from '~/thunks/auth/authThunk';
import { ILoginPayload, ILoginValid } from '~/utils/interface/auth';
import { EMPTY_STRING, LoginType, RegExp } from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  authRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { AccountRoleCodesEnum, ButtonTypeEnum, SignInNameEnum, StorageEnum, ToastTypeEnum } from '~/utils/enum';
import { validateWithRegex } from '~/utils/helper';
import { generateGuid } from '~/utils/helpers/common';
// Styles, images, icons
import styles from './SignIn.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const SignIn = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formValues, setFormValues] = useState<ILoginPayload>({
    type: LoginType.STANDARD,
    email: EMPTY_STRING,
    password: EMPTY_STRING,
  });
  const [formErrors, setFormErrors] = useState<ILoginValid>({});
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const rememberMe = localStorage.getItem(StorageEnum.REMEMBER_ME);
    const email = localStorage.getItem(StorageEnum.EMAIL);

    if (!rememberMe || !email) return;

    setIsRememberMe(JSON.parse(rememberMe));
    setFormValues((prevState) => ({ ...prevState, email: email }));
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({ ...prevState, [name]: value }));

    if (name === SignInNameEnum.EMAIL && !value) {
      setFormErrors((prevState) => ({ ...prevState, email: t('sign_in_email_required') }));
    } else if (name === SignInNameEnum.EMAIL && !validateWithRegex(value, RegExp.EMAIL)) {
      setFormErrors((prevState) => ({ ...prevState, email: t('sign_in_email_invalid') }));
    }

    if (name === SignInNameEnum.PASSWORD && !value) {
      setFormErrors((prevState) => ({ ...prevState, password: t('sign_in_password_required') }));
    }

    if (name === SignInNameEnum.EMAIL && value && validateWithRegex(value, RegExp.EMAIL)) {
      setFormErrors((prevState) => ({ ...prevState, email: EMPTY_STRING }));
    }

    if (name === SignInNameEnum.PASSWORD && value) {
      setFormErrors((prevState) => ({ ...prevState, password: EMPTY_STRING }));
    }
  };

  const handleNavigateToSignUp = () => {
    navigate(authRouteAbsolute.signUp);
  };

  const handleNavigateToForgotPassword = () => {
    navigate(authRouteAbsolute.forgotPassword);
  };

  const handleTogglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleToggleRememberMe = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setIsRememberMe(checked);
  };

  const handleSignIn = () => {
    if (!formValues.email) {
      setFormErrors((prevState) => ({ ...prevState, email: t('sign_in_email_required') }));
    } else if (!validateWithRegex(formValues.email, RegExp.EMAIL)) {
      setFormErrors((prevState) => ({ ...prevState, email: t('sign_in_email_invalid') }));
    }

    if (!formValues.password) {
      setFormErrors((prevState) => ({ ...prevState, password: t('sign_in_password_required') }));
    }

    if (formValues.email && formValues.password && validateWithRegex(formValues.email, RegExp.EMAIL)) {
      loadingContext?.show();

      dispatch(handleLogin(formValues))
        .unwrap()
        .then((res) => {
          if (!res.data) return;
          const accountInfo = res.data.account;
          const tokenInfo = res.data.token;

          localStorage.setItem(StorageEnum.ACCESS_TOKEN, tokenInfo.access);
          localStorage.setItem(StorageEnum.REFRESH_TOKEN, tokenInfo.refresh);
          localStorage.setItem(StorageEnum.ROLE, accountInfo.role);
          localStorage.setItem(StorageEnum.USER_ID, accountInfo.id);
          localStorage.setItem(StorageEnum.DEVICE_ID, generateGuid());

          if (isRememberMe) {
            localStorage.setItem(StorageEnum.EMAIL, formValues.email);
            localStorage.setItem(StorageEnum.REMEMBER_ME, JSON.stringify(isRememberMe));
          } else {
            localStorage.removeItem(StorageEnum.EMAIL);
            localStorage.removeItem(StorageEnum.REMEMBER_ME);
          }

          switch (accountInfo.role) {
            case AccountRoleCodesEnum.SUPER_ADMIN:
              navigate(`${superAdminRouteAbsolute.home}`);
              break;
            case AccountRoleCodesEnum.ADMIN:
              navigate(`${adminRouteAbsolute.home}`);
              break;
            case AccountRoleCodesEnum.EMPLOYEE:
              navigate(`${staffRouteAbsolute.home}`);
              break;
            case AccountRoleCodesEnum.CAREGIVER:
              navigate(`${caregiverRouteAbsolute.home}`);
              break;
          }
        })
        .catch((error) => {})
        .finally(() => {
          loadingContext?.hide();
        });
    }
  };
  //#endregion Handle Function

  return (
    <div id='signInPage' className={cx('container')}>
      <h3 className={cx('title')}>{t('sign_in_title')}</h3>
      <p className={cx('desc')}>{t('sign_in_desc')}</p>

      <div className={cx('form')}>
        <div className={cx('email')}>
          <label htmlFor={SignInNameEnum.EMAIL} className={cx('label')}>
            {t('sign_in_label_email')}
          </label>

          <div className={cx('inputGroup')}>
            <img src={icons.signInIconEmail} alt={t('common_img_text_alt')} className={cx('inputPrefix')} />

            <input
              name={SignInNameEnum.EMAIL}
              type='text'
              id={SignInNameEnum.EMAIL}
              placeholder={t('sign_in_placeholder_email')}
              className={cx('input')}
              value={formValues.email}
              onChange={handleInputChange}
            />
          </div>

          {formErrors.email && <p className={cx('errorMessage')}>{formErrors.email}</p>}
        </div>

        <div className={cx('password')}>
          <label htmlFor={SignInNameEnum.PASSWORD} className={cx('label')}>
            {t('sign_in_label_password')}
          </label>

          <div className={cx('inputGroup')}>
            <img src={icons.signInIconPassword} alt={t('common_img_text_alt')} className={cx('inputPrefix')} />

            <input
              name={SignInNameEnum.PASSWORD}
              type={isShowPassword ? 'text' : 'password'}
              id={SignInNameEnum.PASSWORD}
              placeholder={t('sign_in_placeholder_password')}
              className={cx('input')}
              value={formValues.password}
              onChange={handleInputChange}
            />

            <img
              src={isShowPassword ? icons.signInIconEyeShow : icons.signInIconEyeHide}
              alt={t('common_img_text_alt')}
              className={cx('inputSuffix')}
              onClick={handleTogglePassword}
            />
          </div>

          {formErrors.password && <p className={cx('errorMessage')}>{formErrors.password}</p>}
        </div>
      </div>

      <div className={cx('signInGroup')}>
        <div className={cx('forgotPassword')} onClick={handleNavigateToForgotPassword}>
          {t('sign_in_btn_forgot_password')}
        </div>
      </div>

      <BaseButton
        text={t('sign_in_btn_submit')}
        width={'100%'}
        typeStyle={ButtonTypeEnum.PRIMARY}
        onClick={handleSignIn}
      />
    </div>
  );
};

export default SignIn;
